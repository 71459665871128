import { defineMessages } from 'react-intl';

export const scope = 'loop.containers';

export default defineMessages({

  // User Registration/Edit
  FirstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'Ime'
  },
  LastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Prezime'
  },
  FullName: {
    id: `${scope}.FullName`,
    defaultMessage: 'Ime i prezime'
  },
  Email: {
    id: `${scope}.Email`,
    defaultMessage: 'email adresa'
  },
  Username: {
    id: `${scope}.username`,
    defaultMessage: 'Korisničko ime'
  },
  InsertEmail: {
    id: `${scope}.insertEmail`,
    defaultMessage: 'Unesi email adresu'
  },
  EmailAlreadyExists: {
    id: `${scope}.emailAlreadyExists`,
    defaultMessage: 'Email adresa postoji. Molimo Vas izaberite drugu'
  },
  VerificationCodeDoesNotExist: {
    id: `${scope}.verificationCodeDoesNotExist`,
    defaultMessage: 'Kod koji si uneo, nije ispravan. Molimo te pokušaj ponovo'
  },
  userWithEmailAlreadyExists: {
    id: `${scope}.userWithEmailAlreadyExists`,
    defaultMessage: 'Email koji se uneo već postoji. Molimo te pokušaj drugi.'
  },
  userWithPhoneNumberAlreadyExists: {
    id: `${scope}.userWithPhoneNumberAlreadyExists`,
    defaultMessage: 'Broj telefona koji se uneo već postoji. Molimo te pokušaj drugi.'
  },
  phoneNumberVerifiedSuccess: {
    id: `${scope}.phoneNumberVerifiedSuccess`,
    defaultMessage: 'Uspešno si verifikovao profil!'
  },
  ToMuchTimeTriedVerifyPhoneNumber: {
    id: `${scope}.toMuchTimeTriedVerifyPhoneNumber`,
    defaultMessage: 'Pokušao si previše puta da se verifikuješ, molimo te da pokušaš kasnije, ukoliko postoji problem uvek se možeš obratiti našoj podršci!'
  },
  ToMuchTimeTriedResetPassword: {
    id: `${scope}.toMuchTimeTried`,
    defaultMessage: 'Pokušao si previše puta da se verifikuješ zahtev za obnovu lozinke, molimo te da pokušaš kasnije, ukoliko postoji problem uvek se možeš obratiti našoj podršci!'
  },
  UserAlreadyApproved: {
    id: `${scope}.userAlreadyApproved`,
    defaultMessage: 'Korisnik je već odobren'
  },
  EmailTaken: {
    id: `${scope}.emailTaken`,
    defaultMessage: 'Već postoji korisnik sa ovim e-mailom.'
  },
  PhoneNumberTaken: {
    id: `${scope}.phoneNumberTaken`,
    defaultMessage: 'Već postoji korisnik sa ovim brojem telefona.'
  },
  CompanyNameTaken: {
    id: `${scope}.companyNameTaken`,
    defaultMessage: 'Kompanija pod istim nazivom je već registrovana. Ukoliko je u pitanju ispostava koja je funkcionalno odvojena, molimo te da dopuniš naziv kompanije lokacijom (ili drugim odgovarajućim diferencijalnim elementom) kako bi sistem omogućio pravljenje naloga.'
  },
  Phone: {
    id: `${scope}.Phone`,
    defaultMessage: 'Telefon'
  },
  AllowedLogin: {
    id: `${scope}.AllowedLogin`,
    defaultMessage: 'Dozvoljeno logovanje sa više email adresa'
  },
  Occupation: {
    id: `${scope}.Occupation`,
    defaultMessage: 'Zanimanje'
  },
  FieldOfWork: {
    id: `${scope}.fieldOfWork`,
    defaultMessage: 'Oblast rada'
  },
  Mailing: {
    id: `${scope}.mailing`,
    defaultMessage: 'Mailing'
  },
  CurrentPosition: {
    id: `${scope}.CurrentPosition`,
    defaultMessage: 'Pozicija u kompaniji'
  },
  SelectState: {
    id: `${scope}.selectState`,
    defaultMessage: 'Država'
  },
  SelectCity: {
    id: `${scope}.selectCity`,
    defaultMessage: 'Grad'
  },
  SelectCityRequired: {
    id: `${scope}.selectCity`,
    defaultMessage: 'Grad <sup>*<sup>'
  },
  LinkedInLink: {
    id: `${scope}.LinkedInLink`,
    defaultMessage: 'LinkedIn profil'
  },
  Avatar: {
    id: `${scope}.avatar`,
    defaultMessage: 'Tvoja slika'
  },
  SelectIndustryCategories: {
    id: `${scope}.selectIndustryCategories`,
    defaultMessage: 'Izbor kategorija oglasa koji će stizati na email:'
  },
  SelectIndustryCategoriesForBusiness: {
    id: `${scope}.selectIndustryCategoriesForBusiness`,
    defaultMessage: 'Izbor kategorija vesti (Glas tržišta) koje će stizati na email:'
  },
  SelectNewsCategory: {
    id: `${scope}.selectNewsCategory`,
    defaultMessage: 'Izbor kategorija vesti (Glas tržišta) koje će stizati na email:'
  },
  ContactData: {
    id: `${scope}.contactData`,
    defaultMessage: 'Kontakt podaci'
  },
  HeaderAccessData: {
    id: `${scope}.headerAccessData`,
    defaultMessage: 'Podaci za pristup'
  },
  InputUsername: {
    id: `${scope}.inputUsername`,
    defaultMessage: 'Korisničko ime'
  },
  InputPassword: {
    id: `${scope}.inputPassword`,
    defaultMessage: 'Lozinka'
  },
  InputRetypePassword: {
    id: `${scope}.inputRetypePassword`,
    defaultMessage: 'Ponovi lozinku'
  },
  NewPassword: {
    id: `${scope}.newPassword`,
    defaultMessage: 'Ukucaj novu lozniku'
  },
  PasswordHint: {
    id: `${scope}.passwordHint`,
    defaultMessage: 'Šifra mora da sadrži najmanje 8 karaktera.'
  },
  IAgree: {
    id: `${scope}.iAgree`,
    defaultMessage: 'Saglasan/a sam sa '
  },
  CheckboxAgreeCondition: {
    id: `${scope}.checkboxAgreeCondition`,
    defaultMessage: 'uslovima korišćenja.'
  },
  CheckboxAgreeConditionRules: {
    id: `${scope}.checkboxAgreeConditionRules`,
    defaultMessage: 'uslovima i pravilima korišćenja.'
  },
  CheckboxGDPR: {
    id: `${scope}.checkboxGDPR`,
    defaultMessage: 'politikom privatnosti.'
  },
  CheckboxNewsletter: {
    id: `${scope}.checkboxNewsletter`,
    defaultMessage: 'Želim da mi stižu objave (Glas tržišta) na email.'
  },
  IAgreeWithTerms: {
    id: `${scope}.iAgreeWithTerms`,
    defaultMessage: 'Registracijom na našem portalu daješ saglasnost za <a>politiku privatnosti</a> i <a2>uslove korišćenja</a2> portala loop.rs'
  },
  CheckboxJobs: {
    id: `${scope}.checkboxJobs`,
    defaultMessage: 'Želim da mi stižu najnoviji oglasi na email.'
  },
  RegisterButton: {
    id: `${scope}.registerButton`,
    defaultMessage: 'Registruj se'
  },
  Login: {
    id: `${scope}.login`,
    defaultMessage: 'Prijavi se'
  },
  Logout: {
    id: `${scope}.Logout`,
    defaultMessage: 'Odjavi se'
  },
  Role: {
    id: `${scope}.role`,
    defaultMessage: 'Uloga'
  },
  ChooseRole: {
    id: `${scope}.chooseRole`,
    defaultMessage: 'Izaberi Ulogu'
  },

  // Register/Edit Business User
  InputCompanyName: {
    id: `${scope}.inputCompanyName`,
    defaultMessage: 'Unesite naziv kompanije',
  },
  SelectWhereRegCompany: {
    id: `${scope}.selectWhereRegCompany`,
    defaultMessage: 'Gde je registrovana kompanija?',
  },
  InputPIB: {
    id: `${scope}.inputPIB`,
    defaultMessage: 'PIB',
  },
  InputVatNumber: {
    id: `${scope}.inputVatNumber`,
    defaultMessage: 'VATIN/Identifikacioni broj',
  },
  InputRegNumber: {
    id: `${scope}.inputRegNumber`,
    defaultMessage: 'Matični broj',
  },
  InputZipNumber: {
    id: `${scope}.inputZipNumber`,
    defaultMessage: 'Poštanski broj'
  },
  InputCompanyAddress: {
    id: `${scope}.inputCompanyAddress`,
    defaultMessage: 'Adresa kompanije'
  },
  InputCompanyWebAddress: {
    id: `${scope}.inputCompanyWebAddress`,
    defaultMessage: 'Web adresa kompanije'
  },
  SelectIndustry: {
    id: `${scope}.selectIndustry`,
    defaultMessage: 'Industrija'
  },
  SelectNumberOfEmployees: {
    id: `${scope}.selectNumberOfEmployees`,
    defaultMessage: 'Broj zaposlenih'
  },
  TextDescSerbian: {
    id: `${scope}.textDescSerbian`,
    defaultMessage: 'Opis kompanije (srpski)'
  },
  TextDescEnglish: {
    id: `${scope}.textDescEnglish`,
    defaultMessage: 'Opis kompanije (engleski)'
  },
  UploadCompanyLogo: {
    id: `${scope}.uploadCompanyLogo`,
    defaultMessage: 'Logo kompanije'
  },
  LabelGetCompanyLogo: {
    id: `${scope}.labelGetCompanyLogo`,
    defaultMessage: 'Prevuci sliku ovde ili'
  },
  ButtonUploadCompanyLogo: {
    id: `${scope}.buttonUploadCompanyLogo`,
    defaultMessage: 'Ubaci sliku'
  },
  InputVideoPresentationLink: {
    id: `${scope}.inputVideoPresentationLink`,
    defaultMessage: 'Youtube video za prezentaciju kompanije'
  },
  HeaderContactData: {
    id: `${scope}.headerContactData`,
    defaultMessage: 'Kontakt Podaci'
  },
  Continue: {
    id: `${scope}.continue`,
    defaultMessage: "Nastavi"
  },
  BackToPreviousStep: {
    id: `${scope}.backToPreviousStep`,
    defaultMessage: "Vrati se na prethodni korak"
  },
  // General
  HomePage: {
    id: `${scope}.homepage`,
    defaultMessage: 'Početna stranica'
  },
  UsersHeader: {
    id: `${scope}.header`,
    defaultMessage: 'Korisnici',
  },
  Filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
  CreatedAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Kreirano',
  },
  UserCreatedAt: {
    id: `${scope}.userCreatedAt`,
    defaultMessage: 'Kreiran',
  },
  Delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Obriši',
  },
  Edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Izmeni',
  },
  AddNew: {
    id: `${scope}.addNew`,
    defaultMessage: 'Dodaj',
  },
  AlreadyApproved: {
    id: `${scope}.alreadyApproved`,
    defaultMessage: 'Zadatak je već odobren!',
  },
  Order: {
    id: `${scope}.order`,
    defaultMessage: 'Naruči',
  },
  Posted: {
    id: `${scope}.posted`,
    defaultMessage: 'Postavljeno: ',
  },
  Expires: {
    id: `${scope}.expires`,
    defaultMessage: 'Ističe: ',
  },
  AddNewLocation: {
    id: `${scope}.addNewLocation`,
    defaultMessage: 'Dodaj grad',
  },
  Back: {
    id: `${scope}.back`,
    defaultMessage: "Nazad"
  },
  BackLink: {
    id: `${scope}.backLink`,
    defaultMessage: "←"
  },
  View: {
    id: `${scope}.View`,
    defaultMessage: 'Pregledaj',
  },
  Here:{
    id: `${scope}.Here`,
    defaultMessage: 'ovde',
  },
  FormSubmitButton: {
    id: `${scope}.submitButton`,
    defaultMessage: 'Sačuvaj Izmene',
  },
  Remove: {
    id: `${scope}.unsaveJob`,
    defaultMessage: 'Ukloni',
  },
  SaveJob: {
    id: `${scope}.saveJob`,
    defaultMessage: 'Sačuvaj oglas',
  },
  SaveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Sačuvaj izmene',
  },
  Show: {
    id: `${scope}.show`,
    defaultMessage: 'Prikaži',
  },
  ShowMore: {
    id: `${scope}.showMore`,
    defaultMessage: 'Vidi više',
  },
  More: {
    id: `${scope}.more`,
    defaultMessage: 'Vidi više...'
  },
  Less: {
    id: `${scope}.less`,
    defaultMessage: 'Vidi manje...'
  },
  PreviewJob: {
    id: `${scope}.previewJob`,
    defaultMessage: 'Pregledaj predlog oglasa od strane loop-a',
  },
  ShowJob: {
    id: `${scope}.showJob`,
    defaultMessage: 'Prikaži oglas',
  },
  StatsShow: {
    id: `${scope}.statsShow`,
    defaultMessage: 'Prikaži statistiku',
  },
  StatsHide: {
    id: `${scope}.statsHide`,
    defaultMessage: 'Zatvori statistiku',
  },
  CommentShow: {
    id: `${scope}.commentShow`,
    defaultMessage: 'Prikaži komentar kandidata',
  },
  CommentHide: {
    id: `${scope}.commentHide`,
    defaultMessage: 'Zatvori komentar kandidata',
  },
  JobPublishes: {
    id: `${scope}.jobPublishes`,
    defaultMessage: 'Oglas objavljuje',
  },
  JobPublished: {
    id: `${scope}.jobPublished`,
    defaultMessage: 'Oglas postavljen:',
  },
  jobExpired: {
    id: `${scope}.jobExpired`,
    defaultMessage: 'Ističe',
  },
  jobOrdered: {
    id: `${scope}.jobOrdered`,
    defaultMessage: 'Oglas naručen:',
  },
  NewsPublished: {
    id: `${scope}.newsPublished`,
    defaultMessage: 'Glas sa tržišta objavio/la: ',
  },
  EventPublished: {
    id: `${scope}.eventPublished`,
    defaultMessage: 'Događaj objavio: ',
  },
  Salary: {
    id: `${scope}.salary`,
    defaultMessage: 'Plata',
  },
  ShortDescription: {
    id: `${scope}.shortDescription`,
    defaultMessage: 'Kratak opis',
  },
  CurrentStatus: {
    id: `${scope}.currentStatus`,
    defaultMessage: 'Status prijave',
  },
  CurrentJobStatus: {
    id: `${scope}.currentJobStatus`,
    defaultMessage: 'Status oglasa',
  },
  SaveAd: {
    id: `${scope}.saveAd`,
    defaultMessage: 'Sačuvaj oglas',
  },
  UnsaveAd: {
    id: `${scope}.unsaveAd`,
    defaultMessage: 'Ukloni iz sačuvanih oglasa',
  },
  Close: {
    id: `${scope}.close`,
    defaultMessage: 'Zatvori'
  },
  Congrats: {
    id: `${scope}.cograts`,
    defaultMessage: 'Čestitamo!'
  },
  Please: {
    id: `${scope}.please`,
    defaultMessage: 'Pažnja!'
  },
  LoremPlaceholder: {
    id: `${scope}.loremPlaceholder`,
    defaultMessage: 'Klikom na "Idi na plaćanje" bićeš redirektovan/a na stranicu sistema za online plaćanje čije usluge koristimo. Sve potrebne podatke o kartici sa koje vršiš plaćanje, upisaćeš upravo na toj stranici.'
  },
  LoremPlaceholderShort: {
    id: `${scope}.loremPlaceholder`,
    defaultMessage: 'Lorem ipsum dolor sit amet'
  },
  Optional: {
    id: `${scope}.optional`,
    defaultMessage: '(Opciono)'
  },
  Upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Zakači'
  },
  Yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Da'
  },
  No: {
    id: `${scope}.no`,
    defaultMessage: 'Ne'
  },
  Further: {
    id: `${scope}.further`,
    defaultMessage: 'Dalje'
  },
  ChangeImage: {
    id: `${scope}.changeImage`,
    defaultMessage: 'Izmeni sliku'
  },
  RemoveImage: {
    id: `${scope}.RemoveImage`,
    defaultMessage: 'Ukloni sliku'
  },
  // Jobs
  StartWork: {
    id: `${scope}.startWork`,
    defaultMessage: 'Željeni početak rada',
  },
  WorkTime: {
    id: `${scope}.workTime`,
    defaultMessage: 'Radno vreme',
  },
  WorkTimePlaceholder: {
    id: `${scope}.workTimePlaceholder`,
    defaultMessage: 'Izaberi radno vreme',
  },
  WeeklyWorkingHours: {
    id: `${scope}.weeklyWorkingHours`,
    defaultMessage: 'Broj radnih sati u nedelji',
  },
  ShiftWork: {
    id: `${scope}.shiftWork`,
    defaultMessage: 'Rad u smenama'
  },
  OrganizedShiftWork: {
    id: `${scope}.organizedShiftWork`,
    defaultMessage: 'Na koji način su organizovane smene'
  },
  WorkShiftsExplained: {
    id: `${scope}.workShiftsExplained`,
    defaultMessage: 'Molimo Vas da dodatno objasnite način rada u smenama'
  },
  StartShift:  {
    id: `${scope}.startShift`,
    defaultMessage: 'Radno vreme (od)'
  },
  EndShift: {
    id: `${scope}.endShift`,
    defaultMessage: 'Radno vreme (do)'
  },
  WorkingSaturday: {
    id: `${scope}.workingSaturday`,
    defaultMessage: 'Radna subota'
  },
  WorkingSunday: {
    id: `${scope}.workingSunday`,
    defaultMessage: 'Radna nedelja'
  },
  WorkFromHome: {
    id: `${scope}.workFromHome`,
    defaultMessage: "Rad od kuće"
  },
  PossibilityToWorkFromHome: {
    id: `${scope}.PossibilityToWorkFromHome`,
    defaultMessage: "Postoji mogućnost"
  },
  WorkContract: {
    id: `${scope}.workContract`,
    defaultMessage: 'Radni ugovor',
  },
  WorkContractPlaceholder: {
    id: `${scope}.workContractPlaceholder`,
    defaultMessage: 'Izaberi radni ugovor',
  },
  ContractDuration: {
    id: `${scope}.contractDuration`,
    defaultMessage: 'Trajanje ugovora',
  },
  TrialPeriod: {
    id: `${scope}.trialPeriod`,
    defaultMessage: 'Probni period',
  },
  TrialPeriodDuration: {
    id: `${scope}.trialPeriodDuration`,
    defaultMessage: 'Trajanje probnog perioda',
  },
  TrialPeriodDurationPlaceholder: {
    id: `${scope}.trialPeriodDurationPlaceholder`,
    defaultMessage: 'Upiši trajanje probnog perioda, na primer "3 nedelje"',
  },
  TrainingPeriod: {
    id: `${scope}.trainingPeriod`,
    defaultMessage: 'Organizovan trening',
  },
  WhenTrainingStart: {
    id: `${scope}.whenTrainingStart`,
    defaultMessage: 'Početak treninga',
  },
  TrainingDaysDuration: {
    id: `${scope}.trainingDaysDuration`,
    defaultMessage: 'Trajanje treninga',
  },
  TrainingDaysDurationPlaceholder: {
    id: `${scope}.trainingDaysDurationPlaceholder`,
    defaultMessage: 'Upiši trajanje treninga, na primer "5 dana"',
  },
  TrainingLocation: {
    id: `${scope}.trainingLocation`,
    defaultMessage: 'Unesi tačnu lokaciju (Država/Grad)',
  },
  ExactTrainingLocation: {
    id: `${scope}.exactTrainingLocationLabel`,
    defaultMessage: 'Lokacija treninga',
  },
  TrainingLocationLabel: {
    id: `${scope}.TrainingLocation`,
    defaultMessage: 'Tačna lokacija treninga',
  },
  ExactLocationTraning: {
    id: `${scope}.exactLocationTraning`,
    defaultMessage: 'Unesi tačnu lokaciju (Država/Grad)',
  },
  Paycheck: {
    id: `${scope}.paycheck`,
    defaultMessage: 'Plata:',
  },
  TrainingContains: {
    id: `${scope}.trainingContains`,
    defaultMessage: 'Trening poseduje',
  },
  SurchargeForStayAbroad: {
    id: `${scope}.surchargeForStayAbroad`,
    defaultMessage: 'Doplatu za boravak u inostranstvu',
  },
  WorkContractValid: {
    id: `${scope}.workContractValid`,
    defaultMessage: 'Radni ugovor važi od dana početka treninga',
  },
  ID: {
    id: `${scope}.id`,
    defaultMessage: 'ID',
  },
  Company: {
    id: `${scope}.company`,
    defaultMessage: 'Kompanija',
  },
  Address: {
    id: `${scope}.address`,
    defaultMessage: 'Adresa',
  },
  Mediator: {
    id: `${scope}.mediator`,
    defaultMessage: 'Posrednik',
  },
  CompanyProvides: {
    id: `${scope}.companyProvides`,
    defaultMessage: 'Šta kompanija nudi:',
  },
  FormCompanyDescriptionSrb: {
    id: `${scope}.form.companyDescriptionSrb`,
    defaultMessage: 'Unesite opis koji najbolje reprezentuje vašu kompaniju',
  },
  FormCompanyDescriptionEng: {
    id: `${scope}.form.companyDescriptionEng`,
    defaultMessage: 'Unesite opis koji najbolje reprezentuje vašu kompaniju',
  },
  PositionDescription: {
    id: `${scope}.positionDescription`,
    defaultMessage: 'Opis pozicije',
  },
  SomethingElse: {
    id: `${scope}.somethingElse`,
    defaultMessage: 'Nešto Drugo',
  },
  GoPay: {
    id: `${scope}.goPay`,
    defaultMessage: 'Nastavi ka plaćanju',
  },
  ChooseOption: {
    id: `${scope}.chooseOption`,
    defaultMessage: 'Naruči'
  },
  Choose: {
    id: `${scope}.choose`,
    defaultMessage: 'Izaberi'
  },
  Notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Obaveštenje!'
  },
  NoPostedJobPreselection: {
    id: `${scope}.noPostedJobPreselection`,
    defaultMessage: 'Nema oglasa za koje možeš naručiti Procenu prijavljenih kandidata'
  },
  NoPostedJobSelection: {
    id: `${scope}.noPostedJobSelection`,
    defaultMessage: 'Nema oglasa za koje možeš naručiti Odabir najboljih kandidata'
  },
  ApplicationsCount: {
    id: `${scope}.applicationsCount`,
    defaultMessage: 'Broj prijava'
  },
  VisitsCount: {
    id: `${scope}.visitsCount`,
    defaultMessage: 'Broj pregleda'
  },
  CurrentNumberCheckIn: {
    id: `${scope}.currentNumberCheckIn`,
    defaultMessage: 'Broj prijava',
  },
  TypeToSearch: {
    id: `${scope}.typeToSearch`,
    defaultMessage: 'Upišite reč za pretragu'
  },
  TypeToSearchEnter: {
    id: `${scope}.typeToSearchEnter`,
    defaultMessage: 'Upiši ključnu reč',
  },
  NoResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'Nema rezultata pretrage'
  },
  NoApplicationsForThisJobs: {
    id: `${scope}.noApplicationsForThisJobs`,
    defaultMessage: 'Nema prijava za ovaj konkurs.'
  },
  NoClients: {
    id: `${scope}.noClients`,
    defaultMessage: 'Nema klijenata.'
  },
  NoTransactions: {
    id: `${scope}.noTransactions`,
    defaultMessage: 'Nema transakcija.'
  },
  NoCoupons: {
    id: `${scope}.noCoupons`,
    defaultMessage: 'Nema kupona.'
  },
  NoSavedJobs: {
    id: `${scope}.noSavedJobs`,
    defaultMessage: 'Nemaš sačuvanih oglasa.'
  },
  NoJobs: {
    id: `${scope}.noJobs`,
    defaultMessage: 'Nema oglasa.'
  },
  NoApplications: {
    id: `${scope}.noApplications`,
    defaultMessage: 'Do sada nisi konkurisao na oglase.'
  },
  NoActiveProcesses: {
    id: `${scope}.noActiveProcesses`,
    defaultMessage: 'Bez aktivnih procesa'
  },
  ContinueSearch: {
    id: `${scope}.continueSearch`,
    defaultMessage: 'Nastavi pretragu'
  },
  Searching: {
    id: `${scope}.searching`,
    defaultMessage: 'Pretraga u toku...'
  },
  AreYouSure: {
    id: `${scope}.areYouSure`,
    defaultMessage: 'Da li si siguran/na?'
  },
  YesDelete: {
    id: `${scope}.yesDelete`,
    defaultMessage: 'Da, izbriši'
  },
  YesDeactivate: {
    id: `${scope}.yesDeactivate`,
    defaultMessage: 'Da, deaktiviraj'
  },
  WithdrawApplication: {
    id: `${scope}.withdrawApplication`,
    defaultMessage: 'Da li si siguran/na da želiš da povučeš prijavu?'
  },
  DeleteUser: {
    id: `${scope}.deleteUser`,
    defaultMessage: 'Da li si siguran/na da želiš da trajno izbrišeš ovog korisnika?'
  },
  DeleteClient: {
    id: `${scope}.deleteClient`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš Klijenta u krugu prilika?'
  },
  DeleteJob: {
    id: `${scope}.deleteJob`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš ovaj oglas?'
  },
  DeleteEmailFromListEmailAlternatives: {
    id: `${scope}.deleteEmailFromListEmailAlternatives`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš ovaj email?'
  },
  DeleteTask: {
    id: `${scope}.deleteTask`,
    defaultMessage: 'Da li siguran/na da želiš da obrišeš ovaj zadatak?'
  },
  DeleteNews: {
    id: `${scope}.deleteNews`,
    defaultMessage: 'Da li si siguran/na da želiš trajno da deaktiviraš ovu objavu u okviru sekcije "Glas tržišta"? Nakon deaktivacije, objava se skida sa sajta.'
  },
  DeleteNewsAdmin: {
    id: `${scope}.DeleteNewsAdmin`,
    defaultMessage: 'Da li si siguran/na da želiš trajno da obrišeš ovu objavu u okviru sekcije "Glas tržišta"?'
  },
  DeleteEvent: {
    id: `${scope}.deleteEvent`,
    defaultMessage: 'Da li si siguran/na da želiš trajno da deaktiviraš ovaj događaj? Nakon deaktivacije, događaj se skida sa sajta.'
  },
  DeleteCategory: {
    id: `${scope}.deleteCategory`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš kategoriju?'
  },
  DeleteTemplate: {
    id: `${scope}.deleteTemplate`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš obrazac?'
  },
  DeleteMessage: {
    id: `${scope}.deleteMessage`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš ovo dopisivanje?'
  },
  DeleteFAQ: {
    id: `${scope}.deleteFAQ`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš FAQ?'
  },
  DeleteNotification: {
    id: `${scope}.deleteNotification`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš izabranu notifikaciju?'
  },
  DeletePackage: {
    id: `${scope}.deletePackage`,
    defaultMessage: 'Da li si siguran/na da želiš da obrišeš paket?'
  },
  DeactivateJob: {
    id: `${scope}.deactivateJob`,
    defaultMessage: 'Da li si siguran/na da želiš trajno da deaktiviraš oglas? Nakon deaktivacije, oglas se skida sa sajta, a mogućnost apliciranja kandidata zaključava.'
  },
  DeactivateEvent: {
    id: `${scope}.deactivateEvent`,
    defaultMessage: 'Da li si siguran/na da želiš trajno da deaktiviraš događaj? Nakon deaktivacije, događaj se skida sa sajta.'
  },
  JobCreateAreYouSure: {
    id: `${scope}.jobCreateAreYouSure`,
    defaultMessage: 'Ukoliko sada izađeš sa ove stranice, neće biti sačuvani podaci koje si do sada uneo/la.'
  },
  JobCreateYesButton: {
    id: `${scope}.jobCreateYesButton`,
    defaultMessage: 'Da, želiš da izađem'
  },
  JobCreateNoButton: {
    id: `${scope}.jobCreateNoButton`,
    defaultMessage: 'Ostaću na stranici'
  },
  Withdraw: {
    id: `${scope}.jobCreateYesButton`,
    defaultMessage: 'Da, želim da povučem prijavu'
  },
  FinishTask: {
    id: `${scope}.finishTask`,
    defaultMessage: 'Završi'
  },
  FinishPreselection: {
    id: `${scope}.finishPreselection`,
    defaultMessage: 'Završi predselekciju'
  },
  FinishSelection: {
    id: `${scope}.finishPreselection`,
    defaultMessage: 'Završi selekciju'
  },
  Activate: {
    id: `${scope}.active`,
    defaultMessage: 'Aktiviraj'
  },
  Deactive: {
    id: `${scope}.deactive`,
    defaultMessage: 'Deaktiviraj'
  },
  ChoosePacket: {
    id: `${scope}.choosePacket`,
    defaultMessage: 'Izaberi Paket'
  },
  ChooseEntity: {
    id: `${scope}.chooseEntity`,
    defaultMessage: 'Izaberi Entiteta'
  },
  ChooseCategory: {
    id: `${scope}.chooseCategory`,
    defaultMessage: 'Sve kategorije'
  },
  CategoryGroups: {
    id: `${scope}.categoryGroups`,
    defaultMessage: 'Grupe kategorija'
  },
  Categories: {
    id: `${scope}.categories`,
    defaultMessage: 'Kategorije'
  },
  CompanyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Naziv kompanije'
  },
  Question: {
    id: `${scope}.question`,
    defaultMessage: 'Pitanje'
  },
  Answer: {
    id: `${scope}.answer`,
    defaultMessage: 'Odgovor'
  },
  URL: {
    id: `${scope}.url`,
    defaultMessage: 'URL'
  },
  DownloadCV: {
    id: `${scope}.downloadCv`,
    defaultMessage: 'Preuzmi CV',
  },
  DownloadCoverLetter: {
    id: `${scope}.downloadCoverLetter`,
    defaultMessage: 'Preuzmi motivaciono pismo',
  },

  ShowAll: {
    id: `${scope}.showAll`,
    defaultMessage: 'Prikaži sve'
  },
  ShowAllServices: {
    id: `${scope}.showAllServices`,
    defaultMessage: 'Sve usluge'
  },
  ShowStatus: {
    id: `${scope}.showStatus`,
    defaultMessage: 'Status'
  },
  PackagesNotCreate: {
    id: `${scope}.packagesNotCreate`,
    defaultMessage: 'Nemate Kreirane pakete'
  },
  AskedQuestions: {
    id: `${scope}.askedQuestions`,
    defaultMessage: 'Često postavljana pitanja'
  },
  AskedQuestionsDesc: {
    id: `${scope}.askedQuestionsDesc`,
    defaultMessage: 'Unapred smo osigurali odgovore za nedoumice i pitanja koja se učestalo javljaju u vezi sa funkcionisanjem i korišćenjem naše platforme.'
  },
  CreateEvent: {
    id: `${scope}.createEvent`,
    defaultMessage: 'Kreiraj događaj'
  },
  CreateNews: {
    id: `${scope}.createNews`,
    defaultMessage: 'Kreiraj vest'
  },
  CreateNewsSuccessDesc: {
    id: `${scope}.createNewsSuccess`,
    defaultMessage: 'Uspešno je naručena usluga kreiranja objave u okviru sekcije "Glas tržišta".'
  },
  CreateNewsSuccessDesc2: {
    id: `${scope}.createNewsSuccess2`,
    defaultMessage: 'loop konsultanti će u najkraćem roku započeti proces verifikacije objave. Ukoliko je sa sadržajem objave sve u redu, loop konsultanti će je učiniti vidljivom svim posetiocima platforme.'
  },
  ModalSameCriteriaText: {
    id: `${scope}.modalSameCriteriaText`,
    defaultMessage: 'Ovi kriterijumi su već sačuvani. Ukoliko želiš da izmeniš neki kriterijum probaj ponovo.'
  },
  CreatePreselection: {
    id: `${scope}.createPreselection`,
    defaultMessage: 'Kreiraj predselekciju'
  },
  CreateSelection: {
    id: `${scope}.createSelection`,
    defaultMessage: 'Kreiraj selekciju'
  },
  OrderPreselection: {
    id: `${scope}.orderPreselection`,
    defaultMessage: 'Pokreni proces procene prijavljenih kandidata'
  },
  OrderSelection: {
    id: `${scope}.orderSelection`,
    defaultMessage: 'Pokreni proces odabira najboljih kandidata'
  },
  CreatePreselectionSelectionHeadline: {
    id: `${scope}.createPreselectionSelectionHeadline`,
    defaultMessage: 'U potrazi ste za najboljim talentima na tržištu rada? Mi možemo pomoći!'
  },
  CreatePreselectionSelectionText: {
    id: `${scope}.createPreselectionSelectionText`,
    defaultMessage: 'Ukoliko se poslovni uspeh oslanja na talenat, sticanje najkvalifikovanijih ljudi predstavlja najveću prednost.'
  },
  CreatePreselectionSelectionText2: {
    id: `${scope}.createPreselectionSelectionText2`,
    defaultMessage: 'Ni jedna organizacija nije ista. To je razlog zbog kojeg naši regruteri posvećuju vreme i trude se da shvate vašu kompaniju na pravi način, kao i poslovne veštine koje su vašoj kompaniji potrebne za uspeh.'
  },
  CreatePreselectionSelectionText3: {
    id: `${scope}.createPreselectionSelectionText3`,
    defaultMessage: 'Svi regruteri su specijalizovani za posebne industrije, što omogućava bolje poznavanje konkretnih tržišta, kompanija i kandidata, i pomaže da vama, kao našem klijentu, pružimo uslugu vrhunskog kvaliteta. Za Vas izdvajamo naše usluge:'
  },
  PreselectionInProgress: {
    id: `${scope}.preselectionInProgress`,
    defaultMessage: 'Procena u toku'
  },
  SelectionInProgress: {
    id: `${scope}.selectionInProgress`,
    defaultMessage: 'Odabir u toku'
  },
  NoApplicationsForPreSelection: {
    id: `${scope}.noApplicationsForPreSelection`,
    defaultMessage: 'Nemaš prijavljenih kandidata i zato nije moguće pokrenuti proces procene prijavljenih kandidata ili odabira najboljih kandidata.'
  },
  SpecialJobApplicationsForPreselection: {
    id: `${scope}.specialJobApplicationsForPreselection`,
    defaultMessage: 'Ovaj oglas je već u predselekciji/selekciji, u pitanju je total ili osnovni paket.'
  },
  CreateNewsHeadline: {
    id: `${scope}.createNewsHeadline`,
    defaultMessage: 'Kreiranje Vesti'
  },
  CreateEventsHeadline: {
    id: `${scope}.createNewsHeadline`,
    defaultMessage: 'Kreiranje Događaja'
  },
  Service: {
    id: `${scope}.service`,
    defaultMessage: 'Usluga'
  },
  Type: {
    id: `${scope}.type`,
    defaultMessage: 'Tip'
  },
  CardProinvoice: {
    id: `${scope}.cardProinvoice`,
    defaultMessage: 'Način plaćanja'
  },
  Status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  StatusTask: {
    id: `${scope}.statusTask`,
    defaultMessage: 'Status Zadatka'
  },
  Date: {
    id: `${scope}.date`,
    defaultMessage: 'Datum'
  },
  TransactionDate: {
    id: `${scope}.transactionDate`,
    defaultMessage: 'Datum transakcije'
  },
  Options: {
    id: `${scope}.options`,
    defaultMessage: 'Opcije'
  },
  Comment: {
    id: `${scope}.comment`,
    defaultMessage: 'Upiši komentar'
  },
  ApplyComment: {
    id: `${scope}.applyComment`,
    defaultMessage: 'Ukoliko imaš dodatni komentar ili pitanje za poslodavca upiši ovde'
  },
  ApplicationsWeb: {
    id: `${scope}.applicationsWeb`,
    defaultMessage: 'Web Adresa Gde se Prikupljaju Prijave'
  },
  Min: {
    id: `${scope}.min`,
    defaultMessage: 'Min'
  },
  Max: {
    id: `${scope}.max`,
    defaultMessage: 'Max'
  },
  Title: {
    id: `${scope}.title`,
    defaultMessage: 'Naslov'
  },
  AddOptionForDescJob: {
    id: `${scope}.addOptionForDescJob`,
    defaultMessage: 'Dodaj opciju'
  },
  InTheNameOfWhichCompany: {
    id: `${scope}.inTheNameOfWhichCompany`,
    defaultMessage: 'U ime koje kompanije objavljujete događaj'
  },
  SearchByKeyName: {
    id: `${scope}.searchByKeyName`,
    defaultMessage: 'Pretraga po ključnoj reči'
  },
  SearchByService: {
    id: `${scope}.searchByService`,
    defaultMessage: 'Pretraži po imenu usluge'
  },
  Search: {
    id: `${scope}.search`,
    defaultMessage: 'Pretraži'
  },
  KeyName: {
    id: `${scope}.keyName`,
    defaultMessage: 'Ključna reč'
  },
  FirstAndLastName: {
    id: `${scope}.firstAndLastName`,
    defaultMessage: 'Ime i prezime'
  },
  KeyTitle: {
    id: `${scope}.keyTitle`,
    defaultMessage: 'Pretraga po naslovu'
  },
  DragOrInsertDoc: {
    id: `${scope}.dragOrInsertDoc`,
    defaultMessage: 'Prevuci dokument ovde ili...'
  },
  // Dashboard Menu
  SetContent: {
    id: `${scope}.setContent`,
    defaultMessage: 'Trenutno aktivne usluge'
  },
  CompanyInfo: {
    id: `${scope}.companyInfo`,
    defaultMessage: 'Profil kompanije'
  },
  CandidateApplication: {
    id: `${scope}.candidateApplication`,
    defaultMessage: 'Pregled prijava na oglase'
  },
  Statistics: {
    id: `${scope}.statistics`,
    defaultMessage: 'Statistika'
  },
  Users: {
    id: `${scope}.users`,
    defaultMessage: 'Korisnici'
  },
  UserInfo: {
    id: `${scope}.userInfo`,
    defaultMessage: 'Moj profil'
  },
  RequestedServicesHistory: {
    id: `${scope}.requestedServiceHistory`,
    defaultMessage: 'Istorijat zatraženih usluga'
  },
  ServicesHistory: {
    id: `${scope}.ServicesHistory`,
    defaultMessage: 'Moji procesi'
  },
  MyFavoriteJobs: {
    id: `${scope}.MyFavoriteJobs`,
    defaultMessage: 'Poslovi za mene'
  },
  YourMessages: {
    id: `${scope}.YourMessages`,
    defaultMessage: 'Poruke'
  },
  Preselections: {
    id: `${scope}.preselections`,
    defaultMessage: 'Procesi procene prijavljenih kandidata'
  },
  Selections: {
    id: `${scope}.selections`,
    defaultMessage: 'Procesi odabira najboljih kandidata'
  },
  CreateTemplate: {
    id: `${scope}.createTemplate`,
    defaultMessage: 'Kreiraj obrazac'
  },
  CreateCategories: {
    id: `${scope}.createCategories`,
    defaultMessage: 'Kreiraj kategoriju'
  },
  CreateClient: {
    id: `${scope}.createClient`,
    defaultMessage: 'Kreiraj klijenta'
  },
  AddCategory: {
    id: `${scope}.addCategory`,
    defaultMessage: 'Dodaj kategoriju'
  },
  CreateFAQs: {
    id: `${scope}.createFAQs`,
    defaultMessage: 'Kreiraj FAQs'
  },
  CreateFAQGroup: {
    id: `${scope}.createFAQGroup`,
    defaultMessage: 'Kreiraj FAQ grupu'
  },
  SelectFAQType: {
    id: `${scope}.selectFAQType`,
    defaultMessage: 'Izaberite FAQ tip'
  },
  SelectFAQModel: {
    id: `${scope}.selectFAQModel`,
    defaultMessage: 'Izaberite FAQ model'
  },
  Task: {
    id: `${scope}.task`,
    defaultMessage: 'Zadatak'
  },
  Tasks: {
    id: `${scope}.tasks`,
    defaultMessage: 'Zadaci'
  },
  TasksHistory: {
    id: `${scope}.tasksHistory`,
    defaultMessage: 'Istorijat zadataka'
  },
  Notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifikacije'
  },
  OurClients: {
    id: `${scope}.ourClients`,
    defaultMessage: 'Klijenti u krugu prilika'
  },
  SavedJobs: {
    id: `${scope}.savedJobs`,
    defaultMessage: 'Sačuvani oglasi'
  },
  Packages: {
    id: `${scope}.packages`,
    defaultMessage: 'Paketi'
  },
  Payment: {
    id: `${scope}.payment`,
    defaultMessage: 'Plaćanje'
  },
  Transactions: {
    id: `${scope}.transactions`,
    defaultMessage: 'Transakcije'
  },
  MyTransactions: {
    id: `${scope}.myTransactions`,
    defaultMessage: 'Moje transakcije'
  },
  ChoosenAdCategories: {
    id: `${scope}.choosenAdCategories`,
    defaultMessage: 'Odabrane kategorije poslova'
  },
  Soon: {
    id: `${scope}.soon`,
    defaultMessage: 'Uskoro'
  },
  // Saga Messages / Notifications
    // Standard
  SomethingNotRight: {
    id: `${scope}.somethingNotRight`,
    defaultMessage: 'Uups! Nešto nije u redu.'
  },
  AlreadyUnsubscribed: {
    id: `${scope}.alreadySubscribed`,
    defaultMessage: 'Več si se odjavio sa servisa, za više informacija poseti svoj profil!'
  },
  SubscribeSuccess: {
    id: `${scope}.subscribeSuccess`,
    defaultMessage: 'Uspešno si se prijavio!'
  },
  UnsubscribeSuccess: {
    id: `${scope}.unsubscribeSuccess`,
    defaultMessage: 'Uspešno si se odjavio!'
  },
  EmailNotRight: {
    id: `${scope}.emailNotRight`,
    defaultMessage: 'Email koji si uneo/la ne postoji.'
  },
  PhoneNumberNotRight: {
    id: `${scope}.phoneNumberNotRight`,
    defaultMessage: 'Uneti broj telefona ne postoji.'
  },
  ResetPasswordMsg: {
    id: `${scope}.resetPasswordMsg`,
    defaultMessage: 'Poslali smo ti email za resetovanje lozinke.'
  },
  ResetPasswordPhoneNotif: {
    id: `${scope}.resetPasswordPhoneNotif`,
    defaultMessage: 'Poslali smo ti sms za resetovanje loznike.'
  },
  ResetSuccess: {
    id: `${scope}.resetSuccess`,
    defaultMessage: 'Lozinka je uspešno resetovana. Od sada novu lozinku koristiš za prijavu u svoj nalog.'
  },
  FieldMustBeFilled: {
    id: `${scope}.fieldMustBeFilled`,
    defaultMessage: 'Polje mora biti popunjeno. Unesite informacije.'
  },
  StepNotif: {
    id: `${scope}.stepNotif`,
    defaultMessage: 'Uspešno popunjen '
  },
  StepNumberNotif: {
    id: `${scope}.stepNumberNotif`,
    defaultMessage: '. korak.'
  },
  DeleteNotif: {
    id: `${scope}.deleteNotif`,
    defaultMessage: 'Brisanje uspešno.'
  },
    // Our Client
  ClientEditNotif: {
    id: `${scope}.clientEditNotif`,
    defaultMessage: 'Uspešno ste promenili klijenta!'
  },
  ClientCreateNotif: {
    id: `${scope}.clientCreateNotif`,
    defaultMessage: 'Uspešno ste kreirali klijenta!'
  },
    // Company Info
  UpdateCompanyInfoNotif: {
    id: `${scope}.updateCompanyInfoNotif`,
    defaultMessage: 'Promena uspešna.'
  },
    // FAQs
  CreateFAQNotif: {
    id: `${scope}.createFAQNotif`,
    defaultMessage: 'Uspešno ste kreirali FAQ!'
  },
  EditFAQNotif: {
    id: `${scope}.editCompanyInfoNotif`,
    defaultMessage: 'Uspešno ste promenili FAQ!'
  },
  GeneralQuestions: {
    id: `${scope}.generalQuestions`,
    defaultMessage: 'Opšta pitanja'
  },
  BusinessUsers: {
    id: `${scope}.businessUsers`,
    defaultMessage: 'Biznis korisnici'
  },
  RegularUsers: {
    id: `${scope}.regularUsers`,
    defaultMessage: 'Regular korisnici'
  },
    // Template
  CreateTemplateNotif: {
    id: `${scope}.createTemplateNotif`,
    defaultMessage: 'Uspešno ste kreirali obrazac!'
  },
  EditTemplateNotif: {
    id: `${scope}.editTemplateNotif`,
    defaultMessage: 'Uspešno ste promenili obrazac!'
  },
  DeleteTemplateNotif: {
    id: `${scope}.deleteTemplateNotif`,
    defaultMessage: 'Uspešno ste obrisali obrazac!'
  },
    // Events
  CreateEventsNotif: {
    id: `${scope}.createEventsNotif`,
    defaultMessage: 'Događaj je uspešno kreiran.'
  },
  EditEventsNotif: {
    id: `${scope}.editEventsNotif`,
    defaultMessage: 'Uspešno ste promenili događaj!'
  },
    // Job Applications
  EditCandidateNotif: {
    id: `${scope}.editCandidateNotif`,
    defaultMessage: 'Status kandidata je uspešno izmenjen.'
  },
  EditCandidateScoreNotif: {
    id: `${scope}.editCandidateScoreNotif`,
    defaultMessage: 'Ocena kandidata je uspešno izmenjena.'
  },
    // Jobs Admin
  CreateJobNotif: {
    id: `${scope}.createJobNotif`,
    defaultMessage: 'Uspešno ste kreirali oglas!'
  },
  EditJobNotif: {
    id: `${scope}.editJobNotif`,
    defaultMessage: 'Uspešno ste promenili oglas!'
  },
  FinishPaymentNotif: {
    id: `${scope}.finishPaymentNotif`,
    defaultMessage: 'Zavrsite plaćanje i sačekajte da vam odobre oglas!'
  },
    // News
  CreateNewsNotif: {
    id: `${scope}.createNewsNotif`,
    defaultMessage: 'Glas tržišta je uspešno kreiran.'
  },
  EditNewsNotif: {
    id: `${scope}.editNewssNotif`,
    defaultMessage: 'Uspešno ste promenili vest!'
  },
   // Preselections && Selections
  ChangesSuccessNotif: {
    id: `${scope}.changesSuccessNotif`,
    defaultMessage: 'Uspešno ste napravili izmene za:'
  },
  RequestPreselectionJobNotif: {
    id: `${scope}.requestPreselectionJobNotif`,
    defaultMessage: 'Uspešno ste poslali zahtev za predselekciju za oglas:'
  },
  ChangesNotif: {
    id: `${scope}.changesNotif`,
    defaultMessage: 'Uspešno ste napravili izmene!'
  },
  AccpetedBid: {
    id: `${scope}.accpetedBid`,
    defaultMessage: 'Uspešno si prihvatio ponudu!'
  },
  RejectedBid: {
    id: `${scope}.rejectedBid`,
    defaultMessage: 'Ponuda je odbijena.'
  },
  BidRejected: {
    id: `${scope}.bidRejected`,
    defaultMessage: 'Ponuda odbijena'
  },
  BidAccepted: {
    id: `${scope}.bidAccepted`,
    defaultMessage: 'Ponuda prihvaćena'
  },
  CreatePreselectionNotif: {
    id: `${scope}.CreatePreselectionNotif`,
    defaultMessage: 'Uspešno ste kreirali predselekciju!'
  },
  TasksNotif: {
    id: `${scope}.tasksNotif`,
    defaultMessage: 'Uspešno ste dodeli zadatak!'
  },
  FinishTaskNotif: {
    id: `${scope}.FinishTasksNotif`,
    defaultMessage: 'Uspešno ste završili zadatak!'
  },
  JobApproveNotif: {
    id: `${scope}.jobApproveNotif`,
    defaultMessage: 'Uspešno ste odobrili posao!'
  },
  DetailsAboutRequestedChangesAreSent: {
    id: `${scope}.detailsAboutRequestedChangesAreSent`,
    defaultMessage: 'Detalji o izmeni su uspešno poslati.'
  },
  JobReturnNotif: {
    id: `${scope}.jobReturnNotif`,
    defaultMessage: 'Oglas je uspešno postavljen na sajt. Vidljiv je na sajtu, a prijave kandidata su omogućene.'
  },
  NewsApproveNotif: {
    id: `${scope}.jobApproveNotif`,
    defaultMessage: 'Uspešno ste odobrili vest!'
  },
  EventApproveNotif: {
    id: `${scope}.eventApproveNotif`,
    defaultMessage: 'Uspešno ste odobrili event!'
  },
    // Users
  EditUserNotif: {
    id: `${scope}.editUserNotif`,
    defaultMessage: 'Promena uspešna.'
  },
  CreateUserNotif: {
    id: `${scope}.createUserNotif`,
    defaultMessage: 'Uspešno ste kreirali korisnika!'
  },
  UpdateUserNotif: {
    id: `${scope}.updateUserNotif`,
    defaultMessage: 'Uspešno ste promenili korisnika!'
  },
  SuccessTaskFinished: {
    id: `${scope}.successTaskFinished`,
    defaultMessage: 'Uspešno ste odobrili korisnika, zadatak je zatvoren!'
  },
    // Category
  CreateCategoryNotif: {
    id: `${scope}.createCategoryNotif`,
    defaultMessage: 'Uspešno ste kreirali kategoriju!'
  },
  EditCategoryNotif: {
    id: `${scope}.EditCategoryNotif`,
    defaultMessage: 'Uspešno ste promenili kategoriju!'
  },
    // Register
  RegisterSuccessNotif: {
    id: `${scope}.registerSuccessNotif`,
    defaultMessage: 'Uspešno ste se registrovali!'
  },
    // Bookmark
  BookmarkNotif: {
    id: `${scope}.bookmarkNotif`,
    defaultMessage: 'Oglas je uspešno sačuvan.'
  },
  UnbookmarkNotif: {
    id: `${scope}.unbookmarkNotif`,
    defaultMessage: 'Oglas je uspešno uklonjen iz "Sačuvanih oglasa".'
  },
    // Archive
  ArchiveSuccessNotif: {
    id: `${scope}.archiveSuccess`,
    defaultMessage: 'Kandidat je uspešno arhiviran.'
  },
  UnarchiveSuccessNotif: {
    id: `${scope}.unarchiveSuccess`,
    defaultMessage: 'Kandidat je uspešno uklonjen iz "Arhiviranih kandidata".'
  },
  // Breadcrumbs as Bc - Messages
  // Home
  HomeBc: {
    id: `${scope}.homeBc`,
    defaultMessage: 'Home'
  },
  // Login
  LoginBc: {
    id: `${scope}.loginBc`,
    defaultMessage: 'Prijavi se'
  },
  // Registration
  RegistrationBc: {
    id: `${scope}.registrationBc`,
    defaultMessage: 'Registracija'
  },
  // Success
  SuccessBc: {
    id: `${scope}.successBc`,
    defaultMessage: 'Uspešno si se registrovao'
  },
  // Forgot Password
  ForgotPasswordBc: {
    id: `${scope}.forgotPasswordBc`,
    defaultMessage: 'Zaboravljena lozinka'
  },
  NewPasswordBc: {
    id: `${scope}.newPasswordBc`,
    defaultMessage: 'Unesi novu lozinku'
  },
  // News
  NewsBc: {
    id: `${scope}.NewsBc`,
    defaultMessage: 'Vesti'
  },
  NewsCreateBc: {
    id: `${scope}.newsCreateBc`,
    defaultMessage: 'Kreiranje Vesti'
  },
  // Events
  EventsBc: {
    id: `${scope}.eventsBc`,
    defaultMessage: 'Događaji'
  },
  EventsCreateBc: {
    id: `${scope}.eventsCreateBc`,
    defaultMessage: 'Kreiranje Događaja'
  },
  // Jobs
  JobsBc: {
    id: `${scope}.jobsBc`,
    defaultMessage: 'Poslovi'
  },
  JobsCreateBc: {
    id: `${scope}.jobsBc`,
    defaultMessage: 'Poslovi'
  },
  // FAQs
  FAQsBc: {
    id: `${scope}.faqsBc`,
    defaultMessage: 'FAQs'
  },
  // Dashboard
  DashboardBc: {
    id: `${scope}.dashboardBc`,
    defaultMessage: 'Dashboard'
  },
  // Price List
  PriceListBc: {
    id: `${scope}.priceListBc`,
    defaultMessage: 'Cenovnik usluga'
  },
  // Message
  SendMessage: {
    id: `${scope}.sendMessage`,
    defaultMessage: 'Pošalji'
  },
  TextMessage: {
    id: `${scope}.textMessage`,
    defaultMessage: 'Tekst poruke'
  },
  SubjectMessage: {
    id: `${scope}.subject`,
    defaultMessage: 'Tema poruke',
  },
  SuccessDeactivate: {
    id: `${scope}.successDeactivate`,
    defaultMessage: 'Oglas je uspešno deaktiviran. Više nije vidljiv na sajtu, a prijave su onemogućene.',
  },
  EventDeactivate: {
    id: `${scope}.eventDeactivate`,
    defaultMessage: 'Uspešna deaktivacija.',
  },
  NewsDeactivate: {
    id: `${scope}.newsDeactivate`,
    defaultMessage: 'Uspešna deaktivacija.',
  },
// Price List + Packages
  PDV: {
    id: `${scope}.pdv`,
    defaultMessage: '+ PDV'
  },
  ApproveText: {
    id: `${scope}.approveText`,
    defaultMessage: 'Da li ste sigurni da ste pregledali posao. Ako jeste molimo vas da nastavite dalje i zavrsite task.'
  },
  Approve: {
    id: `${scope}.approve`,
    defaultMessage: 'Odobri'
  },
  AllTask: {
    id: `${scope}.allTasks`,
    defaultMessage: 'Svi zadaci'
  },
  TaskTitle: {
      id: `${scope}.taskTitle`,
      defaultMessage: 'Naslov zadatka'
  },
  Model: {
      id: `${scope}.model`,
      defaultMessage: 'Model'
  },
  Job: {
      id: `${scope}.job`,
      defaultMessage: 'Oglas'
  },
  News: {
      id: `${scope}.news`,
      defaultMessage: 'Glas tržišta'
  },
  Events: {
      id: `${scope}.events`,
      defaultMessage: 'Dogadjaj'
  },
  Ad: {
    id: `${scope}.ad`,
    defaultMessage: 'Oglas:'
  },
  Verified: {
    id: `${scope}.verified`,
    defaultMessage: 'Ver.'
  },
  ContactMe: {
    id: `${scope}.contactMe`,
    defaultMessage: 'Kontaktiraj me'
  },
  VerifiedUser: {
    id: `${scope}.verifiedUser`,
    defaultMessage: 'Verifikuj korisnika'
  },
  VerifiedUserSuccess: {
    id: `${scope}.verifiedUserSuccess`,
    defaultMessage: 'Korisnik uspešno verifikovan'
  },
  ActiveShort: {
    id: `${scope}.activeShort`,
    defaultMessage: 'Akt.'
  },
  // Statuses
  Active: {
    id: `${scope}.active`,
    defaultMessage: 'Aktivan'
  },
  Expired: {
    id: `${scope}.expired`,
    defaultMessage: 'Istekao'
  },
  Pause: {
    id: `${scope}.pause`,
    defaultMessage: 'Pauziran'
  },
  HrCreatedStatus: {
    id: `${scope}.hrCreatedStatus`,
    defaultMessage: 'Oglas kreiran od strane HR-a'
  },
  Deaktiviran: {
    id: `${scope}.deaktiviran`,
    defaultMessage: 'Deaktiviran'
  },
  ProcessStatus: {
    id: `${scope}.processStatus`,
    defaultMessage: 'Verifikacija u toku'
  },
  ProcessPaymentStatus: {
    id: `${scope}.processPaymentStatus`,
    defaultMessage: 'Uplata u toku'
  },
  ProcessProccessWaiting: {
    id: `${scope}.processProccessWaiting`,
    defaultMessage: 'Uplata na čekanju'
  },

  DraftStatus: {
    id: `${scope}.draftStatus`,
    defaultMessage: 'Draft'
  },
  PreselectionStatus: {
    id: `${scope}.preselection`,
    defaultMessage: 'Procena u toku'
  },
  SelectionStatus: {
    id: `${scope}.selection`,
    defaultMessage: 'Odabir u toku'
  },
  ApplyingClosed: {
    id: `${scope}.applyingClosed`,
    defaultMessage: 'Konkurs zatvoren'
  },
  ServiceRequested: {
    id: `${scope}.serviceRequested`,
    defaultMessage: 'Usluga naručena: ',
  },
  RequestCreated: {
    id: `${scope}.requestCreated`,
    defaultMessage: 'Zahtev kreiran: ',
  },
  TaskDateCreated: {
      id: `${scope}.events`,
      defaultMessage: 'Datum kreiranja zadatka'
  },
  EntityName: {
      id: `${scope}.entityName`,
      defaultMessage: 'Naslov Entiteta'
  },
  AssignTo: {
      id: `${scope}.assignTo`,
      defaultMessage: 'Dodeljen'
  },
  AssingBy: {
      id: `${scope}.assignTo`,
      defaultMessage: 'Dodelio'
  },
  Assing: {
    id: `${scope}.assignTo`,
    defaultMessage: 'Dodeli'
  },
  TaskType: {
    id: `${scope}.taskType`,
    defaultMessage: 'Tip zadatka'
  },
  ApproveType: {
    id: `${scope}.approveType`,
    defaultMessage: 'Odobravanje'
  },
  PreselectionType: {
      id: `${scope}.preselectionType`,
      defaultMessage: 'Predselekcije'
  },
  SelectionType: {
      id: `${scope}.selectionType`,
      defaultMessage: 'Selekcije'
  },
  PreselectionServiceType: {
    id: `${scope}.preselectionServiceType`,
    defaultMessage: 'Predselekcija - formiranje ponude'
  },
  SelectionServiceType: {
    id: `${scope}.selectionServiceType`,
    defaultMessage: 'Selekcija - formiranje ponude'
  },
  CreateType: {
      id: `${scope}.createType`,
      defaultMessage: 'Kreiranje'
  },
  archiveCandidate: {
    id: `${scope}.archiveCandidate`,
    defaultMessage: 'Arhivirani kandidati'
  },
  PreselectionProgress: {
    id: `${scope}.preselectionProgress`,
    defaultMessage: 'Procena prijavljenih kandidata je završena. Pregledaj kandidate i njihove statuse.'
  },
  SelectionProgress: {
    id: `${scope}.selectionProgress`,
    defaultMessage: 'Odabir najboljih kandidata je završen. Pregledaj kandidate i njihove statuse.'
  },
  PreselectionInProgressLongText: {
    id: `${scope}.preselectionInProgressLongText`,
    defaultMessage: 'Procena prijavljenih kandidata u toku. Dobićeš obaveštenje po završetku'
  },
  SelectionInProgressLongText: {
    id: `${scope}.selectionInProgressLongText`,
    defaultMessage: 'Odabir najboljih kandidata u toku. Dobićeš obaveštenje po završetku'
  },
  ServicePreSelectionProgress: {
    id: `${scope}.servicePreSelectionProgress`,
    defaultMessage: 'Poslat upit. Formiranje ponude u toku.'
  },
  PositionInfo: {
    id: `${scope}.positionInfo`,
    defaultMessage: 'Preporučujemo da uneseš što više informacija u ovom segmentu. Ukoliko neki kriterijum ostane prazan, kandidati će imati manje šanse da pronađu tvoj oglas kada koriste pretragu putem specifičnih kriterijuma.'
  },
  YourOfferForCandidate: {
    id: `${scope}.YourOfferForCandidate`,
    defaultMessage: 'Preporučujemo da uneseš što više informacija u ovom segmentu. Kandidati cene kada je poslodavac transparentan u vezi sa uslovima koje nudi svojim budućim zaposlenima.'
  },
  MailSameAsUsername: {
    id: `${scope}.ailSameAsUsername`,
    defaultMessage: 'Tvoj email će biti korišćen kao korisnički email.'
  },
  MailChangeInfo: {
    id: `${scope}.mailChangeInfo`,
    defaultMessage: 'Promena email adrese nije moguća na ovaj način. Ukoliko je zaista neophodno da promeniš email adresu, molimo te da se obratiš loop korisničkoj podršci putem kontakt forme, nakon čega će tvoj zahtev biti obrađen u najkraćem mogućem roku, a u skladu sa <a>Uslovima korišćenja</a>.'
  },
  PhonelChangeInfo: {
    id: `${scope}.phonelChangeInfo`,
    defaultMessage: 'Promena broja telefona nije moguća na ovaj način. Ukoliko je zaista neophodno da promeniš broj telefona, molimo te da se obratiš loop korisničkoj podršci putem kontakt forme, nakon čega će tvoj zahtev biti obrađen u najkraćem mogućem roku, a u skladu sa <a>Uslovima korišćenja</a>.'
  },

  UsernameChangeInfo: {
    id: `${scope}.usernameChangeInfo`,
    defaultMessage: 'Promena korisničkog email-a nije moguća na ovaj način. Ukoliko je zaista neophodno da promeniš korisnički email, molimo te da se obratiš loop korisničkoj podršci putem kontakt forme, nakon čega će tvoj zahtev biti obrađen u najkraćem mogućem roku, a u skladu sa <a>Uslovima korišćenja</a>.'
  },
  PhoneNumberForLogin: {
    id: `${scope}.phoneNumberForLogin`,
    defaultMessage: 'Ovaj broj telefona ćeš koristiti prilikom prijavljivanja.'
  },

  // Nav and Footer
  AboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: 'O nama'
  },
  WhoAreWe: {
    id: `${scope}.whoAreWe`,
    defaultMessage: 'Ko smo?'
  },
  FAQs: {
    id: `${scope}.faqs`,
    defaultMessage: 'Česta pitanja'
  },
  ForEmployer: {
    id: `${scope}.forEmployer`,
    defaultMessage: 'Za poslodavce'
  },
  SetJob: {
    id: `${scope}.setJob`,
    defaultMessage: 'Postavi oglas'
  },
  ReturnJob: {
    id: `${scope}.returnJob`,
    defaultMessage: 'Vrati oglas'
  },
  PriceListAds: {
    id: `${scope}.priceListAds`,
    defaultMessage: 'Cenovnik oglasa'
  },
  RegisteredCandidates: {
    id: `${scope}.registeredCandidates`,
    defaultMessage: 'Procena prijavljenih kandidata'
  },
  BestCandidates: {
    id: `${scope}.bestCandidates`,
    defaultMessage: 'Odabir najboljih kandidata'
  },
  Preselection: {
    id: `${scope}.preselection`,
    defaultMessage: 'Procena prijavljenih kandidata'
  },
  Selection: {
    id: `${scope}.selection`,
    defaultMessage: 'Odabir najboljih kandidata'
  },
  MarketResearch: {
    id: `${scope}.marketResearch`,
    defaultMessage: 'Istraživanje tržišta'
  },
  ProTest: {
    id: `${scope}.proTest`,
    defaultMessage: 'Profesionalna procena i testiranje'
  },
  ProDev: {
    id: `${scope}.proDev`,
    defaultMessage: 'Profesionalni razvoj'
  },
  SetNews: {
    id: `${scope}.setNews`,
    defaultMessage: 'Postani glas tržišta'
  },
  ReadNews: {
    id: `${scope}.readNews`,
    defaultMessage: 'U toku sa glasom tržišta'
  },
  SetEvent: {
    id: `${scope}.setEvent`,
    defaultMessage: 'Oglasi događaj'
  },
  readEvent: {
    id: `${scope}.readEvent`,
    defaultMessage: 'Događaji na vidiku'
  },
  ForYou: {
    id: `${scope}.forYou`,
    defaultMessage: 'Za tebe'
  },
  FindJob: {
    id: `${scope}.findJob`,
    defaultMessage: 'Pronađi posao'
  },
  FindAboutEarnings: {
    id: `${scope}.findAboutEarnings`,
    defaultMessage: 'Saznaj o zaradama'
  },
  AssessYourself: {
    id: `${scope}.assessYourself`,
    defaultMessage: 'Proceni se'
  },
  DevelopCarrer: {
    id: `${scope}.developCarrer`,
    defaultMessage: 'Razvoj karijere'
  },
  DevelopYourself: {
    id: `${scope}.developYourself`,
    defaultMessage: 'Razvij se'
  },
  InGeneral: {
    id: `${scope}.inGeneral`,
    defaultMessage: 'Opšte'
  },
  PrivacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Politika privatnosti'
  },
  TermsOfUse: {
    id: `${scope}.termsOfUse`,
    defaultMessage: 'Uslovi i pravila korišćenja'
  },
  JobsToMail: {
    id: `${scope}.jobsToMail`,
    defaultMessage: 'Primaj ponude poslova na email'
  },
  Companies: {
    id: `${scope}.companies`,
    defaultMessage: 'Kompanije'
  },
  Employers: {
    id: `${scope}.employers`,
    defaultMessage: 'Poslodavci'
  },
  Employer: {
    id: `${scope}.employer`,
    defaultMessage: 'Poslodavac'
  },
  Country: {
    id: `${scope}.country`,
    defaultMessage: 'Država'
  },
  City: {
    id: `${scope}.city`,
    defaultMessage: 'Grad'
  },
  AllCity: {
    id: `${scope}.allCity`,
    defaultMessage: 'Svi gradovi'
  },
  Candidates: {
    id: `${scope}.candidates`,
    defaultMessage: 'Ukupan broj izvršilaca'
  },
  // Contact Form
  ContactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Piši nam!'
  },
  YourEmail: {
    id: `${scope}.yourEmail`,
    defaultMessage: 'Tvoja email adresa'
  },
  UserTitle: {
    id: `${scope}.user`,
    defaultMessage: 'Korisnik'
  },
  Description: {
    id: `${scope}.description`,
    defaultMessage: 'Ovde upiši tekst poruke...'
  },
  SendMessageForm: {
    id: `${scope}.sendMessageForm`,
    defaultMessage: 'Pošalji poruku'
  },
  // Loop Info
  LoopEmail: {
    id: `${scope}.loopEmail`,
    defaultMessage: '<a>office@loop.rs</a>'
  },
  LoopWorkTime: {
    id: `${scope}.loopWorkTime`,
    defaultMessage: 'Pon - Pet | 9 - 17'
  },
  LoopCopyright: {
    id: `${scope}.loopCopyright`,
    defaultMessage: '<b>loop.</b> Sva prava zadržana.'
  },
  LoopCopyrightSign: {
    id: `${scope}.loopCopyrightSign`,
    defaultMessage: '©'
  },
  noNotifications: {
    id: `${scope}.noNotifications`,
    defaultMessage: 'Nema novih notifikacija'
  },
// 403, 404, 500 Page
  Message403: {
    id: `${scope}.message403`,
    defaultMessage: 'Zabranjeno!'
  },
  Message403Desc: {
    id: `${scope}.message403Desc`,
    defaultMessage: 'Nažalost nemaš dozvolu da vidis ovu stranicu.'
  },
  Message500: {
    id: `${scope}.message500`,
    defaultMessage: 'Uuups! Stranica ne postoji!'
  },
  Message500Desc: {
    id: `${scope}.message500Desc`,
    defaultMessage: 'Izgleda da pokušavate da pristupite stranici koja je izbrisana ili nikada nije postojala.'
  },
  Message404: {
    id: `${scope}.message404`,
    defaultMessage: 'Uuups! Stranica nije pronađena!'
  },
  AdPaused: {
    id: `${scope}.adPaused`,
    defaultMessage: 'Oglas je uspešno pauziran. Trenutno nije vidljiv na sajtu, a prijave su onemogućene.'
  },
  AdUnpaused: {
    id: `${scope}.adUnpaused`,
    defaultMessage: 'Oglas je uspešno nastavljen. Vidljiv je na sajtu, a prijave su opet moguće.'
  },
  NumberOfNotifications: {
    id: `${scope}.numberOfNotifications`,
    defaultMessage: 'Broj notifikacija'
  },
  // for all icons
  AltIcon: {
    id: `altIcon`,
    defaultMessage: 'ikonice'
  },
  User: {
    id: 'user',
    defaultMessage: 'Korisnik'
  },
  WeAskOfYou: {
    id: `${scope}.weAskOfYou`,
    defaultMessage: 'Profil odgovarajućeg kandidata',
  },
  Other: {
    id: `${scope}.other`,
    defaultMessage: 'Ostalo',
  },
  RequiredLanguageKnowledge: {
    id: `${scope}.requiredLanguageKnowledge`,
    defaultMessage: 'Poznavanje jezika',
  },
  WorkExperience: {
    id: `${scope}.workExperience`,
    defaultMessage: 'Traženo radno iskustvo',
  },
  EducationProfil: {
    id: `${scope}.educationProfil`,
    defaultMessage: 'Traženi obrazovni profil',
  },
  RequiredTechnicalKnowledge: {
    id: `${scope}.requiredTechnicalKnowledge`,
    defaultMessage: 'Tehnička i IT znanja',
  },
  cookiePolicy: {
    id: `${scope}.cookiePolicy`,
    defaultMessage: 'Polisa kolačića (Cookies policy)',
  },
  HeaderReviewJob: {
    id: `${scope}.headerReviewJob`,
    defaultMessage: 'Pogledajte izmene na samom oglasu'
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Dokumenti'
  },
  SuccessContactUs: {
    id: `${scope}.successContactUs`,
    defaultMessage: 'Uspešno si nas kontaktirao/la.'
  },
  YourStatusChanged: {
    id: `${scope}.yourStatusChanged`,
    defaultMessage: 'Tvoj status je promenjen.'
  },
  TransactionsSuccessMessage: {
    id: `${scope}.transactionsSuccessMessage`,
    defaultMessage: 'Uspešno si promenio status!'
  },
  Coupons: {
    id: `${scope}.coupons`,
    defaultMessage: 'Kuponi'
  },
  HeaderJobReviewTextFirst: {
    id: `${scope}.headerJobReviewTextFirst`,
    defaultMessage: 'Oglas je kreiran po upitu koji si prosledio/la loop konsulantu.'
  },
  HeaderJobReviewTextSecond: {
    id: `${scope}.headerJobReviewTextSecond`,
    defaultMessage: 'Ukoliko su tvoji zahtevi ispunjeni, oglas možeš aktivirati klikom na "Postavi oglas".'
  },
  HeaderJobReviewTextThird: {
    id: `${scope}.headerJobReviewTextThird`,
    defaultMessage: 'Ako postoje dodatni zahtevi, klikom na "Izmeni" možeš uneti informacije u skladu sa kojima će loop konsultant dopuniti/izmeniti kreiran oglas.'
  },
  NoJobsEmptyMessage: {
    id: `${scope}.noJobsEmptyMessage`,
    defaultMessage: 'Trenutno nema otvorenih pozicija'
  },
  NoCriteriaSet: {
    id: `${scope}.noCriteriaSet`,
    defaultMessage: 'Kriterijumi nisu podešeni.'
  },
  NoJobsForCriteria: {
    id: `${scope}.noJobsForCriteria`,
    defaultMessage: 'Trenutno nema oglasa u skladu sa postavljenim kriterijumima.'
  },
  SeeMore: {
    id: `${scope}.seeMore`,
    defaultMessage: 'Vidi više'
  },
  RePost: {
    id: `${scope}.rePost`,
    defaultMessage: 'Obnovi'
  },
  ReNewJobTill: {
    id: `${scope}.reNewJobTill`,
    defaultMessage: 'Produži oglas do'
  },
  SucessReNewJob: {
    id: `${scope}.sucessReNewJob`,
    defaultMessage: 'Uspešno ste produžili oglas!'
  },
  Client: {
    id: `${scope}.client`,
    defaultMessage: 'Klijent'
  },
  OrderDate: {
    id: `${scope}.orderDate`,
    defaultMessage: 'Datum naručivanja'
  },
  ActivationDate: {
    id: `${scope}.actvationDate`,
    defaultMessage: 'Datum aktivacije'
  },
  TotalNumberOfCandidates: {
    id: `${scope}.totalNumberOfCandidates`,
    defaultMessage: 'Ukupan broj prijava'
  },
  TotalNumberOfProcessedCandidates: {
    id: `${scope}.totalNumberOfProcessedCandidates`,
    defaultMessage: 'Broj obrađenih prijava'
  },
  SuccessDeleteItem: {
    id: `${scope}.successDeleteItem`,
    defaultMessage: 'Uspešno ste obrisali upit!'
  },
  SuccessCreatePrice: {
    id: `${scope}.successCreatePrice`,
    defaultMessage: 'Uspešno ste kreirali cenu!'
  },
  Job404: {
    id: `${scope}.Job404`,
    defaultMessage: 'Izgleda da je ovaj oglas istekao!'
  },
  Job404Description: {
    id: `${scope}.Job404Description`,
    defaultMessage: 'Nemoj da te to obeshrabri! Sigurni samo da ima još nešto za tebe!'
  },
  Event404: {
    id: `${scope}.Event404`,
    defaultMessage: 'Ovaj događaj je istekao'
  },
  News404: {
    id: `${scope}.News404`,
    defaultMessage: 'Ova vest je istekla'
  },
  DocumentFormatError: {
    id: `${scope}.documentFormatError`,
    defaultMessage: 'Dokument u ovom formatu nije moguće očitati.'
  },
  DownloadFormat: {
    id: `${scope}.documentFormatError`,
    defaultMessage: 'Potrebno je <b>preuzeti dokument klikom</b> na ikonicu iznad.'
  },
  Id: {
    id: `${scope}.id`,
    defaultMessage: 'ID'
  },
  CompanyDescription: {
    id: `${scope}.companyDescription`,
    defaultMessage: 'Opis kompanije'
  },
  CreatePrice: {
    id: `${scope}.createPrice`,
    defaultMessage: 'Kreiranje cene'
  },
  BidSent: {
    id: `${scope}.bidSent`,
    defaultMessage: 'Poslata ponuda'
  },
  SubService: {
    id: `${scope}.subService`,
    defaultMessage: 'Tip usluge'
  },
  Payed: {
    id: `${scope}.payed`,
    defaultMessage: 'Uplaćeno'
  },
  ApprovedBeforePayed: {
    id: `${scope}.approvedBeforePayed`,
    defaultMessage: 'Aktivirano pre uplate'
  },
  LoopPreselection : {
    id: `${scope}.loopPreselection`,
    defaultMessage: 'Loop procena prijavljenih kandidata'

  },
  LoopPreselectionTailorMade : {
    id: `${scope}.loopPreselectionTailorMade`,
    defaultMessage: 'Prilagođeno rešenje'

  },
  LoopSelectionCbiInterview : {
    id: `${scope}.loopSelectionCbiInterview`,
    defaultMessage: 'Intervju baziran na kompetencijama | Do 5 preporuka'
  },
  LoopSelectionCbiInterviewTailorMade : {
    id: `${scope}.loopSelectionCbiInterviewTailorMade`,
    defaultMessage: 'Intervju baziran na kompetencijama | Prilagođeno rešenje'
  },
  LoopSelectionStandardisedInterview : {
    id: `${scope}.loopSelectionStandardisedInterview`,
    defaultMessage: 'Selekcijski intervju | Do 5 preporuka'
  },
  LoopSelectionStandardisedInterviewTailorMade : {
    id: `${scope}.loopSelectionStandardisedInterviewTailorMade`,
    defaultMessage: 'Selekcijski intervju | Prilagođeno rešenje'
  },
  DownloadData: {
    id: `${scope}.downloadData`,
    defaultMessage: 'Preuzmi podatke'
  },
  Settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Podešavanja'
  },
  EmptyLabel: {
    id: `${scope}.emptyLabel`,
    defaultMessage: 'Nema rezultata pretrage',
  },
  DownloadCSV: {
    id: `${scope}.downloadCSV`,
    defaultMessage: 'Download CSV-a',
  },
  DownloadCVS: {
    id: `${scope}.downloadCVS`,
    defaultMessage: 'Download CVs',
  },
  PhoneVerification: {
    id: `${scope}.PhoneVerification`,
    defaultMessage: 'Verifikacija telefonskog broja'
  },
  TokenResetPasswordExpired: {
    id: `${scope}.TokenResetPasswordExpired`,
    defaultMessage: 'Token za promenu lozinke je istekao. Molimo Vas da ponovo zatražite promenu lozinke'
  },
  YourCircleOfOpportunitiesInfo: {
    id: `${scope}.yourCircleOfOpportunitiesInfo`,
    defaultMessage: 'Pažljivo osluškujući potrebe tržišta rada više od 15 godina, naučili smo da je za dolazak do idealne poslovne prilike potrebno mnogo više od konkurisanja na oglase. Znamo koliko ti je bitno da te neko prati, usmeri i pomogne u potrazi za poslom, ili ti pruži potrebne alate za razvoj karijere. Zato smo stvorili <b>loop</b>!'
  },
  searching: {
    id: `${scope}.searching`,
    defaultMessage: 'Pretraga u toku...',
  },
  emptyLabel: {
    id: `${scope}.emptyLabel`,
    defaultMessage: 'Nema rezultata pretrage',
  },
  SEOTitle: {
    id: `${scope}.SEOTitle`,
    defaultMessage: 'SEO naslov (Ponoviti naslov teksta koji uključuje ključnu reč teksta)',
  },
  SEODesc: {
    id: `${scope}.SEODesc`,
    defaultMessage: 'SEO opis (Podnaslov teksta koji uključuje ključnu reč teksta)',
  },
  SEOTitleNews: {
    id: `${scope}.SEOTitleNews`,
    defaultMessage: 'SEO naslov (ovo je ono što će se videti na pretraživačima poput Google-a, Safarija, Binga itd. Idealno je da naslov priče sadrži ključnu reč(i) vezane za sam članak)',
  },
  SEOTitleNewsPlaceholder: {
    id: `${scope}.SEOTitleNewsPlaceholder`,
    defaultMessage: 'Ponoviti naziv (naslov) priče',
  },
  SEODescNews: {
    id: `${scope}.SEODescNews`,
    defaultMessage: 'SEO opis (Podnaslov teksta koji će se videti na pretraživačima poput Google-a, Safarija, Binga itd. Idealno je da naslov priče sadrži ključnu reč(i) vezane za sam članak)',
  },
  SEODescNewsPlaceholder: {
    id: `${scope}.SEODescNewsPlaceholder`,
    defaultMessage: 'Ovo je mesto gde treba da upišete okvirno 1 rečenicu (do 155 karaktera) koja će se videti ispod naslova u Google pretrazi, kao i kada se članak deli putem slanja linka. Ova rečenica teksta treba da po strukturi i stilu bude kao podnaslov, dakle da nagoveštava šta je u tekstu i motiviše ljude da ga pročitaju; dakle daje detaljnije informacije o tekstu. Važno je da sadrži ključnu reč(i)',
  },
  Link: {
    id: `${scope}.link`,
    defaultMessage: 'Kreiraj naziv linka, primer: https://loop.rs/glas-trzista/TvojNazivLinka',
  },
  SEOImage: {
    id: `${scope}.SEOImage`,
    defaultMessage: 'Naslovna slika (Naslovna slika teksta koja optimizuje objavu)',
  },
  SEOImageNews: {
    id: `${scope}.SEOImageNews`,
    defaultMessage: 'Naslovna slika članka (ponoviti naslovnu sliku kako bi bila vidljiva prilikom deljenja linka i bila optimizovana)',
  },
  Keywords: {
    id: `${scope}.keywords`,
    defaultMessage: 'Ključne reči (Svaku ključnu reč odvoji zarezom i razmakom, primer: posao, oglas za posao, karijera, …)',
  },
  AltImage: {
    id: `${scope}.altImage`,
    defaultMessage: 'Alternativni opis slike (Kratak opis slike korišćenje ključne reči teksta)',
  },
  AltImageNews: {
    id: `${scope}.AltImageNews`,
    defaultMessage: 'Kratak opis slike',
  },
  AltImageNewsPlaceholder: {
    id: `${scope}.AltImageNewsPlaceholder`,
    defaultMessage: 'Ključne reči u formi proste sintagme koja opisuje sliku. Primer: finansijski menadžer posao',
  },
  SuccessfullyAddedEmail: {
    id: `${scope}.successfullyAddedEmail`,
    defaultMessage: 'Uspešno dodat email na listu dozvoljenih mejlova.',
  },
  SuccessfullyDeletedEmail: {
    id: `${scope}.successfullyDeletedEmail`,
    defaultMessage: 'Uspešno obrisan email sa liste dozvoljenih mejlova.',
  },
});
