// Import Immer
import produce from 'immer';

import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_ERROR,
  SAVE_DOCUMENTS,
  SAVE_DOCUMENTS_SUCCESS,
  SAVE_DOCUMENTS_ERROR,
  REMOVE_CV,
  REMOVE_COVER_LETTER,
  SET_COVER_LETTER,
  SET_CV
  
} from './constants'

// Interface
export interface documentsState {
  loading: boolean;
  uniqueId : string;
  cv: string;
  cv_name: string;
  cover_letter: string;
  cover_letter_name: string;
  error: any;
  cvExist: boolean;
  clExist: boolean;
}

// Setting Intial State
export const initialState: documentsState = {
  loading: false,
  uniqueId: '',
  cv: '',
  cv_name: '',
  cover_letter: '',
  cover_letter_name: '',
  error: null,
  cvExist: false,
  clExist: false,
};

/* eslint-disable default-case, no-param-reassign */
const documentsReducer = (state = initialState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_DOCUMENTS:
        draft.loading = true;
        break;
      case GET_DOCUMENTS_SUCCESS: 
        draft.cv = action.response.data.cv?.unique_id;
        draft.cv_name = action.response.data.cv?.original_name;
        draft.cover_letter = action.response.data.cover_letter?.unique_id;
        draft.cover_letter_name = action.response.data.cover_letter?.original_name;
        draft.uniqueId = action.response.data.unique_id;
        draft.loading = false;
        draft.cvExist = action.response.data.cv?.exist;
        draft.clExist = action.response.data.cover_letter?.exist;
        break;      
      case GET_DOCUMENTS_ERROR:
        draft.error = action.response.error;
        draft.loading = false;
        break;
      case SAVE_DOCUMENTS:
        draft.loading = true;
        break;  
      case SAVE_DOCUMENTS_SUCCESS:
        draft.loading = false;
        break;  
      case SAVE_DOCUMENTS_ERROR:
        draft.loading = false;
        break;  
      case REMOVE_CV:
        draft.cv = '';
        draft.cv_name = '';
        break
      case REMOVE_COVER_LETTER:
        draft.cover_letter = '';
        draft.cover_letter_name = '';
        break;  
      case SET_CV:
        draft.cv = action.id;
        draft.cv_name = action.name;
        draft.cvExist = true;
        break
      case SET_COVER_LETTER:
        draft.cover_letter = action.id;
        draft.cover_letter_name = action.name;
        draft.clExist = true;
        break
    }
  });

export default documentsReducer;
