import produce from 'immer';

import {
  MESSAGES_REQUEST,
  MESSAGES_SUCCESS,
  MESSAGES_ERROR,
  DELETE_ITEM,
  DELETE_ITEM_ERROR,
  GET_JOBS_SUCCESS,
  REQUEST_CONVERSATION,
  REQUEST_CONVERSATION_ERROR,
  REQUEST_CONVERSATION_SUCCESS,  
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  REQUEST_SEARCH_USERS,
  REQUEST_SEARCH_USERS_SUCCESS,
  REQUEST_SEARCH_USERS_ERROR,
  DISPLAY_MODAL,
  RESET_FORM,
  OPEN_CONVERSATION_WINDOW,
  CHAT_UPLOAD_START,
  CHAT_UPLOAD_PROGRESS,
  CHAT_UPLOAD_SUCCESS,
  REQUEST_CONVERSATION_CLEAR,
  REQUEST_CONVERSATION_NEW,
  CANCEL_UPLOAD,
  PRESIGNED_REQUEST_CHAT
} from './constants';

import {Message} from '../../models/message'

export interface messagesState {
  messages: Message[] | null;
  conversationMessages: Message[];
  error: boolean;
  showModal: boolean;
  isRead: boolean;
  loading: boolean;
  conversationLoading: boolean;
  sendMessageLoading: boolean;
  jobs: [],
  jobId: string;
  recipientIds: Array<string>;
  entityType: string,
  entityId: string;
  threadId: string;
  users: Array<any>;
  currentPage: number;
  pageCount: number;
  displayMiniConversationWindow: boolean;
  file: any;
  files: Array<any>,
  progress: Number,
  conversationToken: string | boolean;
  newConversation: boolean;
  conversationSubject: string;
  recipient : any;
}

export const initialState: messagesState = {
  messages: null,
  error: false,
  loading: false,
  conversationLoading: false,
  sendMessageLoading: false,
  isRead: false,
  showModal: false,
  jobs: [],
  conversationMessages: [],
  recipientIds: [],
  jobId: '',
  entityType: 'direct_message',
  entityId: '',
  threadId: '',
  users: [],
  currentPage: 1,
  pageCount: 1,
  displayMiniConversationWindow: false,
  progress: 0,
  file: null,
  files: [],
  conversationToken: false,
  newConversation: false,
  conversationSubject: '',
  recipient: null
};

/* eslint-disable default-case, no-param-reassign */
const messagesReducer = (state = initialState, action: any) =>

  produce(state, draft => {
    switch (action.type) { 
      case MESSAGES_REQUEST:
        draft.loading = true;
        draft.progress = 0;
        break;
      case MESSAGES_SUCCESS:
        draft.loading = false;
        draft.currentPage = action?.response?.data?.meta ? action?.response?.data?.meta?.current_page : action?.response?.data?.current_page;
        draft.pageCount = action?.response?.data?.meta ? action?.response?.data?.meta?.last_page : action?.response?.data?.last_page;
        draft.messages = action.response.data.data;
       
        break;
      case MESSAGES_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case DELETE_ITEM:
        draft.loading = true;
        break;
      case DELETE_ITEM_ERROR:
        draft.loading = false;
        break;  
      case GET_JOBS_SUCCESS:
        draft.jobs = action.response.data;  
        draft.error = action.error;
        break;
      case REQUEST_CONVERSATION:
        draft.conversationLoading = true;  
        draft.recipientIds = action.payload.recipientIds
        draft.jobId = action.payload.jobId
        draft.entityId = action.payload.entityId
        draft.entityType = action.payload.entityType
        draft.threadId = action.payload.threadId
        draft.showModal = action.modal;
        draft.newConversation = false; 
        break;      
      case REQUEST_CONVERSATION_ERROR:
        draft.conversationLoading = false;  
        break;
      case REQUEST_CONVERSATION_SUCCESS:
        draft.conversationLoading = false;
        if(action.response.data.data) {
          draft.conversationMessages = [...state.conversationMessages, ...action.response.data.data];
        }
        draft.conversationToken = action.response.data.token;
        draft.conversationSubject = action.response.data.subject;
        draft.threadId = action.response.data.thread_id; 
        draft.recipient = action.response.data.recipient; 
        break;
      case SEND_MESSAGE_REQUEST:
        draft.sendMessageLoading = true;
        break;
      case SEND_MESSAGE_SUCCESS:
        draft.sendMessageLoading = false;
        draft.isRead = true;
        draft.newConversation = false;
   
        if(action.responseAll.type === 'new' ) {

             draft.conversationSubject = action.responseAll.response.data.subject
        }  

        draft.recipientIds = action.responseAll.response.data.recipient_id
        draft.jobId = action.responseAll.response.data.job_id
        draft.entityId = action.responseAll.response.data.entity_id
        draft.entityType = action.responseAll.response.data.entity_type
        draft.threadId = action.responseAll.response.data.thread_id
        draft.conversationMessages = [action.responseAll.response.data, ...state.conversationMessages];
        // } 
        
        break;
      case SEND_MESSAGE_ERROR:
        draft.sendMessageLoading = false;
        draft.error = action.error;
        break;
      case DISPLAY_MODAL:
        if(!action.status) {
          draft.threadId = ''
        }
        draft.showModal = action.status;
        break;
      case REQUEST_SEARCH_USERS:
        break;
      case REQUEST_SEARCH_USERS_SUCCESS:
        draft.users = action.users;
        break;
      case REQUEST_SEARCH_USERS_ERROR:
        break;
      case RESET_FORM:
        draft.recipientIds = []
        draft.jobId = ''
        draft.entityId = ''
        draft.entityType = 'direct_message'
        draft.threadId = ''


        break;
      case OPEN_CONVERSATION_WINDOW:
        draft.displayMiniConversationWindow = action.open;
        break;
      case CHAT_UPLOAD_START:
        draft.progress = 1;
        break;
      case CHAT_UPLOAD_PROGRESS:
        draft.progress = action.progress.percentage === 0 ? 1 : action.progress.percentage; 
        break;
      case PRESIGNED_REQUEST_CHAT:
      
        draft.progress = 1;
                
        break;

        
      case CHAT_UPLOAD_SUCCESS:
      
        draft.progress = 100;
                
        break;   
        
      case REQUEST_CONVERSATION_CLEAR:
        draft.conversationToken = false;
        draft.conversationMessages = [];
        break;
        
      case REQUEST_CONVERSATION_NEW:
        draft.recipientIds = [];
        draft.showModal = true;
        draft.newConversation= true;
        draft.conversationToken = false;
        draft.conversationMessages = [];
        draft.recipient = null;
        break;  
      case CANCEL_UPLOAD:
        draft.progress = 0;
        break;     

    }
  });

export default messagesReducer;
