import { PRESIGNED_REQUEST, DELETE_REQUEST, DELETE_FILES_BY_NAMESPACES_REQUEST, DELETE_ALL_FILES } from './constants';

export function getPreSignedUrl(file: File, fieldName: string) {
  return {
    type: PRESIGNED_REQUEST,
    file,
    fieldName,
  };
} 

export function deleteFile(id: string) {
  return {
    type: DELETE_REQUEST,
    id,
  };
}

export function deleteFilesByNamespaces(namespace: string[]) {
  return {
    type: DELETE_FILES_BY_NAMESPACES_REQUEST,
    namespace,
  };
}


export function deleteAllFiles() {
  return {
    type: DELETE_ALL_FILES,
  };
}


