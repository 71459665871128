import { defineMessages } from 'react-intl';

export const scope = 'loop.containers.Login';

export default defineMessages({
  Header: {
    id: `${scope}.header`,
    defaultMessage: 'Uloguj se',
  },
  Text: {
    id: `${scope}.text`,
    defaultMessage: 'Budi u krugu najboljih prilika.',
  },
  ButtonForgottenPass: {
    id: `${scope}.butttonForgottenPass`,
    defaultMessage: 'Zaboravio/la si lozinku?',
  },
  Email: {
    id: `${scope}.email`,
    defaultMessage: 'email',
  },
  Password: {
    id: `${scope}.password`,
    defaultMessage: 'Lozinka',
  },
  Phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Telefon',
  },
  Click: {
    id: `${scope}.click`,
    defaultMessage: 'Kliknite',
  },
  Here: {
    id: `${scope}.here`,
    defaultMessage: 'ovde',
  },
  RegisterUser: {
    id: `${scope}.registerUser`,
    defaultMessage: 'Nemaš nalog?',
  },
  InvalidUsernamePassword: {
    id: `${scope}.InvalidUsernamePpassword`,
    defaultMessage: 'Uneo/la si pogrešno korisničko ime i/ili lozinku ili ne postoji korisnik sa ovim kredencijalima.',
  },
  welcomeMorning: {
    id: `${scope}.welcomeMorning`,
    defaultMessage: `Dobro jutro, {first_name}`,
  },
  welcomeAfternoon: {
    id: `${scope}.welcomeAfternoon`,
    defaultMessage: `Dobar dan, {first_name}`,
  },
  welcomeEvening: {
    id: `${scope}.welcomeEvening`,
    defaultMessage: `Dobro vece, {first_name}`,
  },
  pleaseVerifyEmail: {
    id: `${scope}.pleaseVerifyEmail`,
    defaultMessage: 'Uneta adresa nije verifikovana. Molim te klikni na <b>pošalji verifikacioni email ponovo</b> i proveri svoje sanduče. Ukoliko ne vidiš mail za verifikaciju u primarnom sandučetu, molim te pogledaj nepoželjnu poštu/spam ili promocije.'
  },
  pleasePhoneNumber: {
    id: `${scope}.pleasePhoneNumber`,
    defaultMessage: 'Uneti broj telefona nije verifikovan. Klikni na <b>pošalji verifikacioni kod ponovo</b>, a nakon što uneseš novi kod, klikni na <b>potvrdi</b>.'
  },
  pleaseWaitForAdminApproval: {
    id: `${scope}.pleaseWaitForAdminApproval`,
    defaultMessage: 'Tvoj biznis nalog još uvek nije odobren od strane loop administratora. Molimo te za malo strpljenja.'
  },
  pleaseVerifyPhoneNumber: {
    id: `${scope}.pleaseVerifyPhoneNumber`,
    defaultMessage: 'Uneti broj telefona nije verifikovan.'
  },
  verifyEmailAddress: {
    id: `${scope}.verifyEmailAddress`,
    defaultMessage: 'Na naznačenu email adresu postlat ti je email. Potrebno je da verifikujes email adresu.',
  },
  verifyPhoneNumber: {
    id: `${scope}.verifyPhoneNumber`,
    defaultMessage: 'Na broj telefona poslat ti je verifikacioni kod. Potrebno je da verifikujes svoj profil.',
  },
  verifyEmailAddressError: {
    id: `${scope}.verifyEmailAddressError`,
    defaultMessage: 'Greška prilikom pokušaja slanja.',
  },
  sendVerificationEmailAgain: {
    id: `${scope}.sendVerificationEmailAgain`,
    defaultMessage: 'Pošalji verifikacioni email ponovo.',
  },
  sendVerificationCodeAgain: {
    id: `${scope}.sendVerificationCodeAgain`,
    defaultMessage: 'Pošalji verifikacioni kod ponovo.',
  },
});
