
import React, {FC, memo} from 'react';
import {Link, generatePath} from "react-router-dom";
import routes from '../../../config/routes';
import styled from 'styled-components';
import colors from '../../../config/colors';
import HeaderDropdown from '../../../components/Header/HeaderDropdown';

interface IHeaderDropdownInner  {
  toggleDropdownInfo: (e: any) => void,
  openDropdown : boolean,
  closeMenu: any,
  isFixed : boolean,
}

const HeaderDropdownInner: FC<IHeaderDropdownInner> = ({openDropdown, isFixed, toggleDropdownInfo, closeMenu}) => {

  return (
    <HeaderDropdownLink tabIndex={0}  onBlurCapture={toggleDropdownInfo}  onClick={toggleDropdownInfo} className={`${openDropdown ? 'dropdownOpen' : ''} ${isFixed ? 'dropdownFixed' : ''}`}>
      Informiši se <span><svg className="dropdown-arrow"><use xlinkHref={`#arrow-down`} /></svg></span>
      <ul >
        <li><Link onClick={closeMenu} to={generatePath(routes.NewsPage)}><svg><use xlinkHref={`#news`} /></svg> Glas Tržišta</Link></li>
        <li><Link onClick={closeMenu} to={generatePath(routes.EventsPage)}><svg><use xlinkHref={`#event`} /></svg> Događaji na vidiku</Link></li>
      </ul>
    </HeaderDropdownLink>
  )
}


const HeaderDropdownLink = styled(HeaderDropdown)`
  position: relative;
  margin-left: 30px;
  cursor: pointer;

  &:hover {
    color: ${colors.PRIMARY_ORANGE};

    @media (max-width: 1100px) {
      color: ${colors.BLACK};
    }

    span svg {
      fill: ${colors.PRIMARY_ORANGE};

      @media (max-width: 1100px) {
        fill: ${colors.BLACK};
      }
    }
  }

  &.dropdownOpen {
    color: ${colors.PRIMARY_ORANGE};

    span svg {
      fill: ${colors.PRIMARY_ORANGE};
    }
  }

  span {
    margin: -1px 0 0 4px;
  }

  ul {
    top: 100%;
    margin-top: 16px;

    li {
      line-height: normal;
    }

    svg {
      fill: none;
      stroke: ${colors.PRIMARY_PURPLE};
    }
  }
`;




export default memo(HeaderDropdownInner);
