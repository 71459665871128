const routes = {
    HomePage : '/',
    LoginPage : '/login/:type?',
    DashboardPage: '/dashboard',
    error403 : '/error403',
    error404 : '/error404',

    // News
    NewsPage : '/glas-trzista',
    NewsEditCTA: '/glas-trzista/create-package-info',
    NewsCreate: '/glas-trzista/kreiranje/:package_id?',
    NewsEdit: '/dashboard/glas-trzista/edit/:urlSlug?/:id?/:assignedTo?/:taskId?/:taskType?',
    NewsDetailPage: '/glas-trzista/:urlSlug?/:id?/:assignedTo?/:taskId?/:taskType?',
    NewsCategoryPage: '/glas-trzista/kategorija/:slug',
    NewsAdminPage: '/dashboard/news',

    // Events
    EventsPage : '/dogadjaji',
    EventCreate: '/dogadjaj/kreiranje/:package_id?',
    EventEdit: '/event/edit/:urlSlug?/:id?',
    EventAdminEdit: '/dashboard/event/edit/:urlSlug?/:id?/:assignedTo?/:taskId?/:taskType?',
    
    EventsDetailPage: '/dogadjaj/:urlSlug?/:id?/:assignedTo?/:taskId?/:taskType?',
    EventEditCTA: '/event/create-package-info',
    EventReview: '/event/review/:id?',
    EventsAdminPage: '/dashboard/events',

    SupportAndDevelopment: '/podrska-i-razvoj',

    // Soon Page
    SoonPage: '/uskoro',

    // About Us
    AboutUs: '/o-nama',

    // FAQs
    FAQS: '/faq',
    FAQsingleCategory: '/faq/:category/:parentUniqueId',

    // Payment
    Transactions: '/dashboard/transactions',
    TransactionsAssignTo: '/dashboard/transactions/:uniqueId',
    Payment: '/dashboard/payment/:pay_type?/:type/:id/:bidId?',
    PaymentSuccess: '/payment/success/:id',
    PaymentFail: '/payment/fail/:id',

    // Packages
    Packages: '/dashboard/packages',
    AddPacket: '/dashboard/packages/add-packet/:id?',

    // Tasks
    Tasks: '/dashboard/tasks/:type?',
    AssignTask: '/dashboard/assign-task/:id?',
    AssignTaskSpecial: '/dashboard/assign-task-special/:id?',

    // Notification
    Notifications: '/dashboard/notifications',

    // Clients
    OurClients: '/dashboard/our-clients',
    AddClient:'/dashboard/add-client/:id?',

    // Stats
    Stats: '/dashboard/statistics/:id?',

    // Requested Services
    TaskServiceHistory: '/dashboard/task-service-history',
    RequestedServices: '/dashboard/requested-services',

    // Register Page
    RegisterPage: '/registracija/:userType?',
    Success: '/registracija/success/:userType',
    RegisterVerifyPhoneNumber: '/registracija/verifikacija/:phone/:pattern?',

    // Forgotten Password
    ForgottenPassword:'/forgotten-password',
    ForgottenPasswordNew:'/forgotten-password/reset',

    ResetPasswordPhoneNumber:'/zaboravljena-loznika/reset/:code',
    ForgottenPasswordPhone:'/zaboravljena-loznika/verifikacija-pokusaja-promene-lozinke/:phone',
    
    EmailVerify:'/email/verify',
    EmailReactivate: '/reaktivacija',
    Unsubscribe:'/odjava/:slug',

    // Policy and Terms
    PrivacyPolicy: '/politika-privatnosti',
    CookiesPolicy: '/cookies-policy',
    TermsOfUse: '/uslovi-i-pravila-koriscenja',

    // Users
    Users: '/dashboard/users',
    UserInfo: '/dashboard/user-info',
    AdminCreateUserPage: '/dashboard/users/create/:id?',
    ApproveUser: '/dashboard/users/approve/:uniqueId?/:taskId?',

    // Jobs
    Jobs: '/pronadji-posao',
    JobsFilterCity: '/pronadji-posao/grad/:city',
    JobsFilterFieldOfWork: '/pronadji-posao/kategorija/:fieldOfWork',
    JobsDetailByUrlSlug:`/poslovi/:industry/:slug`,
    JobsDetailByUrlSlugApply:`/poslovi/:industry/:slug/apliciraj`,
    JobsDetailPage: `/poslovi/preview/:industry/:urlSlug?/:companySlug?/:id?/:assignedTo?/:taskId?`,
    
    JobAdminCreate: '/job/create',
    JobCreateHelp: '/job/create/help/:packageId?',
    JobCreateStep: '/job/create/:type?/:step?/:packageId?/:id?',
    
    JobEdit: '/job/edit/:editStep?/:id?/:packageId?',
    JobCreateHr: '/job/hr-create/:step/:taskId?/:jobHelpId?/:id?',
    JobEditHr: '/job/hr-edit/:step/:taskId?/:id?',
    JobBussinesPreview: '/job/preview/:uniqueId',
    ReturnJobBussines: '/job/return/:uniqueId',
    JobsAdmin: '/jobs',
    AdminJobWithApplications: '/dashboard/user-jobs/:userUniqueId/job-applications',
    AdminJobApplications: '/dashboard/user-jobs/:userUniqueId/job-applications/:jobUniqueId',

    // Messages
    Messages: '/dashboard/messages',

    // Job Applications (Candidates List & Details)
    JobApplications: '/dashboard/job-applications/:id?',
    ApplicationDetail: '/dashboard/job-applications/candidate-application/:id',
    CandidateArchive: '/dashboard/job-applications/candidates/archived',
    FavoriteJobs: '/dashboard/my-favourite-jobs/:mailOpen?',

    // Edit and create for template
    EditTemplate: '/dashboard/edit-template/:id?',
    CreateTemplate: '/dashboard/create-template',

    // FAQs
    FaqGroups: `/dashboard/faqs-categories`,
    SingleFAQsCategory: '/dashboard/faqs-categories/:slug/:parentUniqueId',
    CreateFAQ: '/dashboard/faqs-categories/:slug/:parentUniqueId/edit-faq/:id?',
    CreateFAQGroup: '/dashboard/faqs-categories/edit-faq-group/:id?',

    // Categories (Jobs/News/Events)
    CategoryGroups: '/dashboard/categories',
    SingleCategory: '/dashboard/categories/:slug/:parentUniqueId',
    CreateCategories: '/dashboard/categories/:slug/:parentUniqueId/edit-category/:id?',

    //PreSelections
    PreselectionSelection: '/dashboard/:type',
    PreselectionSelectionCandidateList: '/dashboard/:type/canidate-list/:jobUniqueId',
    PreselectionCreate: '/preselection/create-package-info',
    SelectionCreate: '/selection/create-package-info',
    PreselectionJobList: '/request/:type/jobs-list',
    PreselectionChooseOption: '/request/:type/choose-option/:interviewType?/:id',
    PreselectionChooseInterview: '/request/:type/choose-interview/:id',
    PreselectionCustomOption: '/request/:type/custom-option/:id?/:interviewType',
    PreselectionJob: '/request/:type/add-comment/:id?/:interviewType',
    PreselectionReviewJob: '/request/:type/review-job/:id?',
    PreselectionCandidateList: '/dashboard/:type/hr/candidate-list/:id/task/:task_id?',
    PreselectionCandidateDetails: '/dashboard/:type/hr/candidate-details/:id/task/:task_id?',
    PreselectionCreatingPrice: '/dashboard/:type/creating-price/:taskId',

    // Price List
    OurServices: '/opis-usluga',
    PriceList: '/cenovnik-usluga',
    Coupons: '/dashboard/coupons',
    CreateCoupon: '/dashboard/coupons/create',

    // Employers
    //Employers: '/poslodavci',
    EmployerDetails: '/poslodavci/:company_slug/oglasi-za-posao',

    BidForService: '/bid-for-service/:bidId',
    DashboardHrAdminBid: '/dashboard/bid/:bidId?',

    // Choosen Ad Categories
    ChoosenAdCategories: '/dashboard/choosen-ad-categories',
}

export default routes;
