const scope = 'loop/Upload';

export const PRESIGNED_REQUEST = `${scope}/PRESIGNED_REQUEST`;
export const PRESIGNED_REQUEST_SUCCESS = `${scope}/PRESIGNED_REQUEST_SUCCESS`;
export const PRESIGNED_REQUEST_ERROR = `${scope}/PRESIGNED_REQUEST_ERROR`;

export const UPLOAD_REQUEST = `${scope}/UPLOAD_REQUEST`;
export const UPLOAD_START = `${scope}/UPLOAD_START`;
export const UPLOAD_PROGRESS = `${scope}/UPLOAD_PROGRESS`;
export const UPLOAD_SUCCESS = `${scope}/UPLOAD_SUCCESS`;
export const UPLOAD_FAILURE = `${scope}/UPLOAD_FAILURE`;

export const FILES_REQUEST = `${scope}/FILES_REQUEST`;
export const FILES_SUCCESS = `${scope}/FILES_SUCCESS`;
export const FILES_ERROR = `${scope}/FILES_ERROR`;

export const DELETE_ALL_FILES= `${scope}/DELETE_ALL_FILES`;

export const DELETE_REQUEST = `${scope}/DELETE_REQUEST`;
export const DELETE_FILES_BY_NAMESPACES_REQUEST = `${scope}/DELETE_FILES_BY_NAMESPACES_REQUEST`;

