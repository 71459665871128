import { 
  BOOK_TRANSACTION,
  GET_BUSINESS_TRANSACTIONS,
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  TRANSACTION_SIDEBAR,
  TRANSACTION_UNDER_COUNSTRUCTION,
  BOOK_BEFORE_TRANSACTION
} from './constants';

export function transactionsAction(page: number, search: string, filter: string) {
  return {
    type: GET_TRANSACTIONS,
    page,
    search,
    filter
  }
}

export function businessTransactionsAction(page: number, filter: string) {
  return {
    type: GET_BUSINESS_TRANSACTIONS,
    page,
    filter
  }
}

export function bookTransactionAction(data: any) {
  return {
    type: BOOK_TRANSACTION,
    data
  }
}


export function activateBeofreBookedTransactionAction(data: any) {
  return {
    type: BOOK_BEFORE_TRANSACTION,
    data
  }
}


export function sidbearAction(open: boolean) {
  return {
    type: TRANSACTION_SIDEBAR,
    open
  }
}

export function underounstructionAction(data: any) {
  return {
    type: TRANSACTION_UNDER_COUNSTRUCTION,
    data
  }
}

export function transactionAction(uniqueId: string) {
  return {
    type: GET_TRANSACTION,
    uniqueId
  }
}