import axios, {AxiosRequestConfig, AxiosInstance} from 'axios';
import {UserService} from '../../services/UserService';
import {BASE_URL} from '../../config/config';
import StoreService from '../StoreService';
import {logout} from '../../containers/App/actions';
import history from '../../utils/history';
import routes from '../../config/routes';

// let isAlreadyFetchingAccessToken : boolean = false;
// let subscribers : Array<any> = [];

const conf: AxiosRequestConfig = {
  baseURL: BASE_URL,
  timeout: 20000,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

const apiInstance: AxiosInstance = axios.create(conf);

apiInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {

  const localStorageToken = UserService.getJwtFromLocalStorage();
  const token = localStorageToken && localStorageToken.token ? localStorageToken.token : '';
  if(config && config.headers) {
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    //config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  }
  return config;
});

apiInstance.interceptors.response.use((response: any) => {
  // console.log(response);
  // const timestamp = Math.floor(Date.now() / 1000); 
  // const tokenStorage = UserService.getJwtFromLocalStorage();
  // const jwtExpire = tokenStorage.jwtExpire;
  // console.log(timestamp, jwtExpire)
  // if(timestamp > (jwtExpire - 100)) {
      
  //     alert('ola ola ola')
  //     return resetTokenAndReattemptRequest(response)
  // }


  return response;

}, async (error: any)=>{

  if(error && error.response && error.response.status === 403) {
    return history.push(routes.error403);
  }
  if(error && error.response && error.response.status === 401) {
    UserService.removeJwtFromLocalStorage();
    return StoreService.dispatch(logout())
  }
  return Promise.reject(error);
});

// const resetTokenAndReattemptRequest = async(response: any) => {
  
//   try {
//     // const { response: errorResponse } = error;
//     // const resetToken = UserService.getJwt();
//     // if (!resetToken) {
//     //    return Promise.reject(error);
//     // }
    
//     const retryOriginalRequest = new Promise(resolve => {
//     addSubscriber((token: string) => {
//         alert(token);
//         response.config.headers.Authorization = 'Bearer ' + token;
//         resolve(axios(response.config));
//       });
//     });

//     if (!isAlreadyFetchingAccessToken) {
//       isAlreadyFetchingAccessToken = true;
//       const response = await apiInstance.get('/refresh-token'); 
//       console.log(isAlreadyFetchingAccessToken);
//       if (!response.data) {
//         alert('logout');
//         StoreService.dispatch(logout())
//         //window.location.reload(false); 
//         UserService.removeJwtFromLocalStorage();
//         return window.location.reload(false); 
        
//         //return Promise.reject(error);
//       }
//       alert('logout');
//       const {token, expires_in} = response.data;
//       UserService.setJwtToLocalStorage(token, expires_in);
      
//       isAlreadyFetchingAccessToken = false;
//       onAccessTokenFetched(token);
//     }
    
//     return retryOriginalRequest;
//   } catch (err) {
//     //return StoreService.dispatch(logout())
//     window.location.reload(false); 
//     //return Promise.reject(err);
//   }
// }

// const  onAccessTokenFetched = (token:  string) => {
// 	// When the refresh is successful, we start retrying the requests one by one and empty the queue
//   alert("queue")
//   subscribers.forEach(callback => callback(token));
//   subscribers = [];
  
// }

// const addSubscriber = (callback: any) => {
//   subscribers.push(callback);
// }


// const resetTokenAndReattemptRequest = async(response: any) => {
  
//   try {
//     const { response: errorResponse } = error;
//     const resetToken = UserService.getJwt();
//     if (!resetToken) {
//        return Promise.reject(error);
//     }
    
//     const retryOriginalRequest = new Promise(resolve => {
//     addSubscriber((access_token: string) => {
//         errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
//         resolve(axios(errorResponse.config));
//       });
//     });

//     if (!isAlreadyFetchingAccessToken) {
//       isAlreadyFetchingAccessToken = true;
//       const response = await apiInstance.get('/refresh-token'); 
//       if (!response.data) {
//         alert('logout');
//         StoreService.dispatch(logout())
//         //window.location.reload(false); 
//         UserService.removeJwtFromLocalStorage();
//         return window.location.reload(false); 
        
//         //return Promise.reject(error);
//       }
//       alert('logout');
//       const {token, expires_in} = response.data;
//       UserService.setJwtToLocalStorage(token, expires_in);
      
//       isAlreadyFetchingAccessToken = false;
//       onAccessTokenFetched(token);
//     }
    
//     return retryOriginalRequest;
//   } catch (err) {
//     //return StoreService.dispatch(logout())
//     window.location.reload(false); 
//     //return Promise.reject(err);
//   }
// }




export default apiInstance;
