const scope = 'loop/App';

export const IS_AUTHENTICATED = `${scope}/IS_AUTHENTICATED`;
export const REFRESH_TOKEN = `${scope}/REFRESH_TOKEN`;
export const REFRESH_TOKEN_SUCCESS = `${scope}/REFRESH_TOKEN_SUCCESS`;
export const REFRESH_TOKEN_ERROR = `${scope}/REFRESH_TOKEN_ERROR`;
export const RESET_APP = `${scope}/RESET_APP`;

export const GET_USER_INFO = `${scope}/GET_USER_INFO`;
export const GET_USER_INFO_SUCCESS = `${scope}/GET_USER_INFO_SUCCESS`;
export const GET_USER_INFO_ERROR = `${scope}/GET_USER_INFO_ERROR`;

export const SUBMIT_CONTACT_FORM = `${scope}/SUBMIT_CONTACT_FORM`;
export const SUBMIT_CONTACT_FORM_SUCCESS = `${scope}/SUBMIT_CONTACT_FORM_SUCCESS`;
export const SUBMIT_CONTACT_FORM_ERROR = `${scope}/SUBMIT_CONTACT_FORM_ERROR`;

export const FEATURED_JOBS_REQUEST = `${scope}/FEATURED_JOBS_REQUEST`;
export const FEATURED_JOBS_SUCCESS = `${scope}/FEATURED_JOBS_SUCCESS`;
export const FEATURED_JOBS_ERROR = `${scope}/FEATURED_JOBS_ERROR`;

//SOCKETS
export const SOCKET_REQUEST = `${scope}/SOCKET_REQUEST`;
export const SOCKET_SUCCESS = `${scope}/SOCKET_SUCCESS`;
export const SOCKET_ERROR = `${scope}/SOCKET_ERROR`;

export const COUNT_UNREAD_MESSAGES = `${scope}/COUNT_UNREAD_MESSAGES`;
export const COUNT_UNREAD_MESSAGES_SUCCESS = `${scope}/COUNT_UNREAD_MESSAGES_SUCCESS`;
export const COUNT_UNREAD_MESSAGES_ERROR = `${scope}/COUNT_UNREAD_MESSAGES_ERROR`;

export const NOTIFICATION_NUMBER = `${scope}/NOTIFICATION_NUMBER`;
export const NOTIFICATION_NUMBER_SUCCESS = `${scope}/NOTIFICATION_NUMBER_SUCCESS`;
export const NOTIFICATION_NUMBER_ERROR = `${scope}/NOTIFICATION_NUMBER_ERROR`;

// Export  Upcoming Events
export const UPCOMING_EVENTS = `${scope}/UPCOMING_EVENTS_REQUEST`;
export const UPCOMING_EVENTS_SUCCESS = `${scope}/UPCOMING_EVENTS_SUCCESS`;
export const UPCOMING_EVENTS_ERROR = `${scope}/UPCOMING_EVENTS_ERROR`;

// Export  Upcoming Events
export const RECENT_NEWS = `${scope}/RECENT_NEWS`;
export const RECENT_NEWS_SUCCESS = `${scope}/RECENT_NEWS_SUCCESS`;
export const RECENT_NEWS_ERROR = `${scope}/RECENT_NEWS_ERROR`;

// Delete
export const DELETE_ITEM = `${scope}/DELETE_ITEM`;
export const DELETE_ITEM_SUCCESS = `${scope}/DELETE_ITEM_SUCCESS`;
export const DELETE_ITEM_ERROR = `${scope}/DELETE_ITEM_ERROR`;

// Updata User Info Success
export const UPDATE_USER_INFO = `${scope}/UPDATE_USER_INFO`;
export const UPDATE_USER_CRITERIA = `${scope}/UPDATE_USER_CRITERIA`;
export const UPDATE_USER_COMPANY_VIDEO_LINK = `${scope}/UPDATE_USER_COMPANY_VIDEO_LINK`;


// Const For Packages
export const GET_PACKAGES = `${scope}/GET_PACKAGES`;
export const GET_PACKAGES_SUCCESS = `${scope}/GET_PACKAGES_SUCCESS`;
export const GET_PACKAGES_ERROR = `${scope}/GET_PACKAGES_ERROR`;


export const GET_PACKAGES_PUBLIC = `${scope}/GET_PACKAGES_PUBLIC`;
export const GET_PACKAGES_PUBLIC_SUCCESS = `${scope}/GET_PACKAGES_PUBLIC_SUCCESS`;
export const GET_PACKAGES_PUBLIC_ERROR = `${scope}/GET_PACKAGES_PUBLIC_ERROR`;

// Per Page
export const FEATURED_JOBS_PER_PAGE = window.innerWidth > 1200 ? 4 : 3 && window.innerWidth > 992 ? 3 : 2 && window.innerWidth > 576 ? 2 : 1;
export const EVENT_ITEMS_PER_PAGE = window.innerWidth > 1200 ? 4 : 3 && window.innerWidth > 992 ? 3 : 2 && window.innerWidth > 576 ? 2 : 1;
export const RECENT_NEWS_PER_PAGE = window.innerWidth > 1200 ? 4 : 3 && window.innerWidth > 992 ? 3 : 2 && window.innerWidth > 576 ? 2 : 1;
export const CLIENTS_PER_PAGE = window.innerWidth > 1200 ? 5 : 4 && window.innerWidth > 992 ? 4 : 3;

// Select Categories
export const SELECT_CATEGORY = `${scope}/SELECT_CATEGORY`;
export const SELECT_CATEGORY_SUCCESS = `${scope}/SELECT_CATEGORY_SUCCESS`;
export const SELECT_CATEGORY_ERROR = `${scope}/SELECT_CATEGORY_ERROR`;

// Select Categories Filter
export const SELECT_CATEGORIES = `${scope}/SELECT_CATEGORIES`;
export const SELECT_CATEGORIES_SUCCESS = `${scope}/SELECT_CATEGORIES_SUCCESS`;
export const SELECT_CATEGORIES_ERROR = `${scope}/SELECT_CATEGORIES_ERROR`;

// Select Special Categories Filter
export const SELECT_SPECIAL_CATEGORIES = `${scope}/SELECT_SPECIAL_CATEGORIES`;
export const SELECT_SPECIAL_CATEGORIES_SUCCESS = `${scope}/SELECT_SPECIAL_CATEGORIES_SUCCESS`;
export const SELECT_SPECIAL_CATEGORIES_ERROR = `${scope}/SELECT_SPECIAL_CATEGORIES_ERROR`;

export const GET_JOB_PACKAGES = `${scope}/GET_JOB_PACKAGES`;
export const GET_JOB_PACKAGES_SUCCESS = `${scope}/GET_JOB_PACKAGES_SUCCESS`;
export const GET_JOB_PACKAGES_ERROR = `${scope}/GET_JOB_PACKAGES_ERROR`;

export const CLOUD_NOTE = `${scope}/CLOUD_NOTE`;
export const CLOUD_NOTE_CLOSE = `${scope}/CLOUD_NOTE_CLOSE`;

export const OPEN_ALL_THREADS_SIDEBAR = `${scope}/OPEN_ALL_THREADS_SIDEBAR`;

export const SET_NEWSLETTER = `${scope}/SET_NEWSLETTER`;

// Get Our Clients
export const GET_HOME_OUR_CLIENTS = `${scope}/GET_HOME_OUR_CLIENTS`;
export const GET_HOME_OUR_CLIENTS_SUCCESS = `${scope}/GET_HOME_OUR_CLIENTS_SUCCESS`;
export const GET_HOME_OUR_CLIENTS_ERROR = `${scope}/GET_HOME_OUR_CLIENTS_SUCCESS`;


export const GET_CITIES = `${scope}/GET_CITIES`;
export const GET_CITIES_SUCCESS = `${scope}/GET_CITIES_SUCCESS`;
export const GET_CITIES_ERROR = `${scope}/GET_CITIES_ERROR`;
