import React, {FC, useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import colors from '../../config/colors';

import {Link, useLocation} from "react-router-dom";
import routes from '../../config/routes';

const Header: FC = (props) => {

  const location = useLocation();

  const [isFixed, setFixed] = useState(false);

  const isHomepage = location.pathname === '/' ? true : false

  const ref = useRef<any>(null);

  const [width, setWidth]   = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setFixed(window.scrollY > 94);
    });
  }, []);

  return (
      <HeaderWrapper className={`${width <= 1100 ? "mobile-header" : ""} ${isHomepage ? '' : 'purple-header'} ${isFixed ? 'headerFixed' : ''}`} ref={ref}>
        <HeaderInner>
          <Logo aria-label="Logo" title='Logo' to={routes.HomePage}>
            <svg><use xlinkHref={width <= 1100 ? `#logo` : isFixed ? `#logo` : `#logo-white`} /></svg>
          </Logo>
          <HeaderNavBar>
            {props.children}
          </HeaderNavBar>
        </HeaderInner>

      </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  margin: 0 auto;
  height: 94px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  a {
    color: ${colors.WHITE};
    font-size: 16px;
    font-family: "Gilroy-Medium";
  }

  &.purple-header {
    background: ${colors.PRIMARY_PURPLE};
  }

  &.mobile-header {
    background: ${colors.WHITE};
  }

  &.headerFixed {

    @media (min-width: 1100px) {
      z-index: 1000;
      background: ${colors.WHITE};
      transition: all 300ms ease-in-out, right 0ms;
      box-shadow: 0 8px 12px -8px rgb(0 0 0 / 10%);

      a {
        color: ${colors.BLACK};
      }
      .dropdown-arrow {
        fill: #FFA73A;

        &:hover {
          fill: ${colors.PRIMARY_ORANGE};
        }
      }
    }
  }
`;

const HeaderInner = styled.div`
  max-width: 1600px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
`;

const Logo = styled(Link)`
  width: 76px;
  height: 40px;
  flex-shrink: 0;
  position: relative;
  transition: all 400ms ease-in-out;

  @media (max-width: 767px) {
    width: 70px;
    height: 37px;
  }

  &.headerFixed {

    @media (max-width: 767px) {
      width: 60px;
      height: 32px;
    }

    svg {
      @media (max-width: 767px) {
        width: 60px;
        height: 32px;
      }
    }
  }

  svg {
    width: 76px;
    height: 40px;
    max-width: 100%;
    transition: all 400ms ease-in-out;

    @media (max-width: 767px) {
      width: 70px;
      height: 37px;
    }
  }
`;

const HeaderNavBar = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;

  a {

    span {
      display: inline-block;
      vertical-align: middle;
      margin: -3px 0 0 3px;

      svg {
        display: block;
        width: 11px;
        height: 7px;
        transition: fill 100ms ease-in-out;
        fill: ${colors.WHITE_COLOR};
      }
    }

    &:hover {
      color: ${colors.PRIMARY_ORANGE};

      span svg {
        fill: ${colors.PRIMARY_ORANGE};
      }
    }
  }
`;

export default Header;
