import styled from 'styled-components';
import colors from '../../../config/colors';

const NotificationNumber = styled.small`
  position: absolute;
  bottom: -5px;
  right: -8px;
  z-index: 1;
  font-size: 8px;
  font-family: 'Gilroy-Bold';
  font-weight: 700;
  font-style: normal;
  color: ${colors.WHITE};
  width: 14px;
  height: 14px;
  padding: 3px 0;
  text-align: center;
  border-radius: 50%;
  transition: background 100ms ease-in-out;

  &.hasNotification {
    background: ${colors.PRIMARY_RED};
  }

  &.hasMessage {
    background: ${colors.PRIMARY_ORANGE};
    color: ${colors.PRIMARY_PURPLE};
  }
`;

export default NotificationNumber;
