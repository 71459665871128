import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import CaslProvider from './containers/Casl';
import App from './containers/App';
import history from './utils/history';
// import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import { HelmetProvider } from 'react-helmet-async';
import appSaga from './containers/App/saga';
import TagManager from 'react-gtm-module';
import gtm from './config/gtm';
import { StyleSheetManager } from 'styled-components';
import jobsSaga from './containers/Jobs/saga';
import { DEBUG } from '../src/config/config';

if (DEBUG === 'false') {
  // console.log = console.error = console.info = console.debug = console.warn = console.trace = console.dir = console.dirxml = console.group = console.groupEnd = console.time =    console.timeEnd = console.assert = console.profile = function() {}
  const tagManagerArgs = {
    gtmId: gtm.id,
  };

  TagManager.initialize(tagManagerArgs);
}

const initialState = {};
const store = configureStore(initialState, history);
store.runSaga(appSaga);
store.runSaga(jobsSaga);

const MOUNT_NODE = document.getElementById('root');
render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider locale="en">
        <ConnectedRouter history={history}>
          <CaslProvider>
            <HelmetProvider>
              <StyleSheetManager disableCSSOMInjection>
                <App />
              </StyleSheetManager>
            </HelmetProvider>
          </CaslProvider>
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
  MOUNT_NODE
);

// serviceWorker.register();
