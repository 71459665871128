const scope = 'loop/Conversations';

export const MESSAGES_REQUEST = `${scope}/MESSAGES_REQUEST`;
export const MESSAGES_SUCCESS = `${scope}/MESSAGES_SUCCESS`;
export const MESSAGES_ERROR = `${scope}/MESSAGES_ERROR`;

export const SEND_MESSAGE_REQUEST = `${scope}/SEND_MESSAGE_REQUEST`;
export const SEND_MESSAGE_SUCCESS = `${scope}/SEND_MESSAGE_SUCCESS`;
export const SEND_MESSAGE_ERROR = `${scope}/SEND_MESSAGE_ERROR`;

export const DELETE_ITEM = `${scope}/DELETE_ITEM`;
export const DELETE_ITEM_SUCCESS = `${scope}/DELETE_ITEM_SUCCESS`;
export const DELETE_ITEM_ERROR = `${scope}/DELETE_ITEM_ERROR`;

export const GET_JOBS = `${scope}/GET_JOBS`;
export const GET_JOBS_SUCCESS = `${scope}/GET_JOBS_SUCCESS`;
export const GET_JOBS_ERROR = `${scope}/GET_JOBS_ERROR`;

export const REQUEST_CONVERSATION = `${scope}/REQUEST_CONVERSATION`;
export const REQUEST_CONVERSATION_SUCCESS = `${scope}/REQUEST_CONVERSATION_SUCCESS`;
export const REQUEST_CONVERSATION_ERROR = `${scope}/REQUEST_CONVERSATION_ERROR`;

export const REQUEST_CONVERSATION_CLEAR = `${scope}/REQUEST_CONVERSATION_CLEAR`;

export const REQUEST_CONVERSATION_BY_JOB_ID = `${scope}/REQUEST_CONVERSATION_BY_JOB_ID`;
export const REQUEST_CONVERSATION_BY_JOB_ID_SUCCESS = `${scope}/REQUEST_CONVERSATION_BY_JOB_ID_SUCCESS`;
export const REQUEST_CONVERSATION_BY_JOB_ID_ERROR = `${scope}/REQUEST_CONVERSATION_BY_JOB_ID_ERROR`;

export const REQUEST_SEARCH_USERS = `${scope}/REQUEST_SEARCH_USERS`;
export const REQUEST_SEARCH_USERS_SUCCESS = `${scope}/REQUEST_SEARCH_USERS_SUCCESS`;
export const REQUEST_SEARCH_USERS_ERROR = `${scope}/REQUEST_SEARCH_USERS_ERROR`;

export const DISPLAY_MODAL = `${scope}/DISPLAY_MODAL`;

export const RESET_FORM = `${scope}/RESET_FORM`;

export const MESSAGES_PER_PAGE = 10;

export const CONVERSATION_TYPES = {
   job: 'job',
   directMessage: 'direct_message'
}

export const SET_MESSAGES_READ = `${scope}/SET_MESSAGES_READ`;
export const SET_MESSAGES_READ_SUCCESS = `${scope}/SET_MESSAGES_READ_SUCCESS`;
export const SET_MESSAGES_READ_ERROR = `${scope}/SET_MESSAGES_READ_ERROR`;

export const OPEN_CONVERSATION_WINDOW = `${scope}/OPEN_CONVERSATION_WINDOW`;


export const PRESIGNED_REQUEST_CHAT = `${scope}/PRESIGNED_REQUEST_CHAT`;
export const PRESIGNED_REQUEST_CHAT_SUCCESS = `${scope}/PRESIGNED_REQUEST_CHAT_SUCCESS`;
export const PRESIGNED_REQUEST_CHAT_ERROR = `${scope}/PRESIGNED_REQUEST_CHAT_ERROR`;


export const CHAT_UPLOAD_START = `${scope}/CHAT_UPLOAD_START`;
export const CHAT_UPLOAD_SUCCESS = `${scope}/CHAT_UPLOAD_SUCCESS`;
export const CHAT_UPLOAD_PROGRESS = `${scope}/CHAT_UPLOAD_PROGRESS`;
export const CHAT_UPLOAD_START_FAILED = `${scope}/CHAT_UPLOAD_START_FAILED`;

export const REQUEST_CONVERSATION_NEW = `${scope}/REQUEST_CONVERSATION_NEW`;

export const CANCEL_UPLOAD = `${scope}/CANCEL_UPLOAD`;