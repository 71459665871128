import produce from 'immer';
import { REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_ERROR, IS_AUTHENTICATED,GET_USER_INFO_SUCCESS,  FEATURED_JOBS_SUCCESS,
  FEATURED_JOBS_REQUEST,
  FEATURED_JOBS_ERROR,
  RESET_APP,
  COUNT_UNREAD_MESSAGES,
  COUNT_UNREAD_MESSAGES_SUCCESS,
  COUNT_UNREAD_MESSAGES_ERROR,
  UPCOMING_EVENTS_SUCCESS,
  RECENT_NEWS,
  RECENT_NEWS_SUCCESS,
  RECENT_NEWS_ERROR,
  UPCOMING_EVENTS,
  UPCOMING_EVENTS_ERROR,
  UPDATE_USER_INFO,
  UPDATE_USER_COMPANY_VIDEO_LINK,
  GET_PACKAGES,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_ERROR,
  GET_PACKAGES_PUBLIC,
  GET_PACKAGES_PUBLIC_SUCCESS,
  GET_PACKAGES_PUBLIC_ERROR,
  SELECT_CATEGORY_SUCCESS,
  SELECT_CATEGORIES_SUCCESS,
  GET_JOB_PACKAGES_ERROR,
  GET_JOB_PACKAGES_SUCCESS,
  GET_JOB_PACKAGES,
  CLOUD_NOTE,
  CLOUD_NOTE_CLOSE,
  NOTIFICATION_NUMBER_SUCCESS,
  NOTIFICATION_NUMBER_ERROR,
  NOTIFICATION_NUMBER,
  OPEN_ALL_THREADS_SIDEBAR,
  SUBMIT_CONTACT_FORM,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_ERROR,
  GET_USER_INFO,
  SET_NEWSLETTER,
  SELECT_SPECIAL_CATEGORIES_SUCCESS,
  GET_HOME_OUR_CLIENTS_SUCCESS,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,
  UPDATE_USER_CRITERIA,
  GET_HOME_OUR_CLIENTS
} from './constants';
import { Job } from '../../models/jobs';
import { CompanyInfo } from '../../models/companyInfo';


export interface appState {
  loading: boolean;
  loadingPackages: boolean;
  refreshToken : boolean;
  error: any;
  userInfo: CompanyInfo;
  isAuthenticated: boolean | undefined,
  token : string;
  featuredJobs: Array<Array<Job>>;
  upcominngEvents: [];
  upcominngEventsLoading: boolean;
  recentNews: [];
  recentNewsLoading: boolean;
  featuredJobsLoading: boolean;
  countUnreadMessages: number;
  unreadNoifications: Array<Object>;
  notificationsNumber: number;
  page: number;
  currentPage: number;
  pageCount: number;
  featuredCurrentPage: number;
  featuredPageCount: number;
  recentNewsCurrentPage: number;
  recentNewsPageCount: number;
  eventsCurrentPage: number;
  eventsPageCount: number;
  clientsCurrentPage: number;
  clientsPageCount: number;
  packages: any;
  packagesPublic: any;
  packagesJob: any;
  selectCategories: Array<Object>;
  selectCategoriesFilter: {
    industry: Array<Object>,
    areaOfWork: Array<Object>
  };
  selectSpecialCategoriesFilter: Array<Object>;
  cloudNoteModalStatus: boolean;
  cloudNoteTitle: string;
  cloudNoteDescription: any;
  openThreadsSidebar: boolean;
  loaderContactUs: boolean;
  loaderMostReadNews: boolean;
  loadingUserInfo: boolean;
  loaderOurClients: boolean;
  ourClientsHome: Array<Object>;
  optionsCities : Array<object>;
}

export const initialState: appState = {
  loading: false,
  loadingPackages: false,
  refreshToken: true,
  error: null,
  userInfo: {
    avatar: '',
    company_name: '',
    company_position: '',
    email: '',
    first_name: '',
    id: 0,
    last_name: '',
    newsletter: false,
    receive_email_for_message: false,
    mailchimp_subscribed: false,
    disabled_mailchimp: false,
    phone: '',
    role: '',
    unique_id: '',
    username: '',
    city: '',
    country: '',
    linkedin: '',
    occupation: '',
    job_categories: [],
    company_video_url: '',
    criteria: 0,
    phone_verified: 0,
    img_url: '',
    type: '',
    allow_contact: true,
    usernames: []
  },
  isAuthenticated: false,
  token: '',
  featuredJobs: [],
  featuredJobsLoading: false,
  upcominngEvents: [],
  upcominngEventsLoading: false,
  recentNews: [],
  recentNewsLoading: false,
  countUnreadMessages: 0,
  unreadNoifications: [],
  notificationsNumber: 0,
  page: 1,
  currentPage: 1,
  pageCount: 1,
  featuredCurrentPage: 1,
  featuredPageCount: 1,
  recentNewsCurrentPage: 1,
  recentNewsPageCount: 1,
  eventsCurrentPage: 1,
  eventsPageCount: 1,
  clientsCurrentPage: 1,
  clientsPageCount: 1,
  packages: [],
  packagesPublic: [],
  packagesJob: [],
  selectCategories: [],
  selectCategoriesFilter: {
    industry: [],
    areaOfWork: []
  },
  selectSpecialCategoriesFilter: [],
  cloudNoteModalStatus: false,
  cloudNoteTitle: '',
  cloudNoteDescription: null,
  openThreadsSidebar: false,
  loaderContactUs: false,
  loaderMostReadNews: false,
  loadingUserInfo: true,
  loaderOurClients: false,
  ourClientsHome: [],
  optionsCities: [],
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action: any) =>
  produce(state, draft => {
    switch (action.type) {

      case GET_CITIES:
        //draft.loading.cities = true;
        break;
      case GET_CITIES_SUCCESS:
        // draft.loading.cities = false;
        draft.optionsCities = action.response.data;
        break;
      case GET_CITIES_ERROR:
        //draft.loading.cities = false;
        draft.error = action.error;
        break;


      case IS_AUTHENTICATED:
        draft.isAuthenticated = action.payload.isAuthenticated;
        draft.token = action.payload.token;
        draft.refreshToken = false;
        break;
      case GET_USER_INFO:
        draft.loadingUserInfo = true;
        break;
      case GET_USER_INFO_SUCCESS:
        draft.loadingUserInfo = false;
        draft.userInfo.avatar = action.response?.data.avatar;
        draft.userInfo.company_name = action.response?.data.company_name;
        draft.userInfo.company_position = action.response?.data.company_position;
        draft.userInfo.email = action.response?.data.email;
        draft.userInfo.first_name = action.response?.data.first_name;
        draft.userInfo.id = action.response?.data.id;
        draft.userInfo.last_name = action.response?.data.last_name;
        draft.userInfo.newsletter = action.response?.data.newsletter;
        draft.userInfo.receive_email_for_message = action.response?.data.receive_email_for_message;
        draft.userInfo.mailchimp_subscribed = action.response?.data.mailchimp_subscribed;
        draft.userInfo.disabled_mailchimp = action.response?.data.disabled_mailchimp;
        draft.userInfo.phone = action.response?.data.phone;
        draft.userInfo.role = action.response?.data.role;
        draft.userInfo.unique_id = action.response?.data.unique_id;
        draft.userInfo.username = action.response?.data.username;
        draft.userInfo.city = action.response?.data.city;
        draft.userInfo.country = action.response?.data.country;
        draft.userInfo.linkedin = action.response?.data.linkedin;
        draft.userInfo.occupation = action.response?.data.occupation;
        draft.userInfo.job_categories = action.response?.data.job_categories;
        draft.userInfo.company_video_url = action.response?.data.company_video_url;
        draft.userInfo.criteria = action.response?.data.criteria;
        draft.userInfo.phone_verified = action.response?.data.phone_verified;
        draft.userInfo.img_url = action.response?.data.img_url;
        draft.userInfo.type = action.response?.data.type;
        draft.userInfo.allow_contact = action.response?.data.allow_contact;
        draft.userInfo.usernames = action.response?.data.usernames;
        break;
      case UPDATE_USER_INFO:
        draft.userInfo.img_url = action.img_url;
        break;
      case UPDATE_USER_CRITERIA:
        draft.userInfo.criteria = action.criteria;
        break;
      case UPDATE_USER_COMPANY_VIDEO_LINK:
        draft.userInfo.company_video_url = action.company_video_url;
        break;
      case REFRESH_TOKEN:
        draft.refreshToken = false;
        break;
      case REFRESH_TOKEN_SUCCESS:
        draft.token = action.token;
        draft.refreshToken = false;
        break;
      case REFRESH_TOKEN_ERROR:
        draft.refreshToken = false;

      break; case FEATURED_JOBS_REQUEST:
        draft.featuredJobsLoading = true;
        break;
      case FEATURED_JOBS_SUCCESS:
        draft.featuredJobsLoading = false;
        draft.featuredCurrentPage = action.response.data.meta.current_page;
        draft.featuredPageCount = action.response.data.meta.last_page;
        draft.featuredJobs = action.response.data.data;
        break;
      case FEATURED_JOBS_ERROR:
        draft.featuredJobsLoading = false;
        break;
      case COUNT_UNREAD_MESSAGES:
        break;
      case COUNT_UNREAD_MESSAGES_SUCCESS:
        draft.countUnreadMessages = action.countUnreadMessages.data;
        break;
      case COUNT_UNREAD_MESSAGES_ERROR:
        draft.error = action.error;
        break;
      case NOTIFICATION_NUMBER:
        break;
      case NOTIFICATION_NUMBER_SUCCESS:
        draft.notificationsNumber = action.response.data;
        break;
      case NOTIFICATION_NUMBER_ERROR:
        draft.error = action.error;
        break;
      case RESET_APP:
        draft.userInfo = {
          avatar: '',
          company_name: '',
          company_position: '',
          email: '',
          first_name: '',
          id: 0,
          last_name: '',
          newsletter: false,
          receive_email_for_message: false,
          mailchimp_subscribed: false,
          disabled_mailchimp: false,
          phone: '',
          role: null,
          unique_id: '',
          username: '',
          city: '',
          country: '',
          linkedin: '',
          occupation: '',
          job_categories: [],
          company_video_url: '',
          criteria: 0,
          phone_verified: 0,
          img_url: '',
          type: '',
          allow_contact: true,
          usernames: []
        };
        draft.countUnreadMessages = 0;
        break;
      case UPCOMING_EVENTS:
        //draft.upcominngEvents = [];
        draft.upcominngEventsLoading = true;
        break;
      case UPCOMING_EVENTS_SUCCESS:
        draft.upcominngEventsLoading = false;
        draft.eventsCurrentPage = action?.response?.data?.meta?.current_page || 1;
        draft.eventsPageCount = action?.response?.data?.meta?.last_page || 1;
        draft.upcominngEvents = action.response.data.data;
        break;
      case UPCOMING_EVENTS_ERROR:
        draft.upcominngEventsLoading = false;
        break;
      case RECENT_NEWS:
        draft.recentNews = [];
        draft.recentNewsLoading = true;
        break;
      case RECENT_NEWS_SUCCESS:
        draft.recentNewsLoading = false;
        draft.recentNewsCurrentPage = action?.response?.data?.meta?.current_page || 1;
        draft.recentNewsPageCount = action?.response?.data?.meta?.last_page || 1;
        draft.recentNews = action.response.data.data;
        break;
      case RECENT_NEWS_ERROR:
        draft.recentNewsLoading = false;
        break;
      case GET_PACKAGES:
        draft.loadingPackages = true;
        break;
      case GET_PACKAGES_SUCCESS:
        draft.loadingPackages = false;
        draft.packages = action.response.data
        break;
      case GET_PACKAGES_ERROR:
        draft.loadingPackages = false;
        break;
      case GET_PACKAGES_PUBLIC:
        draft.loadingPackages = true;
        break;
      case GET_PACKAGES_PUBLIC_SUCCESS:
        draft.loadingPackages = false;
        draft.packagesPublic = action.response.data
        break;
      case GET_PACKAGES_PUBLIC_ERROR:
        draft.loadingPackages = false;
        break;
      case GET_JOB_PACKAGES:
        draft.loadingPackages = true;
        break;
      case GET_JOB_PACKAGES_SUCCESS:
        draft.loadingPackages = false;
        draft.packagesJob = action.response.data
        break;
      case GET_JOB_PACKAGES_ERROR:
        draft.loadingPackages = false;
        break;
      case SELECT_CATEGORY_SUCCESS:
        draft.selectCategories = action.response.data
        break;
      case SELECT_CATEGORIES_SUCCESS:
        draft.selectCategoriesFilter.areaOfWork = action.response.data.area_of_work;
        draft.selectCategoriesFilter.industry = action.response.data.industry;
        break;
      case SELECT_SPECIAL_CATEGORIES_SUCCESS:
        draft.selectSpecialCategoriesFilter = action.response.data;
        break;
      case CLOUD_NOTE:
        draft.cloudNoteModalStatus = action.cloudNoteModalStatus;
        draft.cloudNoteTitle = action.cloudNoteTitle;
        draft.cloudNoteDescription = action.cloudNoteDescription;
        break;
      case CLOUD_NOTE_CLOSE:
        draft.cloudNoteModalStatus = action.cloudNoteModalStatus;
        break;
      case OPEN_ALL_THREADS_SIDEBAR:
        draft.openThreadsSidebar = action.open;
        break;
      case SUBMIT_CONTACT_FORM_SUCCESS:
        draft.loaderContactUs = false;
        break;
      case SUBMIT_CONTACT_FORM:
        draft.loaderContactUs = true;
        break;
      case SUBMIT_CONTACT_FORM_ERROR:
        draft.loaderContactUs = false;
        break;
      case SET_NEWSLETTER:
        draft.userInfo.newsletter = action.value;
        break;
      case GET_HOME_OUR_CLIENTS: 
        draft.ourClientsHome = [];
        draft.loaderOurClients = true;
        break;
      case GET_HOME_OUR_CLIENTS_SUCCESS:
        draft.loaderOurClients = false;
        draft.clientsCurrentPage = action?.response?.data?.meta?.current_page || 1;
        draft.clientsPageCount = action?.response?.data?.meta?.last_page || 1;
        draft.ourClientsHome = action.response.data.data;
        break;
    }
  });

export default appReducer;
