const scope = 'loop/Login';

export const LOGIN_REQUEST = `${scope}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${scope}/LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${scope}/LOGIN_ERROR`;

export const LOGIN_RESET = `${scope}/LOGIN_RESET`;

export const CHANGE_PASSWORD = `${scope}/CHANGE_PASSWORD`;
export const CHANGE_USERNAME = `${scope}/CHANGE_USERNAME`;

export const LOGOUT_REQUEST = `${scope}/LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${scope}/LOGOUT_SUCCESS`;
export const LOGOUT_ERROR = `${scope}/LOGOUT_ERROR`;

export const RESEND_VERIFICATION_EMAIL_REQUEST = `${scope}/RESEND_VERIFICATION_EMAIL_REQUEST`;
export const RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS = `${scope}/RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS`;
export const RESEND_VERIFICATION_EMAIL_REQUEST_ERROR = `${scope}/RESEND_VERIFICATION_EMAIL_REQUEST_ERROR`;

export const RESEND_VERIFICATION_CODE_REQUEST = `${scope}/RESEND_VERIFICATION_CODE_REQUEST`;
export const RESEND_VERIFICATION_CODE_REQUEST_SUCCESS = `${scope}/RESEND_VERIFICATION_CODE_REQUEST_SUCCESS`;
export const RESEND_VERIFICATION_CODE_REQUEST_ERROR = `${scope}/RESEND_VERIFICATION_ECODEREQUEST_ERROR`;

export const LOGIN_AFTER_VALIDATION_REQUEST = `${scope}/LOGIN_AFTER_VALIDATION_REQUEST`;


export const LOGIN_VERIFY_PHONE = `${scope}/LOGIN_VERIFY_PHONE`;
export const LOGIN_VERIFY_PHONE_SUCCESS = `${scope}/LOGIN_VERIFY_PHONE_SUCCESS`;
export const LOGIN_VERIFY_PHONE_ERROR = `${scope}/LOGIN_VERIFY_PHONE_ERROR`;