import {fork, take, call, put, all, takeEvery,select,cancel,takeLatest, ForkEffect, TakeEffect, SelectEffect, CallEffect, CancelEffect, PutEffect} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga'

import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_ERROR,
  IS_AUTHENTICATED,
  SUBMIT_CONTACT_FORM,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_ERROR,
  FEATURED_JOBS_REQUEST,
  FEATURED_JOBS_SUCCESS,
  FEATURED_JOBS_ERROR,
  COUNT_UNREAD_MESSAGES,
  COUNT_UNREAD_MESSAGES_SUCCESS,
  COUNT_UNREAD_MESSAGES_ERROR,
  UPCOMING_EVENTS,
  UPCOMING_EVENTS_SUCCESS,
  UPCOMING_EVENTS_ERROR,
  RECENT_NEWS,
  RECENT_NEWS_SUCCESS,
  RECENT_NEWS_ERROR,
  RESET_APP,
  DELETE_ITEM,
  DELETE_ITEM_ERROR,
  DELETE_ITEM_SUCCESS,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_ERROR,
  GET_PACKAGES,
  GET_PACKAGES_PUBLIC_SUCCESS,
  GET_PACKAGES_PUBLIC_ERROR,
  GET_PACKAGES_PUBLIC,
  //FEATURED_JOBS_PER_PAGE,
  EVENT_ITEMS_PER_PAGE,
  SELECT_CATEGORY_ERROR,
  SELECT_CATEGORY,
  SELECT_CATEGORY_SUCCESS,
  SELECT_CATEGORIES_SUCCESS,
  SELECT_CATEGORIES_ERROR,
  SELECT_CATEGORIES,
  GET_JOB_PACKAGES,
  GET_JOB_PACKAGES_SUCCESS,
  GET_JOB_PACKAGES_ERROR,
  NOTIFICATION_NUMBER_SUCCESS,
  NOTIFICATION_NUMBER_ERROR,
  NOTIFICATION_NUMBER,
  GET_USER_INFO,
  SELECT_SPECIAL_CATEGORIES,
  SELECT_SPECIAL_CATEGORIES_SUCCESS,
  SELECT_SPECIAL_CATEGORIES_ERROR,
  FEATURED_JOBS_PER_PAGE,
  GET_HOME_OUR_CLIENTS_SUCCESS,
  GET_HOME_OUR_CLIENTS,
  GET_HOME_OUR_CLIENTS_ERROR,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,
  RECENT_NEWS_PER_PAGE,
  CLIENTS_PER_PAGE
} from './constants';

import { GET_ALL } from '../RequestedServices/constants';
import { GET_LATEST_SERVICES } from '../ActiveServices/constants'

import * as actions  from '../Upload/actions'

import {LOGOUT_REQUEST, LOGOUT_SUCCESS} from '../Login/constants'
import {SOCKET_ENDPOINT} from '../../config/config';
import routes from '../../config/routes';
import {UserService}  from '../../services/UserService';

import Echo from "laravel-echo"
import io from 'socket.io-client';

import {submitContactFormService, getFeaturedJobs, getNumberOfNotificationsService,  getCountUnreadMessages, getUpcomingEvents, getCitiesService, getRecentNews, deleteItemService, getPackages, getPackagesPub, selectCategoriesService, selectCategoriesFilterService, getPackagesJobService, userPresenceService, selectSpecialCategoriesFilterService, getClientsHomeService} from './services';
import {makeSelectUserInfo, makeSelectisAuthenticated} from './selectors';
import {getUserProfile} from '../Login/saga';
import {push} from 'connected-react-router'

// Import Notifications and Store Service
import {setNotificationError, setNotificationSuccess} from '../Notifications/actions';
import StoreService from '../../utils/StoreService'
import intl from '../../intl';
import generalMessages from '../generalMessages';
import { MESSAGES_REQUEST } from '../Messages/constants';
import { getTasksAction } from '../Tasks/actions';
import { clearConversation, getConversation, setMessagesReadAction } from '../Messages/actions';
import { threadIdSelector } from '../Messages/selectors';
import TagManager from 'react-gtm-module';
import gtm from '../../config/gtm'
import get from 'lodash/get'
import { underounstructionAction } from '../Transactions/actions';


import * as loginActions from '../Login/actions'
import {getCoupons} from '../Coupons/actions'
import { AxiosResponse } from 'axios';
import { UserBase } from '../../models/user';


function* resetState() {
  yield put (loginActions.resetLogoutAction())

}

function* refreshTokenFlow(): any {

  try {
    const {token, jwtExpire} = UserService.getJwtFromLocalStorage();
    if(token && jwtExpire ) {
      const payload = {
        isAuthenticated : true,
        token: token,
      }
      yield put({type: GET_USER_INFO});
      yield call(getUserProfile);
      yield put({type: IS_AUTHENTICATED, payload});

      const action = yield take([LOGOUT_REQUEST]);

      if (action.type === LOGOUT_REQUEST) {

        yield put({type:LOGOUT_REQUEST});
      }
    } else {
      const payload = {
        isAuthenticated : false,
        token: '',
      }
      UserService.removeJwtFromLocalStorage();
      yield put({type: IS_AUTHENTICATED, payload});
    }
  } catch (error) {
    yield put({type: REFRESH_TOKEN_ERROR, error})
  }
}

function connect() {

  const response = UserService.getJwtFromLocalStorage();
  if(response) {
    const token = response.token;
    return new Echo({
      broadcaster: 'socket.io',
      host: SOCKET_ENDPOINT,
      client: io,
      // transports: ['websocket'],
      auth: {
          headers: {
            Authorization : 'Bearer ' + token
          },
      },
    });
  }
}

function* subscribe(echoClient: Echo) {

  const user: UserBase = yield select(makeSelectUserInfo);


  if(echoClient) {
    return eventChannel(emit => {

      echoClient.channel(`notification_${user.id}`)
      .listen('NewNotification',(e: any) => {
        emit({action: 'newnotification', obj:e});
      });

      echoClient.channel(`isread_${user.id}`)
      .listen('ReadMessage', (e: any) => {
        emit({action: 'readmessage', obj:e});
      });

      // echoClient.channel(`room_${user.id}`).listen('UserOnline', (e: any) => {

      //   console.log(e);
      //   // emit({action: 'useronline', obj: user})
      // });
      // echoClient.leave('room');

      // echoClient.join(`room`)
      // .here((users: any) => {
      //   console.log('svi aktivni useri', users);
      // })
      // .joining((user: any) => {
      //     console.log('usao user', user.username);
      //     emit({action: 'useronline', obj: user});
      // })
      // .leaving((user: any) => {
      //   console.log('napustio user', user.username);
      //   emit({action: 'useroffline', obj: user})
      // });

      echoClient.connector.socket.on('connect', function(){
          emit({action:'connected'});
          // console.log('Konektovan', user);
          // emit({action: 'useronline', obj: user})
      });

      echoClient.connector.socket.on('disconnect', function(){
          emit({action:'disconnected'});
          // emit({action: 'useroffline', obj: user})
      });

      echoClient.connector.socket.on('reconnecting', function(attemptNumber: number){

      });

      echoClient.connector.socket.on('pong', function(data: any) {
        //console.log('Received Pong: ', data);
      });

      return () => {};
    });
  }
}

function* getUnreadMessagesFlow(){
  try {
    const countUnreadMessages: AxiosResponse  = yield call(getCountUnreadMessages);
    yield put({type:COUNT_UNREAD_MESSAGES_SUCCESS, countUnreadMessages})
  } catch(error) {
    yield put({type:COUNT_UNREAD_MESSAGES_ERROR, error})
  }
}

function* getNumberOfNotification() {
  try{
    const response: AxiosResponse  = yield call(getNumberOfNotificationsService);
    yield put({type: NOTIFICATION_NUMBER_SUCCESS, response});
  } catch(error) {
    yield put({type: NOTIFICATION_NUMBER_ERROR, error});
  }

}

function* socketConnected() {
  yield put({type: COUNT_UNREAD_MESSAGES})
  yield put({type: NOTIFICATION_NUMBER})
}

function socketDisconnected() {
  //StoreService.dispatch(setNotificationError('Server connection is lost. Please check your internet connection'));
}

function* setUserPresence(user: any, status: string) {

  const {id} = user;

  try {
    // yield call(userPresenceService, user);
    yield call(userPresenceService, id, status);
  } catch(error) {
    console.log(error);
  }
}

// Here notification and set it
function* socketNewNotification(data: any) {
  const {body, entity, type} = data.notification;
  const userInfo: UserBase = yield select(makeSelectUserInfo);

  yield put({type: NOTIFICATION_NUMBER});

  switch(type) {
    case 'message' :
      yield messageNotificationFlow(entity, userInfo);
      StoreService.dispatch(setNotificationSuccess(body));
    break;
    case 'task' :
      yield taskNotificationFlow();
      StoreService.dispatch(setNotificationSuccess(body));
    break;
    case 'queue_coupons' :
      yield taskQueueCouponsFlow();
      StoreService.dispatch(setNotificationSuccess(body));
      break;
    case 'notify' :
      yield taskNotificationFlow();
      StoreService.dispatch(setNotificationSuccess(body));
      break;
    case 'status_info' :
      yield taskNotificationFlow();
      StoreService.dispatch(setNotificationSuccess(intl.formatMessage(generalMessages.YourStatusChanged)));
      break;
  }
}

function taskQueueCouponsFlow() {
  StoreService.dispatch(getCoupons(1, ''));
}

function* socketSetMessagesRead(data: any) {

  const {threadId, recipientId} = data;
  const currentThreadId: string = yield select(threadIdSelector);

  if(currentThreadId === threadId) {
      const obj: any = {
        threadId: threadId,
        recipientId: recipientId
      }

      StoreService.dispatch(setMessagesReadAction(obj));
  }
}

// Function for notification Messages
function* messageNotificationFlow(entity: any, userInfo: any) {

  const {thread_id, recipient_id, sender_id, entity_id, entity_type} = entity;

  const currentThreadId: string = yield select(threadIdSelector);

  yield put({type: COUNT_UNREAD_MESSAGES});
  yield put({type: MESSAGES_REQUEST});

  if(currentThreadId === thread_id) {
    const obj = {
      id: thread_id,
      recipientIds: userInfo.id !== recipient_id ? [recipient_id] : [sender_id],
      entityType: entity_type,
      senderId: sender_id,
      entityId: entity_id,
      threadId: thread_id
    }

    StoreService.dispatch(getConversation(obj, true));
    StoreService.dispatch(clearConversation());
  }

}

// Still need work
function taskNotificationFlow() {
  let page = 1;
  StoreService.dispatch(getTasksAction(page, ''));
}

function* echoLogout(echoClient: Echo){
  yield take (LOGOUT_REQUEST);
  if(echoClient) {
    yield echoClient.disconnect();
  }

}

function* read(echoClient: Echo) : Generator<TakeEffect |  CallEffect , void, any>  {
  const channel : any = yield call(subscribe, echoClient);
  if(channel) {
  try {
    while (true) {
      let {action, obj} = yield take(channel);

      switch (action) {
        case 'connected':
          yield call(socketConnected);
          break;
        case 'disconnected':
          yield call(socketDisconnected);
          break;
        case 'newnotification':
          yield call(socketNewNotification, obj);
          break;
        case 'useronline':
          yield call(setUserPresence, obj, 'online');
          break;
        case 'useroffline':
          yield call(setUserPresence, obj, 'offline');
          break;
        case 'readmessage':
          yield call(socketSetMessagesRead, obj);
          break;
        case 'transactionopened':
          yield call(socketTransacationOpened, obj);
          break;
      }
    }
   } finally {
     yield channel.close();
   }
  }
    //yield put(action);
}

function socketTransacationOpened(data: any)
{
  const {transaction_unique_id, user_id} = data;

  const arr = [];

  arr.push({
    user: user_id,
    trasnsaction_id: transaction_unique_id,
    work: true
  });

  StoreService.dispatch(underounstructionAction(arr));
}

 function* handleIO(echoClient: Echo): Generator<ForkEffect>{
   yield fork(read, echoClient);
 }

export function* getSocketFlow(): Generator<TakeEffect | ForkEffect | SelectEffect | CallEffect | CancelEffect, void, never> {
  while(true) {
    yield take(IS_AUTHENTICATED);
    const isAuth: boolean = yield select(makeSelectisAuthenticated)
    if(isAuth) {

      const echoClient: Echo = yield call(connect);

      try {

      const task1 = yield fork(handleIO, echoClient);
      const task2 = yield fork(echoLogout, echoClient)
      yield take (LOGOUT_REQUEST);
      yield cancel(task1);
      yield cancel(task2);


      } finally {
        //echoClient.leave;
      }

    }
  }
}

function* submitContactForm(action: any) {
  const {data} = action;
  
  try {
    
    const response: AxiosResponse = yield call(submitContactFormService, data);

    const tagManagerArgs = {
      dataLayer: {
          formName: get(data, 'data.email'),
          senderType: get(data, 'data.subject'),
         'event': gtm.events.formSubmitted,
      },
    }
    TagManager.dataLayer(tagManagerArgs)
    yield put({type: SUBMIT_CONTACT_FORM_SUCCESS, response});
    StoreService.dispatch(setNotificationSuccess(intl.formatMessage(generalMessages.SuccessContactUs)));
  } catch (error) {
    StoreService.dispatch(setNotificationError(intl.formatMessage(generalMessages.SomethingNotRight)));
    yield put({type: SUBMIT_CONTACT_FORM_ERROR, error})
  }
}



export function* logout() {

  while(true) {
    yield take (LOGOUT_REQUEST);
    const payload = {
      isAuthenticated : false,
      token: '',
      userInfo: null,
    }
    try {
      //yield call(authLogoutService);
      UserService.removeJwtFromLocalStorage();
      yield put({type: IS_AUTHENTICATED, payload});
      yield put({type: RESET_APP});
      //call upload reduces
      yield put(actions.deleteAllFiles())
      yield put({type: LOGOUT_SUCCESS});

      yield call(resetState);
      yield put(push(routes.HomePage));
    } catch(error) {

    }
  }
}

function* featuredJobsFlow(action: any) {
  const {page} = action;
  const controller = new AbortController();
  const signal = controller.signal;
  try {
    const response: AxiosResponse = yield call(getFeaturedJobs, page, FEATURED_JOBS_PER_PAGE, signal);
    yield put({type:FEATURED_JOBS_SUCCESS, response});
  } catch(error) {
    // if (yield cancelled()) {
    //   yield call(controller.abort())
    // }
      yield put({type:FEATURED_JOBS_ERROR, error})
  } finally {
    //if (yield cancelled()) {
      yield controller.abort()
    // }
  }
}

// Upcoming Events

function* upcomingEventsFlow(action: any) {
  const {category, page} = action;
  try {
    const response: AxiosResponse = yield call(getUpcomingEvents, category, page, EVENT_ITEMS_PER_PAGE);
    yield put({type: UPCOMING_EVENTS_SUCCESS, response});

  } catch(error) {
      yield put({type: UPCOMING_EVENTS_ERROR, error})
  }
}

// Recent News
function* recentNewsFlow(action: any) {
  const {category, page} = action;
  try {
    const response: AxiosResponse = yield call(getRecentNews, category, page, RECENT_NEWS_PER_PAGE);
    yield put({type: RECENT_NEWS_SUCCESS, response});

  } catch(error) {
      yield put({type: RECENT_NEWS_ERROR, error})
  }
}



// Delete
function* deleteItem(action: any) {
  const {id, model, path} = action;
  try {
    const response: AxiosResponse = yield call(deleteItemService, model, id);
    yield put({type: DELETE_ITEM_SUCCESS, response});
    switch(path) {
      case 'getAll':
        yield put({type: GET_ALL});
        break;
      case 'latest' :
        yield put({type: GET_LATEST_SERVICES});
        break;
    }
    StoreService.dispatch(setNotificationSuccess(intl.formatMessage(generalMessages.DeleteNotif)))
  } catch (error) {
    yield put({type: DELETE_ITEM_ERROR, error})
  }
}

function* getPackagesFlow(action: any) {
  const { model } = action;
  try {
    const response: AxiosResponse = yield call(getPackages, model);
    yield put({type: GET_PACKAGES_SUCCESS, response});
  } catch(error) {
    yield put({type: GET_PACKAGES_ERROR});
  }
}

function* getPackagesPublicFlow(action: any) {
  const { model } = action;
  try {
    const response: AxiosResponse = yield call(getPackagesPub, model);
    yield put({type: GET_PACKAGES_PUBLIC_SUCCESS, response});
  } catch(error) {
    yield put({type: GET_PACKAGES_PUBLIC_ERROR});
  }
}

function* getPackagesJobFlow(action: any) {
  const { duration } = action;
  try {
    const response: AxiosResponse = yield call(getPackagesJobService, duration);
    yield put({type: GET_JOB_PACKAGES_SUCCESS, response});
  } catch(error) {
    yield put({type: GET_JOB_PACKAGES_ERROR});
  }
}


// Select Categories
function* selectCategoriesFlow(action: any) {
  const {slug} = action;

  try {
    const response: AxiosResponse = yield call(selectCategoriesService, slug);
    yield put({type: SELECT_CATEGORY_SUCCESS, response})
  } catch (error) {
    yield put({type: SELECT_CATEGORY_ERROR, error})
  }
}

// Select Categories Filter
function* selectCategoriesFilterFlow(action: any) {
  try {
    const response: AxiosResponse = yield call(selectCategoriesFilterService);
    yield put({type: SELECT_CATEGORIES_SUCCESS, response})
  } catch (error) {
    yield put({type: SELECT_CATEGORIES_ERROR, error})
  }
}

// Select Special Categories Filter
function* selectSpecialCategoriesFilterFlow(action: any) {

  try {
    const response: AxiosResponse = yield call(selectSpecialCategoriesFilterService);
    yield put({type: SELECT_SPECIAL_CATEGORIES_SUCCESS, response})
  } catch (error) {
    yield put({type: SELECT_SPECIAL_CATEGORIES_ERROR, error})
  }
}

// Get Our Clients Home
function* getOurClientsHomeFlow(action: any) {
  const {page} = action;
  //alert(CLIENTS_PER_PAGE);
  try {
    const response: AxiosResponse = yield call(getClientsHomeService, page, CLIENTS_PER_PAGE);
    yield put({type: GET_HOME_OUR_CLIENTS_SUCCESS, response});
  } catch(error) {
    yield put({type: GET_HOME_OUR_CLIENTS_ERROR, error});
  }
}

function* getCitiesFlow(action: any): Generator<CallEffect | PutEffect , void, never>{
  const {city} :{city: string} = action;

  try {
    const response: AxiosResponse = yield call(getCitiesService, city);
    yield put({type: GET_CITIES_SUCCESS, response});
  } catch (error) {
    yield put({type: GET_CITIES_ERROR, error});
  }
}

export default function* appSaga() {
    yield all([
      takeLatest(REFRESH_TOKEN, refreshTokenFlow),
      takeEvery(FEATURED_JOBS_REQUEST, featuredJobsFlow),
      takeEvery(UPCOMING_EVENTS, upcomingEventsFlow),
      takeEvery(RECENT_NEWS, recentNewsFlow),
      takeLatest(GET_HOME_OUR_CLIENTS, getOurClientsHomeFlow),
      takeEvery(DELETE_ITEM, deleteItem),
      takeEvery(COUNT_UNREAD_MESSAGES, getUnreadMessagesFlow),
      takeEvery(GET_PACKAGES, getPackagesFlow),
      takeEvery(GET_PACKAGES_PUBLIC, getPackagesPublicFlow),
      takeEvery(GET_JOB_PACKAGES, getPackagesJobFlow),
      takeLatest(SELECT_CATEGORY, selectCategoriesFlow),
      takeLatest(SELECT_CATEGORIES, selectCategoriesFilterFlow),
      takeLatest(SELECT_SPECIAL_CATEGORIES, selectSpecialCategoriesFilterFlow),
      takeLatest(NOTIFICATION_NUMBER, getNumberOfNotification),
      takeLatest(GET_CITIES, getCitiesFlow),
      takeLatest(SUBMIT_CONTACT_FORM, submitContactForm),
      fork(getSocketFlow),
      fork(logout),
    ])
}
