
import { 
  GET_COUPONS,
  REQUEST_BUSINNES_USERS,
  REQUEST_SAVE_COUPON,
  REQUEST_DELETE_COUPON,
  REQUEST_COUPONS_BY_BUSINESS,
  GET_SELECT_PACKAGES
} from './constants';


export function getCoupons(page: number, search: string) {
  return {
    type: GET_COUPONS,
    page,
    search
  };
}

export const getUsersAction = (search: string) => {
  return {
    type: REQUEST_BUSINNES_USERS,
    search: search,
  }
}

export const submitCoupons = (data: any) => {
  return {
    type: REQUEST_SAVE_COUPON,
    data
  }
}

export const deleteCoupon = (id: string, page: number) => {
  return {
    type: REQUEST_DELETE_COUPON,
    id,
    page
  }
}


export const getCouponsByBusinessUser = (page: number) => {
  return {
    type: REQUEST_COUPONS_BY_BUSINESS,
    page,
  };
}

export const getSelectOptionsPackagesAction = () => {
  return {
    type: GET_SELECT_PACKAGES
  };
}


