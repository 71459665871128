// Scope
const scope = 'loop/Documents';

export const GET_DOCUMENTS = `${scope}/GET_DOCUMENTS`;
export const GET_DOCUMENTS_SUCCESS = `${scope}/GET_DOCUMENTS_SUCCESS`;
export const GET_DOCUMENTS_ERROR = `${scope}/GET_DOCUMENTS_ERROR`;

export const SAVE_DOCUMENTS = `${scope}/SAVE_DOCUMENTS`;
export const SAVE_DOCUMENTS_SUCCESS = `${scope}/SAVE_DOCUMENTS_SUCCESS`;
export const SAVE_DOCUMENTS_ERROR = `${scope}/SAVE_DOCUMENTS_ERROR`;

export const REMOVE_COVER_LETTER = `${scope}/REMOVE_COVER_LETTER`;
export const REMOVE_CV = `${scope}/REMOVE_CV`;

export const SET_COVER_LETTER = `${scope}/SET_COVER_LETTER`;
export const SET_CV = `${scope}/SET_CV`;
