/**
 * Messages selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMessages = (state: any) => state.messages || initialState;

const makeSelectMessages = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.messages,
);

const makeSelectJobMessages = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.jobs,
);

const makeSelectMessagesLoading = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.loading,
);

const makeSelectMessagesConversation = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.conversationMessages,
);

const makeSelectMessagesConversationLoading = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.conversationLoading,
);

const makeSelectMessagesConversationRecipientIds = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.recipientIds,
);
const makeSelectSendMessageLoading = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.sendMessageLoading,
);

const makeSelectDisplayModal = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.showModal,
);

const makeSelectJobId = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.jobId,
);

const entityIdSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.entityId,
);

const threadIdSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.threadId,
);


const entityTypeSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.entityType,
);

const usersSlector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.users,
);

const makeSelectPageCount = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.pageCount,
)

const makeSelectCurrentPage = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.currentPage,
);

const SearchLoadingSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.searchLoading,
);

const makeSelectIsRead = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.isRead,
);

const displayMiniConversationWindowSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.displayMiniConversationWindow,
);

const fileProgressSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.progress,
);

const conversationTokenSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.conversationToken,
);


const newConversationSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.newConversation,
);

const conversationSubjectSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.conversationSubject,
);

const recipientSelector = createSelector(
  selectMessages,
  messagesSelectorState => messagesSelectorState.recipient,
);


export { 
  makeSelectMessages,
  makeSelectMessagesLoading, 
  makeSelectJobMessages, 
  makeSelectMessagesConversationLoading,
  makeSelectMessagesConversation,
  makeSelectMessagesConversationRecipientIds,
  makeSelectSendMessageLoading,
  makeSelectDisplayModal,
  makeSelectJobId,
  entityIdSelector,
  entityTypeSelector,
  usersSlector,
  makeSelectCurrentPage,
  makeSelectPageCount,
  SearchLoadingSelector,
  threadIdSelector,
  makeSelectIsRead,
  displayMiniConversationWindowSelector,
  fileProgressSelector,
  conversationTokenSelector,
  newConversationSelector,
  conversationSubjectSelector,  
  recipientSelector
};
