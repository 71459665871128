
import { 
  NOTIFICATIONS_SUCCESS, 
  NOTIFICATIONS_INFO, 
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_WARNING, 
  NOTIFICATION_INIT, 
  ALL_NOTIFICATIONS, 
  UPDATE_NOTIFICATIONS, 
  DELETE_NOTIFICATION
} from './constants';

export function getNotification(typeOfNotification: string, message?: string) {
  return {
    type: NOTIFICATION_INIT,
    payload : {
      typeOfNotification,
      message,
    }
    ,
  };
}

export function setNotificationSuccess(message: string) {

  return {
    type: NOTIFICATIONS_SUCCESS,
    message,
  };
}

export function setNotificationError(message: string) {
  return {
    type: NOTIFICATIONS_ERROR,
    message,
  };
}

export function setNotificationInfo(message: string) {
  return {
    type: NOTIFICATIONS_INFO,
    message,
  };
}

export function setNotificationWarning(message: string) {
  return {
    type: NOTIFICATIONS_WARNING,
    message,
  };
}

export function getAllNotificationsAction(page: number) {
  return {
    type: ALL_NOTIFICATIONS,
    page
  }
}

export function updateNotificationAction() {
  return {
    type: UPDATE_NOTIFICATIONS
  }
}

export function deleteNotificationAction(id: string) {
  return {
    type: DELETE_NOTIFICATION,
    id
  }
}