import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import update from 'immutability-helper';
import moment from 'moment';
import {CDN_URL} from '../config/config'

const IMAGE_CACHE = 'image_cache';
// const clearAllData = () => {
//   AsyncStorage.getAllKeys()
//     .then(keys => AsyncStorage.multiRemove(keys))
//     .then(() => {});
// };
// clearAllData();
const getImages = () => {
  try {
    const imageList = localStorage.getItem(IMAGE_CACHE)
    
    if(imageList) {
      
      let images: Array<any> = JSON.parse(imageList);
      return images ? images : []; 
     } else {
       return [];
     }

  } catch (error) {
    // Error 
    console.log(error);
  }
}

export const findImageIndex = (imageKey: string) => {
  const images = getImages();
  const image : number = findIndex(images, {'key': imageKey})
  return image;
}

export const getImageUrl = (imageKey:  string) => {
  
  const images = getImages();
  const image : Object | any = find(images, {'key': imageKey});
  
  if(image){
    return image
  }
  return null;
}


export const saveImagePath = (imageKey: string, imageUrl : string) => {
  
  try {
  
    let images = getImages();
  
    const imageIndex = findImageIndex (imageKey);

    if(imageIndex !== -1) {
      
      update(images, {
        [imageIndex]: {
          value: { $set: imageUrl},
          expires_at:{$set: moment().unix()},
      }})
     } else {
      images?.push({
        key : imageKey,
        value: imageUrl,
        expires_at: moment().unix(),
      }) 
      
    }
    localStorage.setItem(IMAGE_CACHE, JSON.stringify(images));
    
  } catch (error) {
    // Error saving data
  }
};

export const getImagePath = (namespace: string) => {
  try {
    return localStorage.getItem(namespace);
  } catch (e) {}
};

export const removeImageNamespace = () => {
  try {
    return localStorage.removeItem(IMAGE_CACHE);
  } catch (e) {}
};

export const removeImage = (imageKey:  string) => {
  
  const images = getImages();

  if(images) {
    const filteredItems = images.filter(function(item) {
      return item.key !== imageKey
    })

    localStorage.setItem(IMAGE_CACHE, JSON.stringify(filteredItems));
  }

}


export const getPublicUrl = (name: string) => {
  return CDN_URL + name ;
}

export const getYoutubePoster = (url: string) => {
    
  if(url){
       let thumbnail, result;
       //const VID_REGEX = ;
       
       if(result = url.match(/^.*(youtu.com\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/))
       {
           if(result[2]) {
               thumbnail = "https://img.youtube.com/vi/"+result[2]+"/0.jpg";
               return thumbnail;
           }
           

             
       }
  
      
   }
   return false;

}
