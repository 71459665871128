/**
 * Jobs selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectJobs = (state: any) => state.jobs || initialState;

const makeSelectJobs = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.jobs,
);

const makeSelectJobsLoading = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.loading,
);

const makeSelectLoaderDetails = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.loaderDetail,
);

const makeSelectLoaderSimilar = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.loaderSimilar,
);

const makeSelectJobsPage = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.page,
);

//  Select Job Item
const makeSelectJobItem =
  createSelector(
    selectJobs,
    jobsSelectorState => jobsSelectorState.jobItem,
);

const makeSelectJobItemError =
  createSelector(
    selectJobs,
    jobsSelectorState => jobsSelectorState.jobItemError,
);

const makeSelectorCheck = 
  createSelector(
    selectJobs,
    jobsSelectorState => jobsSelectorState.applicantExists,
);

const makeSelectSimiliarJobs = 
  createSelector(
    selectJobs,
    jobsSelectorState => jobsSelectorState.similiarJobs
);

const makeSelectSavedJob = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.isSavedJob,
);

const makeSelectSavedJobPayload = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.saveJobPayload,
);

const makeSelectSavedJobLoading = createSelector(
  selectJobs,
  jobsSelectorState => jobsSelectorState.savedJobLoading,
);

const makeSelectPageCount = createSelector(
  selectJobs,
  jobsState => jobsState.pageCount,
)

const makeSelectCurrentPage = createSelector(
  selectJobs,
  jobsState => jobsState.currentPage,
);

const makeSelectPageSimilarCount = createSelector(
  selectJobs,
  jobsState => jobsState.pageSimilarCount,
)

const makeSelectCurrentSimilarPage = createSelector(
  selectJobs,
  jobsState => jobsState.currentSimilarPage,
);

const selectCitySeo = createSelector(
  selectJobs,
  jobsState => jobsState.citySeo,
);

const successfullApplySelector = createSelector(
  selectJobs,
  jobsState => jobsState.applyModal,
);

const isUserLoggedInSelector = createSelector(
  selectJobs,
  jobsState => jobsState.isUserLoggedInModal,
);

export { 
  makeSelectLoaderDetails, 
  makeSelectLoaderSimilar, 
  makeSelectJobs, 
  makeSelectSimiliarJobs, 
  makeSelectJobsLoading, 
  makeSelectJobsPage, 
  makeSelectJobItem, 
  makeSelectorCheck, 
  makeSelectSavedJob, 
  makeSelectSavedJobLoading, 
  makeSelectSavedJobPayload,
  makeSelectCurrentPage,
  makeSelectPageCount,
  makeSelectCurrentSimilarPage,
  makeSelectPageSimilarCount,
  selectCitySeo,
  makeSelectJobItemError,
  successfullApplySelector,
  isUserLoggedInSelector
};
