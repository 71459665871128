import styled from 'styled-components';
import colors from '../../config/colors';

const HeaderDropdown = styled.div`
  color: ${colors.WHITE};
  font-size: 16px;
  font-family: "Gilroy-Medium";
  transition: color 100ms ease-in-out;

  @media (max-width: 1100px) {
    color: ${colors.BLACK};
    margin-left: 0;
    padding: 11.5px 14px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    margin: -1px 0 0 7px;

    svg {
      width: 11px;
      height: 7px;
      fill: ${colors.WHITE};
      cursor: pointer;
      transition: fill 100ms ease-in-out;

      @media (max-width: 1100px) {
        fill: ${colors.BLACK};
      }

      &:hover {
        fill: ${colors.PRIMARY_ORANGE};
      }
    }
  }

  ul {
    position: absolute;
    right: 0;
    width: 185px;
    top: 47px;
    background-color: ${colors.WHITE};
    border-radius: 3px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.07);
    z-index: 1;
    text-align: left;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms ease-in-out;

    @media (max-width: 1100px) {
      width: 100%;
      box-shadow: none;
    }

    li {
      list-style: none;
      margin: 0;
      line-height: normal;
    }

    svg {
      margin-right: 10px;
      width: 16px;
      height: 16px;
      fill: ${colors.PRIMARY_PURPLE};
    }

    a {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: ${colors.GRAY_DARK};
      margin-left: 0 !important;
      padding: 10px 13px;
      transition: all 100ms ease-in-out;

      @media (max-width: 1100px) {
        font-size: 16px;
        color: ${colors.BLACK};
      }

      svg {
        margin: -1px 10px 0 0;
        width: 14px;
        height: 14px;
        fill: ${colors.PRIMARY_PURPLE};
        display: inline-block;
        vertical-align: middle;

        @media (max-width: 1100px) {
          margin: 0 10px 0 0;
        }
      }

      &:hover {
        background-color: ${colors.PRIMARY_ORANGE_LIGHT};
        color: ${colors.GRAY_DARK};
      }
    }
  }

  &.dropdownOpen {

    ul {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.dropdownFixed {
    color: ${colors.BLACK};
  }
`;


export default HeaderDropdown;
