export const BASE_URL = process.env.REACT_APP_API_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const APP_URL = process.env.REACT_APP_URL;
export const SOCKET_ENDPOINT = process.env.REACT_APP_ECHO_URL;
export const PAYMENT_SUCCESS_URL = process.env.REACT_APP_PAYMENT_SUCCESS_URL;
export const PAYMENT_FAIL_URL = process.env.REACT_APP_PAYMENT_FAIL_URL;
export const DEBUG  = process.env.REACT_APP_DEBUG ? process.env.REACT_APP_DEBUG : 'true';

export const DEFAULT_CURRENCY = 'RSD'

export const JWT_EXPIRED_TIME = 30;

export const REDUX_SAGA_KEYS = {
    jobs: 'jobs',
    events: 'events',
    news: 'news',
    login: 'login',
    forgotPassword: 'forgotPassword',
    avatar: 'avatar',
    documents: 'documents',
    jobApplications: 'jobApplications',
    notifications: 'notifications',
    register: 'register',
    requestedServices:'requestedServices',
    users: 'users',
    unsubscribe: 'unsubscribe',
    messages: 'messages',
    job: 'job',
    categories: 'categories',
    coupons: 'coupons',
    faqs: 'faqs',
    template: 'template',
    upload: 'upload',
    userInfo: 'userInfo',
    preselection: 'preselection',
    adminJobs: 'adminJobs',
    restoreJob: 'restoreJob',
    priceList: 'priceList',
    employers: 'employers',
    companyInfo: 'companyInfo',
    activeServices: 'activeServices',
    ourClients: 'ourClients',
    audioControls: 'audioControls',
    choosenAdCategories: 'choosenAdCategories',
    packages: 'packages',
    payment: 'payment',
    transactions: 'transactions',
    tasks: 'tasks',
    historyTasks: 'historyTasks',
    stats: 'stats',
    savedJobs: 'savedJobs',
    resume: 'resume',
    filters: 'filters',
    supportAndDevelopment: 'supportAndDevelopment',
    choosePackages: 'choosePackages'
}

export const GROUP_CATEGORIES = {
    industry: 'industrija',
    area_of_work: 'oblasti-rada',
    area_of_work_mailchimp: 'oblasti-rada-mailchimp'
}

export const STATUSES = {
    completed: 'completed',
    process: 'process',
    process_payment: 'process_payment',
    inactive: 'inactive',
    expired: 'expired',
    draft: 'draft',
    pause: 'pause',
    preselection: 'preselection',
    selection: 'selection',
    hr_created: 'hr_created',
    service_requested_preselection: "service_requested_preselection",
    service_requested_selection: "service_requested_selection",
}

export const MODELS = {
    news: 'news',
    job: 'job',
    event: 'event',
    preselection: 'preselection',
    selection: 'selection',
    job_audio: 'job_audio',
    direct_message: 'direct_message',
    user: 'user'
}

export const FILTERS = {
    jobs: "jobs",
    events: "events",
    news: "news"
}

export const JOB_TYPE = {
    help: "help",
    custom: "custom",
    template: "template"
}

export const PACKAGE_NAME = {
  classic: "classic",
  hot: "hot",
  featured: "featured",
  total: "total",
  elementary: "elementary"
}

export const NOTIFICATIONS = {
    entity_approved: "entity_approved",
    service_requested: "service_requested",
    task: "task",
    status_info: "status_info",
    review_job_requested: "review_job_requested",
    transaction_assigned: "transaction_assigned",
    bid_created: "bid_created",
    bid: "bid",
    service_completed: "service_completed",
    task_create: "task_create",
    hr_candidate_list : 'hr_candidate_list',
    hr_selections: 'hr_selections',
    assign_task: 'assign_task',
    assign_task_special: 'assign_task_special',
    queue_coupons: 'queue_coupons',
}    

export const BUTTON_TYPE = {
    letter: "letter",
    cv: "cv"
}

export const LOGIN_TYPE_MESSAGE = {
    email: "email",
    not_active: "not_active",
    phone: "phone"
}

export const TASK_TYPE = {
    create: "create",
    service_requested_preselection: "service_requested_preselection",
    service_requested_selection: "service_requested_selection",
    preselection: "preselection",
    selection: "selection",
    approve: "approve"
}

export const PAYMENT_TYPE = {
    card: 'card',
    proinvoice: 'proinvoice',
}

export const TRANSACTION_STATUS = {
    approved: 'APPROVED',
    approved_before_booked: 'APPROVED_BEFORE_BOOKED',
    declined: 'DECLINED',
    failed: 'FAILED',
    not_started: 'NOT_STARTED',
    booked: 'BOOKED'
}

export const TASK_STATUS = {
    activate_before_payment: 'activated_before_payment',
    waiting_for_approval: 'waiting_for_approval',
    booked: 'booked',
    creating_price: 'creating_price',
    bid_sent: 'bid_sent',
    inactive: 'inactive',
    completed: 'completed',
    bid_accepted: 'bid_accepted',
    // ongoing_preselection: 'ongoing_preselection',
    // ongoing_selection: 'ongoing_selection',
    ongoing: 'ongoing',
    bid_rejected: 'bid_rejected'
}

export const jobLanguages = {
    en: 'en',
    rs: 'rs'
}

export const couponType = {
    free: 'free',
    payed: 'payed'
}

export const CANDIDATE_JOB_STATUS = {
    application_recieved: 'Prijava primljena',
    cv_match: 'CV odgovara',
    cv_does_not_match: 'CV ne odgovara',
    interview: 'Intervju',
    inner_circle: 'Uži krug',
    inadequate: 'Neodgovarajući',
    it_doesnt_fit: 'Ne uklapa se',
    rejected: 'Odbijen',
    gave_up: 'Odustao',
    offer: 'Ponuda',
    hired: 'Zaposlen'
}


export const BID_STATUS = {
    created: 'created',
    accepted: 'accepted',
    rejected: 'rejected'
}

export const JOB_TYPE_SERVICES = {
    tailor_made : 'tailor_made',
    loop_selection: 'loop_selection',
    cbi_interview : 'cbi_interview',
    standardised_interview: 'standardised_interview',    
}

export const PRESELECTION_PACKAGE = {
    loop_preselection : 'loop_preselection',
    loop_preselection_tailor_made : 'loop_preselection_tailor_made',
    loop_selection_cbi_interview : 'loop_selection_cbi_interview',
    loop_selection_standardised_interview: 'loop_selection_standardised_interview',
    loop_selection_cbi_interview_tailor_made : 'loop_selection_cbi_interview_tailor_made',
    loop_selection_standardised_interview_tailor_made: 'loop_selection_standardised_interview_tailor_made',    
}

