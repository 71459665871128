export const converFromEngToSrb  = ({
    title,
    city,
    employer,
    field_of_work,
    employment_type,
    seniority,
    position_level,
    industry,
    language,
    remote,
    salary,
    disability,
    for_over_50,
    jobs_abroad,
    for_students,
    job_description, 
    }: {
        title: string | null,
        city: string | null,
        employer: string | null,
        field_of_work: string | null,
        employment_type: string | null,
        seniority: string | null,
        position_level: string | null,
        industry: string | null,
        language: string | null,
        salary: boolean,
        remote: boolean,
        disability: boolean,
        for_over_50: boolean,
        jobs_abroad: boolean,
        for_students: boolean,
        job_description : string | boolean,
    }) => (

    {
        pozicija : title ? title :  null,
        grad: city ? city : null,
        poslodavac : employer ? employer: null,
        kategorija : field_of_work ? field_of_work : null,
        tip_zaposlenja : employment_type ? employment_type : null,
        senioritet : seniority ? seniority : null,
        nivo_pozicije : position_level ? position_level : null,
        industrija : industry ? industry : null,
        jezik : language ? language : null,
        plata : salary ? salary : null,
        rad_od_kuce : remote ? remote : false,
        osobe_sa_invaliditetom: disability ? disability : false,
        preko_50_godina : for_over_50 ? for_over_50 : false, 
        poslovi_u_inostranstvu : jobs_abroad ? jobs_abroad : false, 
        za_studente : for_students ? for_students : false, 
        kljucna_rec : job_description ? job_description : false, 
       
    }

)

export const converFromSrbToEng  = ({
    pozicija, 
    grad,
    poslodavac,
    kategorija,
    kljucna_rec,
    tip_zaposlenja,
    senioritet,
    nivo_pozicije,
    industrija,
    jezik,
    rad_od_kuce,
    plata,
    osobe_sa_invaliditetom,
    preko_50_godina,
    poslovi_u_inostranstvu,
    za_studente,

    }: {
       pozicija: string | null, 
       grad: string | null,
       poslodavac: string | null,
       kategorija: string | null,
       kljucna_rec: string | null,
       tip_zaposlenja: string | null,
       senioritet: string | null,
       nivo_pozicije: string | null,
       industrija: string | null,
       rad_od_kuce: boolean,
       jezik: string | null,
       plata: boolean,
       osobe_sa_invaliditetom: boolean,
       preko_50_godina: boolean,
       poslovi_u_inostranstvu: boolean,
       za_studente: boolean,
    }) => (

    {
        title : pozicija ? pozicija :  null,
        city : grad ? grad :  null,
        employer : poslodavac ? poslodavac :  null,
        field_of_work : kategorija ? kategorija : null,
        job_description : kljucna_rec ? kljucna_rec : null,
        employment_type : tip_zaposlenja ? tip_zaposlenja : null,
        seniority : senioritet ? senioritet : null,
        position_level : nivo_pozicije ? nivo_pozicije : null,
        industry : industrija ? industrija : null,
        language : jezik ? jezik : null,
        remote : rad_od_kuce ? rad_od_kuce : null,
        salary : plata ? plata : null,
        disability: osobe_sa_invaliditetom ? osobe_sa_invaliditetom : false, 
        for_over_50: preko_50_godina ? preko_50_godina : false, 
        jobs_abroad: poslovi_u_inostranstvu ? poslovi_u_inostranstvu : false, 
        for_students: za_studente ? za_studente : false, 
    }

)