import React, {FC, useCallback, useEffect} from "react";
import { Route, Redirect, RouteProps } from 'react-router-dom';
import routes from '../config/routes';
import {useSelector} from 'react-redux';
import {makeSelectUserInfo, makeSelectLoadingUserInfo} from './App/selectors';
import {UserService} from '../services/UserService'
import {LoadOverlaySticky} from "../components/Animations";
import history from '../utils/history';


interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component?: any;
    // tslint:disable-next-line:no-any
    children?: any;
    roles?: any, 
  }
  

  const PrivateRoute: FC<PrivateRouteProps> = (props) => {
    const { component: Component, children, roles, ...rest } = props;
    const tokenStorage = UserService.getJwtFromLocalStorage();
    const token = tokenStorage ? tokenStorage.token :  null;
    const userInfo = useSelector(makeSelectUserInfo);
    
    const loading = useSelector(makeSelectLoadingUserInfo);
        
    useEffect(()=>{
      if(roles && roles.length && !loading) {
       
        const grant =  roles.includes(userInfo.role);
        
        if(!grant && token) { 
          history.push(routes.error403)
        }
              
      } 
    }, [userInfo, loading, roles])  

    const grantRolePermission = useCallback((roles: string[] | undefined) => {
      if(roles && roles.length) {
        const grant =  roles.includes(userInfo.role);
        return grant && token ? true :  false;
       
      }
      return true;
   
    }, [userInfo])  
    
    return (
      
      <Route
        {...rest}
        render={routeProps =>

          token && loading ?  <LoadOverlaySticky /> : userInfo.role && !loading ? 
            grantRolePermission(roles)
             ? (
              children
            ) : (
            
            <Redirect
              to={{
                pathname: routes.HomePage, search: '?modal=true&LoginPage=true',
                state: { from: routeProps.location },
              }}
            />
          ) : (
            <Redirect
            to={{
              pathname: routes.HomePage, search: '?modal=true&LoginPage=true',
              state: { from: routeProps.location },
            }}
          />
          )
        }
      /> 
    );
  };
  
  export default PrivateRoute;