import {UserRole} from '../models/roles';

const roles = {
  ADMIN: 'admin' as UserRole,
  SUPER_ADMIN: 'super-admin' as UserRole,
  USER: 'user' as UserRole,
  BUSINESS_USER: 'business' as UserRole,
  HR_USER: 'hr' as UserRole,
  SUPPORT: 'support' as UserRole,
  FINANCE: 'finance' as UserRole,
  MANAGEMENT: 'management' as UserRole
};

export default roles;

