import {fork, take, select,call, put, cancel, takeLatest, takeEvery} from 'redux-saga/effects';
import {authLoginService, resendVerificationEmailService, resendVerificationCodeService} from './services';

import {
  LOGIN_REQUEST, 
  LOGOUT_REQUEST, 
  LOGIN_ERROR, 
  LOGIN_SUCCESS, 
  RESEND_VERIFICATION_EMAIL_REQUEST, 
  RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS, 
  RESEND_VERIFICATION_EMAIL_REQUEST_ERROR, 
  RESEND_VERIFICATION_CODE_REQUEST,
  RESEND_VERIFICATION_CODE_REQUEST_SUCCESS,
  RESEND_VERIFICATION_CODE_REQUEST_ERROR,
  LOGIN_AFTER_VALIDATION_REQUEST,
  LOGIN_VERIFY_PHONE_ERROR,
  LOGIN_VERIFY_PHONE_SUCCESS,
  LOGIN_VERIFY_PHONE
} from './constants';

import {push} from 'connected-react-router'
import routes from '../../config/routes';
import {UserService} from '../../services/UserService';
import {setNotificationSuccess, setNotificationError} from '../Notifications/actions';
import { IS_AUTHENTICATED, GET_USER_INFO_SUCCESS, GET_USER_INFO_ERROR } from '../App/constants';
import StoreService from '../../utils/StoreService'
import {getUserInfoService} from '../App/services';
import Roles from '../../config/roles';
import intl from '../../intl';
import messages from './messages';

import {makeSelectLocation} from '../App/selectors';
import get from 'lodash/get'
import { LOGIN_TYPE_MESSAGE } from '../../config/config';
import TagManager from 'react-gtm-module';
import gtm from '../../config/gtm';

import { AxiosError, AxiosResponse } from 'axios';
import { SagaIterator } from '@redux-saga/types';
import { loginAfterValidation } from './actions';
import { verifyPhoneNumberService } from '../Register/services';
import generalMessages from '../generalMessages';


export function* loginAfterValidationFlow(action: any) : SagaIterator {
  
  let jobRedirection: string = '';

  const {token, expires_in, category, slug} = action;

  UserService.setJwtToLocalStorage(token, expires_in);
  
  jobRedirection =  category && slug ?  `/poslovi/${category}/${slug}` : '';

  var today = new Date().getHours();
    
  const welcomeMessage =  today < 11 ? messages.welcomeMorning : today > 19 ? messages.welcomeEvening : messages.welcomeAfternoon;  

  const profileResponse: AxiosResponse = yield call(getUserProfile);
  const profile = profileResponse.data;
  const {id, first_name} = profile;

  jobRedirection ? 
  yield put(push(jobRedirection)) : 
  profile.role === Roles.USER ? yield put(push(routes.HomePage)) : 
  profile.role === Roles.BUSINESS_USER ? yield put(push(routes.DashboardPage)) : 
  yield put(push('/dashboard/tasks'));


  const tagManagerArgs = {
    dataLayer: {
        userId:  id,
        'event': gtm.events.loggedIn,        
    },
  }
  TagManager.dataLayer(tagManagerArgs)

  jobRedirection = '';

  StoreService.dispatch(setNotificationSuccess(intl.formatMessage(welcomeMessage, {first_name})));

  const payload = {
    isAuthenticated : true,
    token: token,
  }

  yield put({type: IS_AUTHENTICATED, payload});
}

export function* getUserProfile()  {
  try {
    const response: AxiosResponse = yield call(getUserInfoService);
    yield put({type: GET_USER_INFO_SUCCESS, response});
    return response;
  } catch (error) {
    yield put({type: GET_USER_INFO_ERROR, error})
  }
}

function* authorize({username, password}: {username: string, password: string}) : SagaIterator {
    const location = yield select(makeSelectLocation());
    const pathname = get(location, 'location.state.from.pathname');
  
    let jobRedirection: string= '';
    try {
      const response: AxiosResponse = yield call(authLoginService, username, password);
      
      const {token, expires_in, category, slug} = response.data;
      UserService.setJwtToLocalStorage(token, expires_in);
      
      if(category && slug) {
        jobRedirection = `/poslovi/${category}/${slug}`
      }

      const profileResponse: AxiosResponse = yield call(getUserProfile);
      const profile = profileResponse.data;
      
      const payload = {
        isAuthenticated : true,
        token: token,
      }  
      yield put({type: IS_AUTHENTICATED, payload});

      var today = new Date().getHours();
          
      const welcomeMessage =  today < 11 ? messages.welcomeMorning : today > 19 ? messages.welcomeEvening : messages.welcomeAfternoon;   
      yield put({type: LOGIN_SUCCESS});

      jobRedirection ? yield put(push(jobRedirection)) :  pathname ? yield put(push(pathname)) : profile.role === Roles.USER ? yield put(push(routes.HomePage)) : profile.role === Roles.BUSINESS_USER ? yield put(push(routes.DashboardPage)) : profile.role === Roles.MANAGEMENT  ? yield put(push('/dashboard/jobs')) : yield put(push('/dashboard/tasks'));

      const {id, first_name} = profile;

      const tagManagerArgs = {
          dataLayer: {
              userId:  id,
              'event': gtm.events.loggedIn,        
          },
      }
      TagManager.dataLayer(tagManagerArgs)
      if(jobRedirection) {
        jobRedirection='';
      }
           
      StoreService.dispatch(setNotificationSuccess(intl.formatMessage(welcomeMessage, {first_name})))
    } catch(error) {
      const {response} = error as AxiosError;
      const {data}  = response as AxiosResponse;
                 
      if(response && response.status === 401) {
        StoreService.dispatch(setNotificationError(intl.formatMessage(messages.InvalidUsernamePassword)));
      } else if(response && response.status === 403) {
        
        switch(data.type) {
          case LOGIN_TYPE_MESSAGE.not_active:
            StoreService.dispatch(setNotificationError(intl.formatMessage(messages.pleaseWaitForAdminApproval)));
            yield put({type: LOGIN_ERROR, error});  
            break;
          case LOGIN_TYPE_MESSAGE.phone:
            StoreService.dispatch(setNotificationError(intl.formatMessage(messages.pleaseVerifyPhoneNumber)));
            yield put({type: LOGIN_ERROR, error});  
            break;
          case LOGIN_TYPE_MESSAGE.email:
            yield put({type: LOGIN_ERROR, error});
            break;
        }
      } else {
       
        StoreService.dispatch(setNotificationError(intl.formatMessage(messages.InvalidUsernamePassword)));
      }

      yield put({type: LOGIN_ERROR, error});  
     
    }
}

function* resendVerificationEmail(action: any) {
  const {username} : {username: string} = action;
  try {
    const response: AxiosResponse = yield call(resendVerificationEmailService, username);
    yield put({type: RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS, response});
    StoreService.dispatch(setNotificationSuccess(intl.formatMessage(messages.verifyEmailAddress)))
    
  } catch (error) {
    yield put({type: RESEND_VERIFICATION_EMAIL_REQUEST_ERROR, error})
    StoreService.dispatch(setNotificationSuccess(intl.formatMessage(messages.verifyEmailAddressError)))
    
  }
}

function* loginFlow(): SagaIterator {
    while (true) {
      const {username, password} = yield take([LOGIN_REQUEST]);
      let task = yield fork(authorize, {username, password});
      const action = yield take([LOGOUT_REQUEST, LOGIN_ERROR]);
      if (action.type === LOGOUT_REQUEST) {
        if(task) {
          yield cancel(task)
        }  
        yield put(push('/'));
        yield put({type: LOGOUT_REQUEST})
      }
    }
}

function* resendVerificationCodeFlow(action: any) {
  const {username} : {username: string} = action;
  try {
    const response: AxiosResponse = yield call(resendVerificationCodeService, username);
    yield put({type: RESEND_VERIFICATION_CODE_REQUEST_SUCCESS, response});
    StoreService.dispatch(setNotificationSuccess(intl.formatMessage(messages.verifyPhoneNumber)))
  } catch (error) {
    yield put({type: RESEND_VERIFICATION_CODE_REQUEST_ERROR, error})
    StoreService.dispatch(setNotificationSuccess(intl.formatMessage(messages.verifyEmailAddressError)))
    
  }
}

function* loginVerifyPhoneNumber(action: any) {
  const {phone, code} = action;
  try {
    const response: AxiosResponse = yield call(verifyPhoneNumberService,{phone, code});

    const {data} = response;
    const {token, expires_in, category, slug} = data;

    const tagManagerArgs = {
      dataLayer: {
        accountType: Roles.USER,
        'event': gtm.events.registered,        
      },
    }
    TagManager.dataLayer(tagManagerArgs)
    
    yield put({type: LOGIN_VERIFY_PHONE_SUCCESS, response});
    StoreService.dispatch(setNotificationSuccess(intl.formatMessage(generalMessages.phoneNumberVerifiedSuccess)))
    yield StoreService.dispatch(loginAfterValidation({token, expires_in, category, slug}))

  } catch (error) {
    if(error) {
      const {response} = error as AxiosError;

      if(response && response.status === 422) {
        StoreService.dispatch(setNotificationError(intl.formatMessage(generalMessages.VerificationCodeDoesNotExist)));
      } else if(response && response.status === 429) {
        StoreService.dispatch(setNotificationError(intl.formatMessage(generalMessages.ToMuchTimeTriedVerifyPhoneNumber)));
      } else {
        StoreService.dispatch(setNotificationError(intl.formatMessage(generalMessages.SomethingNotRight)));
      }

    }
    yield put({type: LOGIN_VERIFY_PHONE_ERROR, error});
  }
}

export default function* loginSaga() {
    yield fork(loginFlow)
    yield takeLatest(RESEND_VERIFICATION_EMAIL_REQUEST, resendVerificationEmail)
    yield takeLatest(RESEND_VERIFICATION_CODE_REQUEST, resendVerificationCodeFlow)
    yield takeEvery(LOGIN_AFTER_VALIDATION_REQUEST, loginAfterValidationFlow)
    yield takeEvery(LOGIN_VERIFY_PHONE, loginVerifyPhoneNumber)
}