
import { 
  REFRESH_TOKEN, 
  SUBMIT_CONTACT_FORM, 
  FEATURED_JOBS_REQUEST, 
  UPCOMING_EVENTS, 
  GET_JOB_PACKAGES, 
  RECENT_NEWS, 
  DELETE_ITEM, 
  UPDATE_USER_INFO, 
  GET_PACKAGES, 
  GET_PACKAGES_PUBLIC, 
  SELECT_CATEGORY, 
  SELECT_CATEGORIES, 
  CLOUD_NOTE, 
  CLOUD_NOTE_CLOSE, 
  OPEN_ALL_THREADS_SIDEBAR,
  UPDATE_USER_COMPANY_VIDEO_LINK,
  SET_NEWSLETTER,
  SELECT_SPECIAL_CATEGORIES,
  GET_HOME_OUR_CLIENTS,
  GET_CITIES,
  UPDATE_USER_CRITERIA
} from './constants';

import { LOGOUT_REQUEST } from '../Login/constants';


/**
 * Refresh token
 *
 * @return {object} An action object with a type of GET_USER_INFO
 */
export function refreshToken() {
  return {
    type: REFRESH_TOKEN,
  };
}

export function submitContactUsForm(data: object) {
  return {
    type: SUBMIT_CONTACT_FORM,
    data,
  };
}

export function getFeaturedJobs(page: number) {
  return {
    type: FEATURED_JOBS_REQUEST,
    page,
  };
}

// Action get Upcoming Events
export function getUpcomingEvents(category: string, page: number) {  
  return {
    type: UPCOMING_EVENTS,
    category,
    page,
  };
}

// Action Recent News
export function getRecentNews(category: string, page: number) {  
  return {
    type: RECENT_NEWS,
    category,
    page
  };
}

// Delete
export function deleteItem(id: string, model?: string, path?:string ) {
  return {
    type: DELETE_ITEM,
    id,
    model,
    path
  }
}

export function logout() {
  return {
    type: LOGOUT_REQUEST,
  }
}




export function updateAvatar(img_url: string) {
  
  return {
    type: UPDATE_USER_INFO,
    img_url
  }
}
export function updateUserCriteria(criteria: number) {
  
  return {
    type: UPDATE_USER_CRITERIA,
    criteria
  }
}


export function updateCompanyVideoUrl(company_video_url: string) {
  return {
    type: UPDATE_USER_COMPANY_VIDEO_LINK,
    company_video_url
  }
}

// Get Packages
export function getPackages(model: string) {
  return  {
    type: GET_PACKAGES,
    model
  }
}

// Get Packages Public
export function getPackagesPublic(model: string) {
  return  {
    type: GET_PACKAGES_PUBLIC,
    model
  }
}

// Get Packages Job
export function getPackagesJobAction(duration: string) {
  return  {
    type: GET_JOB_PACKAGES,
    duration
  }
}


// Select Category
export function selectCategory(slug: string) {
  return {
    type: SELECT_CATEGORY,
    slug
  }
}

// Select Categories Filter
export function selectCategoriesFilter() {
  return {
    type: SELECT_CATEGORIES
  }
}

// Select Special Category Filter
export function selectSpecialCategoriesFilter() {
  return {
    type: SELECT_SPECIAL_CATEGORIES
  }
}

export function cloudNote(status : boolean, title?: string, description?: any) {
  return {
    type: CLOUD_NOTE,
    cloudNoteModalStatus: status,
    cloudNoteTitle: title,
    cloudNoteDescription: description,
  }
}
export function hideCloudNote() {
  return {
    type: CLOUD_NOTE_CLOSE,
    cloudNoteModalStatus: false,
    // cloudNoteTitle: '',
    // cloudNoteDescription: '',
  }
}

export function openAllThreadsAction(open: boolean) {
  return {
    type: OPEN_ALL_THREADS_SIDEBAR,
    open
  }
}

export function setNewsletterAction(value: boolean) {
  return {
    type: SET_NEWSLETTER,
    value
  }
}

export function getOurHomeClients(page: number) {
  return {
    type: GET_HOME_OUR_CLIENTS,
    page
  };
}

export function getCitiesAction(city: string) { 
  return {
    type: GET_CITIES,
    city
  }
}