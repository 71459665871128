const scope = 'loop/Dashboard/ActiveServices';

export const GET_LATEST_SERVICES = `${scope}/GET_LATEST_SERVICES`;
export const GET_LATEST_SERVICES_SUCCESS = `${scope}/GET_LATEST_SERVICES_SUCCESS`;
export const GET_LATEST_SERVICES_ERROR = `${scope}/GET_LATEST_SERVICES_ERROR`;
export const CHANGE_SERVICE_TYPE = `${scope}/CHANGE_SERVICE_TYPE`;

export const DEACTIVATE_JOB = `${scope}/DEACTIVATE_JOB`;
export const DEACTIVATE_JOB_SUCCESS = `${scope}/DEACTIVATE_JOB_SUCCESS`;
export const DEACTIVATE_JOB_ERROR = `${scope}/DEACTIVATE_JOB_ERROR`;

export const DEACTIVATE_EVENT = `${scope}/DEACTIVATE_EVENT`;
export const DEACTIVATE_EVENT_SUCCESS = `${scope}/DEACTIVATE_EVENT_SUCCESS`;
export const DEACTIVATE_EVENT_ERROR = `${scope}/DEACTIVATE_EVENT_ERROR`;

export const DELETE_NEWS = `${scope}/DELETE_NEWS`;
export const DELETE_NEWS_SUCCESS = `${scope}/DELETE_NEWS_SUCCESS`;
export const DELETE_NEWS_ERROR = `${scope}/DELETE_NEWS_ERROR`;

// Per Page
export const ACTIVE_SERVICES_ITEMS_PER_PAGE = 6;
