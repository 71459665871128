const scope = 'loop/Dashboard/Coupons';

export const GET_COUPONS = `${scope}/GET_COUPONS`;
export const GET_COUPONS_SUCCESS = `${scope}/GET_COUPONS_SUCCESS`;
export const GET_COUPONS_ERROR = `${scope}/GET_COUPONS_ERROR`;


export const REQUEST_BUSINNES_USERS = `${scope}/REQUEST_BUSINNES_USERS`;
export const REQUEST_BUSINNES_USERS_SUCCESS = `${scope}/REQUEST_BUSINNES_USERS_SUCCESS`;
export const REQUEST_BUSINNES_USERS_ERROR = `${scope}/REQUEST_BUSINNES_USERS_ERROR`;

export const REQUEST_SAVE_COUPON = `${scope}/REQUEST_SAVE_COUPON`;
export const REQUEST_SAVE_COUPON_SUCCESS = `${scope}/REQUEST_SAVE_COUPON_SUCCESS`;
export const REQUEST_SAVE_COUPON_ERROR = `${scope}/REQUEST_SAVE_COUPON_ERROR`;

export const REQUEST_DELETE_COUPON = `${scope}/REQUEST_DELETE_COUPON`;
export const REQUEST_DELETE_COUPON_SUCCESS = `${scope}/REQUEST_DELETE_COUPON_SUCCESS`;
export const REQUEST_DELETE_COUPON_ERROR = `${scope}/REQUEST_DELETE_COUPON_ERROR`;

export const REQUEST_COUPONS_BY_BUSINESS = `${scope}/REQUEST_COUPONS_BY_BUSINESS`;
export const REQUEST_COUPONS_BY_BUSINESS_SUCCESS = `${scope}/REQUEST_COUPONS_BY_BUSINESS_SUCCESS`;
export const REQUEST_COUPONS_BY_BUSINESS_ERROR = `${scope}/REQUEST_COUPONS_BY_BUSINESS_ERROR`;

export const GET_SELECT_PACKAGES = `${scope}/GET_SELECT_PACKAGES`;
export const GET_SELECT_PACKAGES_SUCCESS = `${scope}/GET_SELECT_PACKAGES_SUCCESS`;
export const GET_SELECT_PACKAGES_ERROR = `${scope}/GET_SELECT_PACKAGES_ERROR`;

// Per Page
export const GET_COUPONS_BUSS_PER_PAGE = 5;
export const GET_COUPONS_ADMIN_PER_PAGE = 10;