import authInstance from '../../utils/axios/authInstance';
import apiInstance from '../../utils/axios/apiInstance';
import {encodeData} from '../../utils/url';

export const getRefreshToken = () => {
  return apiInstance.get('/refresh-token');
}

export const getUserInfoService = () => {
  return apiInstance.get('/profile/me');
}

export const submitContactFormService = (data: object) => {
  return authInstance.post('/contact-us', data);
}

export const getFeaturedJobs = (page: number, per_page: number, signal: any) => {
  
  const data : any = {
  
    page: page,
    per_page: per_page
    
  };
  const params = encodeData(data);
  return authInstance.get(`/featured-jobs?${params}`, {signal:signal});
};

export const getNumberOfNotificationsService = () => {
  return apiInstance.get(`/number-notifications`);
}

export const getCountUnreadMessages = () => {
  return apiInstance.get(`/count-unread-messages`);
};

// export const

// Get Upcoming Events
export const getUpcomingEvents = (category: string, page: number, per_page: number) => {
  const data : any = {
    featured: true,
    category: category,
    page: page,
    per_page: per_page
  };
  const params = encodeData(data);
  return authInstance.get(`/home-events?${params}`);
};

// Get Most Read News
export const getRecentNews = (category: string, page: number, per_page: number) => {
  const data : any = {
    featured: true,
    category: category,
    page: page,
    per_page: per_page
  };
  const params = encodeData(data);
  return authInstance.get(`/home-news?${params}`);
};

// Delete
export const deleteItemService = (model: string, id: string) => {
  return apiInstance.delete(`${model}/${id}`);
}

// Get packages
export const getPackages = (model: string) => {
  const data : any = {
    type: model
  };
  const params = encodeData(data);
  return apiInstance.get(`/profile/packages?${params}`);
}

// Get packages public
export const getPackagesPub = (model: string) => {
  const data : any = {
    type: model
  };
  const params = encodeData(data);
  return authInstance.get(`/packages?${params}`);
}

// Get packages public
export const getPackagesJobService = (duration: number) => {
  const data : any = {
    duration: duration
  };
  const params = encodeData(data);
  return authInstance.get(`/job-packages?${params}`);
}

// Get Categories for Select
export const selectCategoriesService = (slug: string) => {
 
  const params = encodeData({
    slug: slug
  });
  return authInstance.get(`category-selector?${params}`);
}

// Get Categories for Both Industry and Field of Work
export const selectCategoriesFilterService = () => {
  return authInstance.get(`category-filter-selector`);
}

// Get Categories for Both Industry and Field of Work SPECIAL ones
export const selectSpecialCategoriesFilterService = () => {
  return authInstance.get(`category-filter-selector-special`);
}

// Test
export const userPresenceService = (id: number, status: string) => {
  return authInstance.patch(`user/${id}/${status}`);
}

// Set Read
export const setMessagesReadService = (data: any) => {
  const params = encodeData(data);
  return apiInstance.patch(`/set-messages-read?${params}`);
}

export const getClientsHomeService = (page: number, per_page: number) => {
  const data : any = {
    page: page,
    per_page: per_page
  };
  const params = encodeData(data);
  return authInstance.get(`/our-clients?${params}`);
}
export const getCitiesService = (data: string) => {
  return authInstance.get(`/get-cities/${data}`);
}


