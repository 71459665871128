
import { 
  JOBS_REQUEST, 
  GET_SINGLE_JOB, 
  JOB_APPLY,
  CHECK_APPLICATION,
  SAVE_JOB,
  GET_SIMILIAR_JOBS,
  GET_CITY_DESC,
  GET_JOB_BY_URL_SLUG,
  APPLY_MODAL,
  IS_USER_LOGGED_IN_MODAL,
  JOB_APPLY_VISITED
} from './constants';

export function getAllJobs(page: number, filter?: string, searchParams?: string) { 
  
  return {
    type: JOBS_REQUEST,
    page,
    filter,
    searchParams,
  };
}

// Get Single Job
export function getSingleJob(id: any) {
  return {
    type: GET_SINGLE_JOB,
    id
  }
}


export function getJobByUrlSlug(slug: string) {
  return {
    type: GET_JOB_BY_URL_SLUG,
    slug
  }
}

// Applly for Job
export function applyForPosition(data: object) {
  return {
    type: JOB_APPLY,
    data
  }
}


// Check Application
export function checkingApplication(id: string) {
  return {
    type: CHECK_APPLICATION,
    id
  }
}

export function toggleSavedJob(id: string, saved: boolean, key?: string) {
  return {
    type: SAVE_JOB,
    id,
    saved,
    key
  }
}

// Get Similiar Jobs
export function getSimiliarJobs(industry: string, unique_id: string, page: number) {
  return {
    type: GET_SIMILIAR_JOBS,
    industry,
    unique_id,
    page
  }
}

export function getCityAction(city: string) {
  return {
    type: GET_CITY_DESC,
    city
  }
}

// Modal
export function successfullApplyAction(open: boolean) {
  return {
    type: APPLY_MODAL,
    open
  }
}

export function isUserLoggedInAction(open: boolean) {
  return {
    type: IS_USER_LOGGED_IN_MODAL,
    open
  }
}

export function applyJobVisitedAction(unique_id: string) {
  return {
    type: JOB_APPLY_VISITED,
    unique_id
  }
}


// export function getCitiesAction(city: string) { 
//   return {
//     type: GET_CITIES,
//     city
//   }
// }
