// Axios and other stuff
import authInstance from '../../utils/axios/authInstance';
import apiInstance from '../../utils/axios/apiInstance';
import {encodeData} from '../../utils/url';
import queryString from 'query-string';
import {converFromSrbToEng} from '../../utils/seoConverter'

// Get All Jobs
export const getAllJobs = (page: number, per_page: number, filter?: string, searchParams?: string) => {
  const data: object = {
    page: page,
    per_page: per_page,
    order_by: filter
  };
  const searchObject = queryString.parse(searchParams);  
  const searchObjectRaw  = converFromSrbToEng(searchObject);
  const queryParams = {...data, ...searchObjectRaw};
  const params = encodeData(queryParams);
  return authInstance.get(`/jobs?${params}`);
};

// Get Job By Hash
export const getSingleJob = (id?: string) => {
  return apiInstance.get(`/jobs/${id}`);
}

export const getJobByUrlSlug = (slug?: string) => {
  return apiInstance.get(`/job-by-slug/${slug}`);
}

// Apply For Job
export const applyingForJob = (data: any) => {
  return apiInstance.post(`/job-applications/${data.job_unique_id}`, data);
}

// Check if Job Applications Allready exists
export const checkApplications = (job_unique_id: string) => {
  return apiInstance.get(`/profile/check-job-application/${job_unique_id}`);
}

export const saveJobService = (data: any) => {
  return apiInstance.patch(`/save-job`, data);
}

// Get Similiar Jobs
export const fetchSimiliarJobs = (industry: string, unique_id: string, page: number, per_page: number) => {
  const data = {
    page: page,
    per_page: per_page
  }
  const params = encodeData(data);
  return authInstance.get(`/similar-jobs/${industry}/${unique_id}?${params}`);
}

export const getCityDescService = (city: string) => {
  return apiInstance.get(`/cities-seo-description/${city}`);
}

export const applyJobVisitedService = (data: object) => {
  return apiInstance.post(`/jobs/apply-visited`, data);
}

