import React, { lazy, Suspense} from 'react';
import {LoadOverlayFadingCircle} from '../components/Animations';

const loadableModal = (importFunc: () => Promise<{ default: React.ComponentType<any>; }>, { fallback = null } = { fallback: React.Component  }) => {
  const LazyComponent = lazy(importFunc);
  
  return (props: any) => (
    
    <Suspense fallback={<LoadOverlayFadingCircle />}>
         <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadableModal;
