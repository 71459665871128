import {all, put, fork, call, takeEvery, takeLatest, take, select} from 'redux-saga/effects';

import {
  JOBS_ERROR, 
  JOBS_REQUEST, 
  JOBS_SUCCESS, 
  GET_SINGLE_JOB_SUCCESS, 
  GET_SINGLE_JOB_ERROR, 
  GET_SINGLE_JOB, 
  CHECK_APPLICATION,
  CHECK_APPLICATION_SUCCESS,
  CHECK_APPLICATION_ERROR,
  JOB_APPLY,
  JOB_APPLY_ERROR,
  JOB_APPLY_SUCCESS,
  SAVE_JOB,
  SAVE_JOB_SUCCESS,
  SAVE_JOB_ERROR,
  GET_SIMILIAR_JOBS,
  GET_SIMILIAR_JOBS_SUCCESS,
  GET_SIMILIAR_JOBS_ERROR,
  JOBS_ITEMS_PER_PAGE,
  SIMILAR_JOBS_ITEMS_PER_PAGE,
  GET_CITY_DESC,
  GET_CITY_DESC_SUCCESS,
  GET_CITY_DESC_ERROR,
  GET_JOB_BY_URL_SLUG,
  JOB_APPLY_VISITED,
} from  './constants'

import {getAllJobs, getSingleJob, applyingForJob, fetchSimiliarJobs, checkApplications, saveJobService, getCityDescService, getJobByUrlSlug, applyJobVisitedService} from './services';
import { makeSelectJobItem } from './selectors';
import {setNotificationSuccess} from '../Notifications/actions';
import StoreService from '../../utils/StoreService';
import intl from '../../intl';
import generalMessages from '../generalMessages';
import queryString from 'query-string';
import TagManager from 'react-gtm-module';
import gtm from '../../config/gtm'
import { successfullApplyAction } from './actions';
import axios, { AxiosResponse } from 'axios';
import { JobItem } from '../../models/jobItem';


// const isAxiosError = (err: unknown): err is Error => err instanceof AxiosError;


function* jobsFlow(action: any) {
    const {searchParams, filter, page} = action;
    const searchObject = queryString.parse(searchParams);
   
    try {
      const response: AxiosResponse = yield call(getAllJobs, page, JOBS_ITEMS_PER_PAGE, filter, searchParams);

      const tagManagerArgs = {
        dataLayer: {
            searchObject,
            'event': gtm.events.adSearch,        
        },
      }
      TagManager.dataLayer(tagManagerArgs)

      yield put({type:JOBS_SUCCESS, response});

    } catch(error) {
        yield put({type:JOBS_ERROR, error})  
    }
}

// Apply For Job
function* applyForJob(data: any) {
  try {
    const response: AxiosResponse = yield call(applyingForJob, data);
    const job: JobItem = yield select(makeSelectJobItem);
    if(job) {
      const tagManagerArgs = {
        dataLayer: {
            'adId' : job.unique_id,
            'field': job.field_of_work,
            'positionLevel': job.position_level,
            'seniorityLevel': job.seniority,
            'event': gtm.events.adApply,        
        },
      }
      TagManager.dataLayer(tagManagerArgs);


      const tagManagerArgs1 = {
        dataLayer: {
          
            'event': gtm.events.purchase,
            'items' : [{
              'id': job.unique_id, 
              'google_business_vertical': 'jobs' 
            }]        
        },
      }
      TagManager.dataLayer(tagManagerArgs1);



    }
    
    yield put({type: JOB_APPLY_SUCCESS, response});
    yield put({type: CHECK_APPLICATION, id: data.job_unique_id});
    StoreService.dispatch(successfullApplyAction(true));
  } catch(error) {
    yield put({type: JOB_APPLY_ERROR, error})  
  }
}

// Apply Job Flow
function* applyJobFlow(): any {
  while (true) {
   const payload: any  = yield take([JOB_APPLY]);
   const {data} = payload
   yield call(applyForJob, data);
 }
}

// Get Job Details
function* getJobDetails(action: any) {
    const {id} = action;
    try {
      const response: AxiosResponse = yield call(getSingleJob, id);
      yield put({type: GET_SINGLE_JOB_SUCCESS, response})


    } catch (error) {
      yield put({type: GET_SINGLE_JOB_ERROR, error})
    }
}

function* getJobDetailsByUrlSlug(action: any) {
  const {slug} = action;
 
  try {
    const response: AxiosResponse = yield call(getJobByUrlSlug, slug);
    yield put({type: GET_SINGLE_JOB_SUCCESS, response})

    const tagManagerArgs = {
      dataLayer: {
          'event': gtm.events.viewItem,
          'items' : [{
            'id': response.data.unique_id, 
            'google_business_vertical': 'jobs' 
          }]        
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  } catch (err) {
     //const errors = err as Error | AxiosError;
     if(axios.isAxiosError(err) && err.response) {
      
      const {response} =  err;

      yield put({type: GET_SINGLE_JOB_ERROR, response})
     }     
    
    
  }
}

function* citySeoFlow(action: any) {
  const {city} = action;
  try {
    const response: AxiosResponse = yield call(getCityDescService, city);
    yield put({type: GET_CITY_DESC_SUCCESS, response})
  } catch (error) {
    yield put({type: GET_CITY_DESC_ERROR, error})
  }
}




// Get Job Details
function* checkingApplication(id: string) {
  try {
    const response: AxiosResponse = yield call(checkApplications, id);
    yield put({type: CHECK_APPLICATION_SUCCESS, response})
  } catch (error) {
    yield put({type: CHECK_APPLICATION_ERROR, error})
  }
}

function* checkingApplicationFlow(): any {
  while (true) {
   const payload  = yield take([CHECK_APPLICATION]);
   yield call(checkingApplication, payload.id);
 }
}

function* saveJob(action: any) {
  
  const job: JobItem = yield select(makeSelectJobItem);
  
  const data = {
    id: action.id,
    saved: action.saved
  }
  try {
    const response: AxiosResponse = yield call(saveJobService, data);
    yield put({type: SAVE_JOB_SUCCESS, response});   
    if(job) {
      const tagManagerArgs = {
        dataLayer: {
            'adId' : job.unique_id,
            'field': job.field_of_work,
            'positionLevel': job.position_level,
            'seniorityLevel': job.seniority,
            'event': gtm.events.adSaved,        
        },
      }
      TagManager.dataLayer(tagManagerArgs)
    }
    
    data.saved === true ? StoreService.dispatch(setNotificationSuccess(intl.formatMessage(generalMessages.BookmarkNotif))) : StoreService.dispatch(setNotificationSuccess(intl.formatMessage(generalMessages.UnbookmarkNotif)))
  } catch(error) {
    yield put({type: SAVE_JOB_ERROR, error}); 
  }
}


function* saveJobFlow(): any {
  while (true) {
   const payload  = yield take([SAVE_JOB]);   
   yield call(saveJob, payload);
 }
}



function* applyJobVisited(action: any) {
  const {unique_id} : {unique_id: string}  = action;
  try {
    const data: object = {
      unique_id: unique_id
    }
    yield call(applyJobVisitedService, data);
    // const response: AxiosResponse = yield call(fetchSimiliarJobs, industry, unique_id, page, SIMILAR_JOBS_ITEMS_PER_PAGE);
    // yield put({type: GET_SIMILIAR_JOBS_SUCCESS, response})
  } catch (error) {
    // yield put({type: GET_SIMILIAR_JOBS_ERROR, error})
  }
}


// Get Similiar Jobs
function* getAllSimiliarJobsFlow(action: any) {
  const {industry, unique_id, page} = action;
  try {
    const response: AxiosResponse = yield call(fetchSimiliarJobs, industry, unique_id, page, SIMILAR_JOBS_ITEMS_PER_PAGE);
    yield put({type: GET_SIMILIAR_JOBS_SUCCESS, response})
  } catch (error) {
    yield put({type: GET_SIMILIAR_JOBS_ERROR, error})
  }
}

export default function* jobsSaga() {
    yield all([
        takeLatest(JOB_APPLY_VISITED, applyJobVisited),
        takeLatest(JOBS_REQUEST, jobsFlow),   
        takeEvery(GET_CITY_DESC, citySeoFlow),   
        takeLatest(GET_SINGLE_JOB, getJobDetails),
        takeLatest(GET_JOB_BY_URL_SLUG, getJobDetailsByUrlSlug),
        takeLatest(GET_SIMILIAR_JOBS, getAllSimiliarJobsFlow),
        fork(checkingApplicationFlow),
        fork(applyJobFlow),
        fork(saveJobFlow),
    ])
}