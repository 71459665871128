
import { 
  MESSAGES_REQUEST, 
  DELETE_ITEM, 
  GET_JOBS, 
  SEND_MESSAGE_REQUEST, 
  REQUEST_CONVERSATION,
  DISPLAY_MODAL, 
  CONVERSATION_TYPES, 
  REQUEST_SEARCH_USERS,
  OPEN_CONVERSATION_WINDOW,
  SET_MESSAGES_READ,
  PRESIGNED_REQUEST_CHAT,
  REQUEST_CONVERSATION_CLEAR,
  REQUEST_CONVERSATION_NEW,
  CANCEL_UPLOAD
} from './constants';


export function getAllMessages(page?: number, unique_id?: string) {
  return {
    type: MESSAGES_REQUEST,
    page,
    unique_id
  };
}

export function deleteItem(unique_id: string) {
  return {
    type: DELETE_ITEM,
    unique_id
  }
}

export function closeModal() {
  return {
    type: DISPLAY_MODAL,
    status: false,
  }
}

export function openModal() {
  return {
    type: DISPLAY_MODAL,
    status: true,
  }
}

export function getUserJobs() {
  return {
    type: GET_JOBS
  }
}

export function sendMessage(data: any, disableSuccessMessage?: boolean, displayModal?: boolean) {
  return {
    type: SEND_MESSAGE_REQUEST,
    data,
    disableSuccessMessage,
    displayModal
  }
}

export function getConversation({id, recipientIds, senderId, entityType, entityId, threadId, file} : {id: string, recipientIds: string[], senderId: string, entityType: string, entityId: string | undefined, threadId: string, file?: boolean}, modal?: boolean, loadMore?: boolean) {
  return {
    type: REQUEST_CONVERSATION,
    payload : {
      id: id,
      recipientIds: recipientIds,
      senderId: senderId,
      entityType: entityType, 
      entityId: entityId,
      threadId: threadId,
      file: file,
    },
    modal: modal,
    loadMore: loadMore,
  }
}

export function getMessagesByJobIdAndRecipientId({jobId, recipientIds,entityType, entityId, threadId} : {jobId: string, recipientIds: string[],entityType: string, entityId: string | undefined, threadId?: string}, modal?: boolean) {
  return {
    type: REQUEST_CONVERSATION,
    payload : {
      jobId: jobId,
      recipientIds: recipientIds,
      entityType: entityType, 
      entityId: entityId,
      threadId: threadId
    },
    modal: modal,
  
  }
}

export function createNewConversation() {
  return {
    type: REQUEST_CONVERSATION,
    payload : {
      recipientIds: [],
      entityType: CONVERSATION_TYPES.directMessage,
    }
  }
}

export const getUsersAction = (search: string) => {
  return {
    type: REQUEST_SEARCH_USERS,
    search: search,
  }
}

export const openSmallConversationWindows = (open: boolean) => {
  return {
    type: OPEN_CONVERSATION_WINDOW,
    open,
  }
}

export const setMessagesReadAction = (data: any) => {
  return {
    type: SET_MESSAGES_READ,
    data
  }
}

export function getPreSignedUrlChat(file: File, fieldName: string, abortController: any ) {
  return {
    type: PRESIGNED_REQUEST_CHAT,
    file,
    fieldName,
    abortController,
  };
} 

export function clearConversation() {
  return {
    type: REQUEST_CONVERSATION_CLEAR,
    
  };
} 

export function newConversation() {
  return {
    type: REQUEST_CONVERSATION_NEW,
       
  };
}

export function cancleUpload() {
  return {
    type: CANCEL_UPLOAD,
       
  };
}


