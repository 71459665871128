
export const gtmId = process.env.REACT_APP_GTM_ID;
const gtm = {
   id: gtmId ? gtmId : 'GTM-TNF3N45',
   events : {
    registered: 'registered',
    loggedIn: 'loggedIn',
    accountVerified: 'accountVerified',
    formSubmitted: 'formSubmitted',
    adSearch: 'adSearch',
    adSaved: 'adSaved',
    addApply: 'adApply',
    eventCreated: 'eventCreated',
    adApply: 'adApply',
    eventApply :'eventApply',
    adCreated: 'adCreated',
    candidatePreselection: 'candidatePreselection',
    candidateSelection: 'candidateSelection',
    cookieConsentSet: 'cookieConsentSet',
    viewItem: 'view_item',
    addToCart: 'add_to_cart',
    purchase: 'purchase'
   } 
};

export default gtm;
