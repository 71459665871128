import { 
    SEARCH_USERS,
    ASSIGN_TASK,
    GET_TASKS,
    COMPLETE_TASK,
    APPROVE_ENTITY,
    GET_TASK,
    TASK_GROUP,
    SIDEBAR,
    DELETE_TASK,
    SIDEBAR_CREATE_PRICE,
    GET_APPLICANTS,
    CREATE_PRICE,
    JOB_COMMENT
  } from './constants';

// Get User
export function getUsersAction(role: string, name: string) {
    return {
      type: SEARCH_USERS,
      role,
      name
    }
  }
  
// Assign
export function assignUserTaskAction(data: object, redirect: boolean) {
    return {
        type: ASSIGN_TASK,
        data,
        redirect
    }
}

// Get Tasks
export function getTasksAction(page: number, kind?: string, role?: string, filter?: string) {
    return {
      type: GET_TASKS,
      kind,
      role,
      filter,
      page
    }
}

// Complete Task
export function completeTask(data: object, id: string) {
    return {
        type: COMPLETE_TASK,
        data,
        id
    }
}

// Approve Job
export function approveEntity(id: string, data: object, model: string) {
  return {
    type: APPROVE_ENTITY,
    id,
    data,
    model
  }
}

// Get Single Task For Admin
export function getTask(id: string) {
  return {
    type: GET_TASK,
    id
  }
}

// Group Task
export function getGroupTask() {
  return {
    type: TASK_GROUP,
  }
}

// SidebarAction
export function sidbearAction(open: boolean) {
  return {
    type: SIDEBAR,
    open
  }
}

// Delete Task Action
export function deleteTaskAction(data: any) {
  return {
    type: DELETE_TASK,
    data
  }
}

// SidebarAction
export function sidebarCreatePriceAction(open: boolean) {
  return {
    type: SIDEBAR_CREATE_PRICE,
    open
  }
}

export function getApplicationsAction(id: string, page: number) {
  return {
    type: GET_APPLICANTS,
    id,
    page
  }
}

export function createPriceAction(data: any) {
  return {
    type: CREATE_PRICE,
    data
  }
}

export function getJobComment(id: string) {
  return {
    type: JOB_COMMENT,
    id
  }
}

