
import React, {FC, memo} from 'react';
import {Link} from "react-router-dom";
import routes from '../../../config/routes';
import { FormattedMessage } from 'react-intl';
import generalMessages from '../../generalMessages';
import HeaderDropdown from '../../../components/Header/HeaderDropdown';

interface IMyProfileDropDownInner  {
  toggleUserSettings: (event: any) => void,
  logoutAction: () => void,
  openUser : boolean,
  roles: any,
  userInfo: any,
}

const MyProfileDropDownInner: FC<IMyProfileDropDownInner> = ({openUser, logoutAction, toggleUserSettings, roles, userInfo}) => {

  return (
    <>
       <Link to={userInfo.role === roles.USER ? routes.RequestedServices  : userInfo.role === roles.BUSINESS_USER ? routes.DashboardPage : '/dashboard/tasks'  }>{userInfo.first_name} {userInfo.last_name}</Link>
      <HeaderDropdown className={openUser ? "dropdownOpen" : ''}  onClick={toggleUserSettings}  tabIndex={0} onBlurCapture={toggleUserSettings}  >
    
      <span  > <svg className="dropdown-arrow"><use xlinkHref={`#arrow-down`} /></svg></span>
      <ul  >
        <li><Link to={routes.UserInfo}><svg><use xlinkHref={`#settings`} /></svg> Moj profil</Link></li>
        <li><Link to={"#"} onClick={logoutAction}><svg><use xlinkHref={`#logout`} /></svg> <FormattedMessage {...generalMessages.Logout} /></Link></li>
      </ul>
    </HeaderDropdown>
  </>
  )
}





export default memo(MyProfileDropDownInner);