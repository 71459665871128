import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../../config/colors';

const printSpans = (numberOfRows: number) => {
  let content = [];
  for (var i = 0; i < numberOfRows; i++) {
    content.push(<span  key={i}></span>);
  }
  return content
}

const LoadFadingCircle: FC = () => {
  return (
    <LoadFadingCircleWrapper>
     {
        printSpans(12)
     }  
    </LoadFadingCircleWrapper>
  )
}

const LoadFadingCircleButton: FC = () => {
    return (
      <LoadFadingCircleButtonWrapper className="loader">
        <label>
        {
          printSpans(12)
        }  
        </label>
      </LoadFadingCircleButtonWrapper>
    )
  }

  const LoadOverlayFadingCircle: FC = () => {
    return (
      <LoadOverlayFadingCircleWrapper className="loadOverlayAnimation">
        <div>
        {
          printSpans(12)
        }  
         </div>
      </LoadOverlayFadingCircleWrapper>
    )
  }

  const LoadOverlaySticky: FC = () => {
    return (
      <LoadOverlayStickyWrapper>
        <div>
        {
          printSpans(9)
        }  
        </div>
      </LoadOverlayStickyWrapper>
    )
  }
  const LoadComponent: FC = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 100)
      return () => {
        clearTimeout(timeout)
      }
    }, [])

    return (
      <div>
        {show &&  <span></span>}
      </div>
  )
  }

const LoadFadingCircleWrapper = styled.label`
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;

  span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: ${colors.WHITE_COLOR};
      border-radius: 100%;
      -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }

    &:nth-child(2) {
      -webkit-transform: rotate(30deg);
          -ms-transform: rotate(30deg);
              transform: rotate(30deg);

      &:before {
        -webkit-animation-delay: -1.1s;
                animation-delay: -1.1s;
      }
    }

    &:nth-child(3) {
      -webkit-transform: rotate(60deg);
          -ms-transform: rotate(60deg);
              transform: rotate(60deg);

      &:before {
        -webkit-animation-delay: -1s;
                animation-delay: -1s;
      }
    }

    &:nth-child(4) {
      -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
              transform: rotate(90deg);

      &:before {
        -webkit-animation-delay: -0.9s;
                animation-delay: -0.9s;
      }
    }

    &:nth-child(5) {
      -webkit-transform: rotate(120deg);
          -ms-transform: rotate(120deg);
              transform: rotate(120deg);

      &:before {
        -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s;
      }
    }

    &:nth-child(6) {
      -webkit-transform: rotate(150deg);
          -ms-transform: rotate(150deg);
              transform: rotate(150deg);

      &:before {
        -webkit-animation-delay: -0.7s;
                animation-delay: -0.7s;
      }
    }

    &:nth-child(7) {
      -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
              transform: rotate(180deg);

      &:before {
        -webkit-animation-delay: -0.6s;
                animation-delay: -0.6s;
      }
    }

    &:nth-child(8) {
      -webkit-transform: rotate(210deg);
          -ms-transform: rotate(210deg);
              transform: rotate(210deg);

      &:before {
        -webkit-animation-delay: -0.5s;
                animation-delay: -0.5s;
      }
    }

    &:nth-child(9) {
      -webkit-transform: rotate(240deg);
          -ms-transform: rotate(240deg);
              transform: rotate(240deg);

      &:before {
        -webkit-animation-delay: -0.4s;
                animation-delay: -0.4s;
      }
    }

    &:nth-child(10) {
      -webkit-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
              transform: rotate(270deg);

      &:before {
        -webkit-animation-delay: -0.3s;
                animation-delay: -0.3s;
      }
    }

    &:nth-child(11) {
      -webkit-transform: rotate(300deg);
          -ms-transform: rotate(300deg);
              transform: rotate(300deg);

      &:before {
        -webkit-animation-delay: -0.2s;
                animation-delay: -0.2s;
      }
    }

    &:nth-child(12) {
      -webkit-transform: rotate(330deg);
          -ms-transform: rotate(330deg);
              transform: rotate(330deg);

      &:before {
        -webkit-animation-delay: -0.1s;
                animation-delay: -0.1s;
      }
    }
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }

  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
`;

const LoadFadingCircleButtonWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    width: 26px;
    height: 26px;
    position: relative;
    display: inline-block;

    span {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: ${colors.TEXT_BLACK};
        border-radius: 100%;
        -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
        animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(30deg);
            -ms-transform: rotate(30deg);
                transform: rotate(30deg);

        &:before {
          -webkit-animation-delay: -1.1s;
                  animation-delay: -1.1s;
        }
      }

      &:nth-child(3) {
        -webkit-transform: rotate(60deg);
            -ms-transform: rotate(60deg);
                transform: rotate(60deg);

        &:before {
          -webkit-animation-delay: -1s;
                  animation-delay: -1s;
        }
      }

      &:nth-child(4) {
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
                transform: rotate(90deg);

        &:before {
          -webkit-animation-delay: -0.9s;
                  animation-delay: -0.9s;
        }
      }

      &:nth-child(5) {
        -webkit-transform: rotate(120deg);
            -ms-transform: rotate(120deg);
                transform: rotate(120deg);

        &:before {
          -webkit-animation-delay: -0.8s;
                  animation-delay: -0.8s;
        }
      }

      &:nth-child(6) {
        -webkit-transform: rotate(150deg);
            -ms-transform: rotate(150deg);
                transform: rotate(150deg);

        &:before {
          -webkit-animation-delay: -0.7s;
                  animation-delay: -0.7s;
        }
      }

      &:nth-child(7) {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg);

        &:before {
          -webkit-animation-delay: -0.6s;
                  animation-delay: -0.6s;
        }
      }

      &:nth-child(8) {
        -webkit-transform: rotate(210deg);
            -ms-transform: rotate(210deg);
                transform: rotate(210deg);

        &:before {
          -webkit-animation-delay: -0.5s;
                  animation-delay: -0.5s;
        }
      }

      &:nth-child(9) {
        -webkit-transform: rotate(240deg);
            -ms-transform: rotate(240deg);
                transform: rotate(240deg);

        &:before {
          -webkit-animation-delay: -0.4s;
                  animation-delay: -0.4s;
        }
      }

      &:nth-child(10) {
        -webkit-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
                transform: rotate(270deg);

        &:before {
          -webkit-animation-delay: -0.3s;
                  animation-delay: -0.3s;
        }
      }

      &:nth-child(11) {
        -webkit-transform: rotate(300deg);
            -ms-transform: rotate(300deg);
                transform: rotate(300deg);

        &:before {
          -webkit-animation-delay: -0.2s;
                  animation-delay: -0.2s;
        }
      }

      &:nth-child(12) {
        -webkit-transform: rotate(330deg);
            -ms-transform: rotate(330deg);
                transform: rotate(330deg);

        &:before {
          -webkit-animation-delay: -0.1s;
                  animation-delay: -0.1s;
        }
      }
    }

    @-webkit-keyframes sk-circleFadeDelay {
      0%, 39%, 100% { opacity: 0; }
      40% { opacity: 1; }
    }

    @keyframes sk-circleFadeDelay {
      0%, 39%, 100% { opacity: 0; }
      40% { opacity: 1; }
    }
  }
`;

const LoadOverlayFadingCircleWrapper = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 40px;
    height: 40px;
    position: relative;
  }

  span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: ${colors.TEXT_BLACK};
      border-radius: 100%;
      -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    }

    &:nth-child(2) {
      -webkit-transform: rotate(30deg);
          -ms-transform: rotate(30deg);
              transform: rotate(30deg);

      &:before {
        -webkit-animation-delay: -1.1s;
                animation-delay: -1.1s;
      }
    }

    &:nth-child(3) {
      -webkit-transform: rotate(60deg);
          -ms-transform: rotate(60deg);
              transform: rotate(60deg);

      &:before {
        -webkit-animation-delay: -1s;
                animation-delay: -1s;
      }
    }

    &:nth-child(4) {
      -webkit-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
              transform: rotate(90deg);

      &:before {
        -webkit-animation-delay: -0.9s;
                animation-delay: -0.9s;
      }
    }

    &:nth-child(5) {
      -webkit-transform: rotate(120deg);
          -ms-transform: rotate(120deg);
              transform: rotate(120deg);

      &:before {
        -webkit-animation-delay: -0.8s;
                animation-delay: -0.8s;
      }
    }

    &:nth-child(6) {
      -webkit-transform: rotate(150deg);
          -ms-transform: rotate(150deg);
              transform: rotate(150deg);

      &:before {
        -webkit-animation-delay: -0.7s;
                animation-delay: -0.7s;
      }
    }

    &:nth-child(7) {
      -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
              transform: rotate(180deg);

      &:before {
        -webkit-animation-delay: -0.6s;
                animation-delay: -0.6s;
      }
    }

    &:nth-child(8) {
      -webkit-transform: rotate(210deg);
          -ms-transform: rotate(210deg);
              transform: rotate(210deg);

      &:before {
        -webkit-animation-delay: -0.5s;
                animation-delay: -0.5s;
      }
    }

    &:nth-child(9) {
      -webkit-transform: rotate(240deg);
          -ms-transform: rotate(240deg);
              transform: rotate(240deg);

      &:before {
        -webkit-animation-delay: -0.4s;
                animation-delay: -0.4s;
      }
    }

    &:nth-child(10) {
      -webkit-transform: rotate(270deg);
          -ms-transform: rotate(270deg);
              transform: rotate(270deg);

      &:before {
        -webkit-animation-delay: -0.3s;
                animation-delay: -0.3s;
      }
    }

    &:nth-child(11) {
      -webkit-transform: rotate(300deg);
          -ms-transform: rotate(300deg);
              transform: rotate(300deg);

      &:before {
        -webkit-animation-delay: -0.2s;
                animation-delay: -0.2s;
      }
    }

    &:nth-child(12) {
      -webkit-transform: rotate(330deg);
          -ms-transform: rotate(330deg);
              transform: rotate(330deg);

      &:before {
        -webkit-animation-delay: -0.1s;
                animation-delay: -0.1s;
      }
    }
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }

  @keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
  }
`;

const LoadOverlayStickyWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;

  div {
    width: 66px;
    height: 66px;
    margin: 180px auto 0;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 30px;

    &:after {
      content: "";
      width: 120px;
      height: 120px;
      background: ${colors.WHITE};
      border-radius: 50%;
      box-shadow: 0px 0px 20px rgba(93,93,93,0.06);
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -60px 0 0 -60px;
      z-index: -1;
    }

    span {
      width: 18px;
      height: 18px;
      margin: 2px;
      border-radius: 50%;
      background-color: ${colors.PRIMARY_ORANGE};
      float: left;
      -webkit-animation: sk-cubeGridScaleDelay 0.3s infinite ease-in-out both;
      animation: sk-cubeGridScaleDelay 0.3s infinite ease-in-out both;

      &:first-child {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
      }

      &:nth-child(2) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
      }

      &:nth-child(4) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
      }

      &:nth-child(5) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }

      &:nth-child(6) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
      }

      &:nth-child(7) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
      }

      &:nth-child(8) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }

      &:nth-child(9) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
      }
    }
  }

  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
    }
  }

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
            background: ${colors.BACKGROUND_COLOR};
  }
}
`;

// Blink Animation
const Blink = styled.span`
  text-decoration: blink;
	animation-name: blinker;
	animation-duration: 0.6s;
	animation-iteration-count:infinite;
	animation-timing-function:ease-in-out;
	animation-direction: alternate;

  @keyframes blinker {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
   }
`;

// FadeIn Animation
const FadeIn = styled.div`
  animation-duration: 100ms;
  animation-timing-function: ease;
  animation-name: fadeIn;

  @keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
      }
  }
`;

export {
  LoadFadingCircleButton,
  LoadFadingCircle,
  LoadOverlayFadingCircle,
  LoadOverlaySticky,
  Blink,
  FadeIn,
  LoadComponent
};
