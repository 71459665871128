/**
 * App selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectApp = (state: any) => state.app || initialState;
const selectRouter = (state: any) => state.router;


const makeSelectUserInfo = createSelector(
  selectApp,
  appState => appState.userInfo,
);

const makeSelectAvatarSelector = createSelector(
  selectApp,
  appState => appState.userInfo.img_url
)

const makeSelectUserRole = createSelector(
  selectApp,
  appState => appState.userInfo.role,
);

const makeSelectToken = createSelector(
  selectApp,
  appState => appState.token,
);

const makeSelectLoading = createSelector(
  selectApp,
  appState => appState.loading,
);

const makeSelectLoadingLogout = createSelector(
  selectApp,
  appState => appState.loadingLogout,
);
const makeSelectLoadingUserInfo = createSelector(
  selectApp,
  appState => appState.loadingUserInfo,
);


const makeSelectisAuthenticated = createSelector(
  selectApp,
  appState => appState.isAuthenticated,
);
const makeSelectRefreshTokenLoading = createSelector(
  selectApp,
  appState => appState.refreshToken,
);

const makeSelectFeaturedJobs = createSelector(
  selectApp,
  appState => appState.featuredJobs,
);

const makeSelectFeaturedJobsLoading = createSelector(
  selectApp,
  appState => appState.featuredJobsLoading,
);

const makeSelectUpcomingEvents = createSelector(
  selectApp,
  appState => appState.upcominngEvents,
);

const makeSelectCountUnreadMessages = createSelector(
  selectApp,
  appState => appState.countUnreadMessages,
);

const makeSelectNumberNotifications = createSelector(
  selectApp,
  appState => appState.notificationsNumber,
);

const makeSelectMostReadNews = createSelector(
  selectApp,
  appState => appState.mostReadNews,
);

const makeSelectLatestNews = createSelector(
  selectApp,
  appState => appState.recentNews,
);

const makeSelectRecentNewsPageCount = createSelector(
  selectApp,
  appState => appState.recentNewsPageCount,
)

const makeSelectRecentNewsCurrentPage = createSelector(
  selectApp,
  appState => appState.recentNewsCurrentPage,
);

const makeSelectEventsPageCount = createSelector(
  selectApp,
  appState => appState.eventsPageCount,
)

const makeSelectEventsCurrentPage = createSelector(
  selectApp,
  appState => appState.eventsCurrentPage,
);

const makeSelectClientsPageCount = createSelector(
  selectApp,
  appState => appState.clientsPageCount,
)

const makeSelectClientsCurrentPage = createSelector(
  selectApp,
  appState => appState.clientsCurrentPage,
);

const featuredPageCount = createSelector(
  selectApp,
  appState => appState.featuredPageCount,
)

const featuredCurrentPage = createSelector(
  selectApp,
  appState => appState.featuredCurrentPage,
);

const makeSelectLocation = () =>
  createSelector(
    selectRouter,
    routerState => routerState,
);

const makeUpcomingEventsLoading = createSelector(
  selectApp,
  appState => appState.upcominngEventsLoading,
);

const makeLatestNewsLoading = createSelector(
  selectApp,
  appState => appState.makeLatestNewsLoading,
);

const makeSelectPackages = createSelector(
  selectApp,
  appState => appState.packages,
);

const makeSelectPackagesPublic = createSelector(
  selectApp,
  appState => appState.packagesPublic,
);

const makeSelectPackagesJob = createSelector(
  selectApp,
  appState => appState.packagesJob,
);

const makeSelectLoadingPackages = createSelector(
  selectApp,
  appState => appState.loadingPackages,
);

// Categories
const makeSelectCategories = createSelector(
  selectApp,
  appState => appState.selectCategories,
);

// Categories
const makeSelectSpecialCategories = createSelector(
  selectApp,
  appState => appState.selectSpecialCategoriesFilter,
);

// Categories Filter
const makeSelectCategoriesIndustry = createSelector(
  selectApp,
  appState => appState.selectCategoriesFilter.industry,
);

const makeSelectCategoriesAreaOfWork = createSelector(
  selectApp,
  appState => appState.selectCategoriesFilter.areaOfWork,
);


const cloudNoteStatusSelector = createSelector(
  selectApp,
  appState => appState.cloudNoteModalStatus,
);

const cloudNoteTitleSelector = createSelector(
  selectApp,
  appState => appState.cloudNoteTitle,
);

const cloudNoteDescriptionSelector = createSelector(
  selectApp,
  appState => appState.cloudNoteDescription,
);

const openThreadsSidebarSelector = createSelector(
  selectApp,
  appState => appState.openThreadsSidebar
);

const loaderContactUsSelector = createSelector(
  selectApp,
  appState => appState.loaderContactUs
);

const makeSelectOurHomeClients = createSelector(
  selectApp,
  appState => appState.ourClientsHome,
);

const loaderOurClientsSelector = createSelector(
  selectApp,
  appState => appState.loaderOurClients
);

const optionsCitiesSelector = createSelector(
  selectApp,
  appState => appState.optionsCities,
);

export {
  makeSelectUserInfo,
  makeSelectLoading,
  makeSelectToken,
  makeSelectRefreshTokenLoading,
  makeSelectisAuthenticated,makeSelectUserRole,
  makeSelectFeaturedJobs,
  makeSelectFeaturedJobsLoading,
  makeSelectCountUnreadMessages,
  makeSelectUpcomingEvents,
  makeSelectMostReadNews,
  makeSelectLatestNews,
  makeUpcomingEventsLoading,
  makeLatestNewsLoading,
  makeSelectLocation,
  makeSelectAvatarSelector,
  makeSelectLoadingLogout,
  makeSelectPackages,
  makeSelectLoadingPackages,
  featuredCurrentPage,
  featuredPageCount,
  makeSelectPackagesPublic,
  makeSelectCategories,
  makeSelectCategoriesIndustry,
  makeSelectCategoriesAreaOfWork,
  makeSelectPackagesJob,
  cloudNoteStatusSelector,
  cloudNoteTitleSelector,
  cloudNoteDescriptionSelector,
  makeSelectNumberNotifications,
  openThreadsSidebarSelector,
  loaderContactUsSelector,
  makeSelectLoadingUserInfo,
  makeSelectSpecialCategories,
  makeSelectOurHomeClients,
  optionsCitiesSelector,
  makeSelectRecentNewsPageCount,
  makeSelectRecentNewsCurrentPage,
  makeSelectEventsPageCount,
  makeSelectEventsCurrentPage,
  makeSelectClientsPageCount,
  makeSelectClientsCurrentPage,
  loaderOurClientsSelector
};
