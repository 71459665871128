import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGIN_RESET,
  RESEND_VERIFICATION_EMAIL_REQUEST,
  RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS,
  RESEND_VERIFICATION_EMAIL_REQUEST_ERROR,
  RESEND_VERIFICATION_CODE_REQUEST,
  RESEND_VERIFICATION_CODE_REQUEST_SUCCESS,
  RESEND_VERIFICATION_CODE_REQUEST_ERROR,
  LOGIN_VERIFY_PHONE,
  LOGIN_VERIFY_PHONE_SUCCESS,
  LOGIN_VERIFY_PHONE_ERROR,
} from './constants';

export interface loginState {
  loading: boolean;
  loadingLogout: boolean;
  error: any;
  username: string;
  password: string;
  verifyEmailError: boolean;
  verifyPhoneNumberError: boolean;
}

export const initialState: loginState = {
  loading: false,
  loadingLogout: false,
  error: null,
  username: '',
  password: '',
  verifyEmailError: false,
  verifyPhoneNumberError: false,
};

const loginReducer = (state = initialState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case LOGIN_REQUEST:
        draft.loading = true;
        draft.error = null;
        break;
      case LOGIN_SUCCESS:
        draft.loading = false;
        draft.error = null;
        draft.verifyEmailError= false;
        draft.verifyPhoneNumberError  = false;
        break;
      case LOGIN_ERROR:
        draft.loading = false;
        draft.error = action.error.response ? action.error.response.message : 0;
        draft.verifyEmailError = action.error.response?.data?.type === 'email' ? true : false
        draft.verifyPhoneNumberError = action.error.response?.data?.type === 'phone' ? true : false
        break;
      case LOGOUT_REQUEST:
        draft.loading = false;
        draft.loadingLogout = true;
        draft.error = null;
        draft.verifyEmailError = false;
        draft.verifyPhoneNumberError  = false;
        break;
      case LOGOUT_SUCCESS:
        draft.loading = false;
        draft.loadingLogout = false;
        draft.error = null;
        draft.verifyEmailError= false;
        draft.verifyPhoneNumberError = false;
        break;
      case LOGIN_RESET:
        draft.loading = false;
        draft.loadingLogout= false;
        draft.error = null;
        draft.username = '';
        draft.password = '';
        draft.verifyEmailError = false;
        draft.verifyPhoneNumberError = false;
        break;
      case RESEND_VERIFICATION_EMAIL_REQUEST:
        draft.loading = true;
        break;
      case RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS:
        draft.loading = false;
        draft.verifyEmailError = false;
        break;
      case RESEND_VERIFICATION_EMAIL_REQUEST_ERROR:
        draft.loading = false;
        draft.verifyEmailError = false;
        break;
      case RESEND_VERIFICATION_CODE_REQUEST:
        draft.loading = true;
        break;
      case RESEND_VERIFICATION_CODE_REQUEST_SUCCESS:
        draft.loading = false;
        draft.verifyPhoneNumberError = true;
        break;
      case RESEND_VERIFICATION_CODE_REQUEST_ERROR:
        draft.loading = false;
        draft.verifyPhoneNumberError = true;
        break;
      case LOGIN_VERIFY_PHONE:
        draft.loading = true;
        break;
      case LOGIN_VERIFY_PHONE_SUCCESS:
        draft.error = null;
        draft.verifyPhoneNumberError = true;
        break;
      case LOGIN_VERIFY_PHONE_ERROR:
        draft.loading = true;
        break;
    }
});

export default loginReducer;
