const scope = 'loop/Transactions';

export const GET_TRANSACTIONS = `${scope}/GET_TRANSACTIONS`;
export const GET_TRANSACTIONS_SUCCESS = `${scope}/GET_TRANSACTIONS_SUCCESS`;
export const GET_TRANSACTIONS_ERROR = `${scope}/GET_TRANSACTIONS_ERROR`;

export const GET_BUSINESS_TRANSACTIONS = `${scope}/GET_BUSINESS_TRANSACTIONS`;
export const GET_BUSINESS_TRANSACTIONS_SUCCESS = `${scope}/GET_BUSINESS_TRANSACTIONS_SUCCESS`;
export const GET_BUSINESS_TRANSACTIONS_ERROR = `${scope}/GET_BUSINESS_TRANSACTIONS_ERROR`;

export const BOOK_TRANSACTION = `${scope}/BOOK_TRANSACTION`;
export const BOOK_TRANSACTION_SUCCESS = `${scope}/BOOK_TRANSACTION_SUCCESS`;
export const BOOK_TRANSACTION_ERROR = `${scope}/BOOK_TRANSACTION_ERROR`;

export const BOOK_BEFORE_TRANSACTION = `${scope}/BOOK_BEFORE_TRANSACTION`;
export const BOOK_BEFORE_TRANSACTION_SUCCESS = `${scope}/BOOK_BEFORE_TRANSACTION_SUCCESS`;
export const BOOK_BEFORE_TRANSACTION_ERROR = `${scope}/BOOK_BEFORE_TRANSACTION_ERROR`;

export const GET_TRANSACTION = `${scope}/GET_TRANSACTION`;
export const GET_TRANSACTION_SUCCESS = `${scope}/GET_TRANSACTION_SUCCESS`;
export const GET_TRANSACTION_ERROR = `${scope}/GET_TRANSACTION_ERROR`;

// Per Page
export const TRANSACTIONS_PER_PAGE = 15;

export const TRANSACTION_SIDEBAR = `${scope}/TRANSACTION_SIDEBAR`;

export const TRANSACTION_UNDER_COUNSTRUCTION = `${scope}/TRANSACTION_UNDER_COUNSTRUCTION`;
