export interface Page {
  type: 'Page',
  section: any
}

export const viewSections : Page  = {
    type: 'Page',
    section: {
      dashboard : {
        savedJobs : 'Saved Jobs',
        companyInfo : 'Company Information',
        candidateApplications: 'Candidate Applications',
        userInformation : 'User Information',
        accountInformation : 'Account Information',
        preSelection : 'Pre-selection',
        selection : 'Selection',
        content : 'Content',
        statistics: 'Statistics',
        users: 'users',
        approveUser: 'Approve User',
        messages: 'Messages',
        messagesNew : 'New Message',
        regularUserInformation: 'Regular User Information',
        businessUserInformation: 'Business User Information',
        regularRequestedServices: 'Regular Requested Services',
        businessRequestedServices: 'Business Requested Services',
        requestedServices: 'Requested Services',
        createTemplate: 'Create Template',
        tasks: 'Tasks',
        notifications: 'Notifications',
        payment: 'Payment',
        transactions: 'Transactions',
        myTransactions: 'My Transactions',
        ourClients: 'Our Clients',
        taskServiceHistory: 'Task History',
        createFAQs: 'Create FAQs',
        jobCreate: 'Create New job',
        archiveCandidate: 'Archive Candidate',
        createCategories: 'Create Categories',
        uploads: 'Upload CV/Resume',
        cta: 'CTA',
        documents: 'Documents',
        coupons: "Coupons",
        businessCoupons: "Business coupons",
        jobs: "Jobs",
        adminCTA: 'Admin CTA',
        bid: 'Bid',
        favouriteJobs: 'Favourite Jobs',
        choosenAdCategories: 'Choosen Ad Categories',
        news: 'News list',
        events: 'Event list'
      },
      jobs : {
        jobButtonShare: 'Job Button Share',
        jobButtonSaveAd: 'Job Button Save Ad',
        jobTabsApply: 'Job Tabs Apply',
        hrButton: 'Hr Button',
        jobAdditionalLink: 'Job Additional Link',
        setAdLink: 'Set Ad Link',
        jobCreate: 'Job Create',
        jobEdit: 'Job Edit',
        jobCreateHr: 'Job Create Hr',
        jobTemplateEdit: 'Job Template Edit',
        bookmark: 'Bookmark'
      },
      events: {
        eventCreate: 'Event Create',
        eventEdit: 'Event Edit',
        eventReview: 'Event Review',
        eventAdminEdit: 'Event Admin Edit',
      },
      news: {
        newsCreate: 'News Create',
        newsEdit: 'News Edit',
      },
      preselection: {
        jobList: 'Job List',
        chooseOption: 'Choose Option',
        chooseInterview: 'Choose Interview',
        previewJob: 'Preview Job',
        addComment: 'Add Comment',
        reviewJob: 'Review Job',
        candidateList: 'Candidate List',
      },
      sideBarMenu : {
        show: 'Show',
        business: 'Business menu',
        regular: 'Regualar menu'
      },
      logout: {
        admin: 'Admin logout'
      },
      footer : {
        business: 'Business footer',
        regular: 'Regualar footer'
      },
      header : {
        regular: 'Regualar header'
      },
      ourOffer : {
        priceListCTA: 'priceListCTA',
        jobCTA: 'JobCTA',
        homeSection: 'Home Section'
      },
      chatBot: 'ChatBot',
      transaction : {
        activateBeforeBooked: 'Activate before booked'
      },
      admin : {
        email_settings: 'Admin email settings',
        verifyUser: 'Verufy User'
      },
      ourServices: {
        chooseServiceButton: 'Choose Service Button'
      }
  }
}
