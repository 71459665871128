import authInstance from '../../utils/axios/authInstance';


export const registerBusinessUserService = (data: object) => {
  return authInstance.post('/business-user', data);
}

export const registerRegularUserService = (data: object) => {
  return authInstance.post('/regular-user', data);
}

// Cities
export const getCitiesService = (city: string, country: string) => {
  return authInstance.get(`/get-cities/${city}?country=${country}`);
}

// Verify phone number service
export const verifyPhoneNumberService = (data: object) => {
  return authInstance.post('/verify-account', data);
}

// Resend code service
export const resendCodeService = (phone: string) => {
  return authInstance.get(`/phone-verification-resend/${phone}`);
}

export const emailVerifyService = (id: number, token: string) => {
  return authInstance.get(`/email/verify/${id}/${token}`);
}