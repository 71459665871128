const scope = 'loop/Jobs';

export const JOBS_REQUEST = `${scope}/JOBS_REQUEST`;
export const JOBS_SUCCESS = `${scope}/JOBS_SUCCESS`;
export const JOBS_ERROR = `${scope}/JOBS_ERROR`;

// Const For Single Job
export const GET_SINGLE_JOB = `${scope}/GET_SINGLE_JOB`;
export const GET_SINGLE_JOB_SUCCESS = `${scope}/GET_SINGLE_JOB_SUCCESS`;
export const GET_SINGLE_JOB_ERROR = `${scope}/GET_SINGLE_JOB_ERROR`;

// Applying For Position
export const JOB_APPLY = `${scope}/JOB_APPLY`;
export const JOB_APPLY_SUCCESS = `${scope}/JOB_APPLY_SUCCESS`;
export const JOB_APPLY_ERROR = `${scope}/JOB_APPLY_ERROR`;

// Applications
export const CHECK_APPLICATION = `${scope}/CHECK_APPLICATION`;
export const CHECK_APPLICATION_SUCCESS = `${scope}/CHECK_APPLICATION_SUCCESS`;
export const CHECK_APPLICATION_ERROR = `${scope}/CHECK_APPLICATION_ERROR`;

export const SAVE_JOB = `${scope}/SAVE_JOB`;
export const SAVE_JOB_SUCCESS = `${scope}/SAVE_JOB_SUCCESS`;
export const SAVE_JOB_ERROR = `${scope}/SAVE_JOB_ERROR`;

// Const Get Similiar Jobs
export const GET_SIMILIAR_JOBS = `${scope}/GET_SIMILIAR_JOBS`;
export const GET_SIMILIAR_JOBS_SUCCESS = `${scope}/GET_SIMILIAR_JOBS_SUCCESS`;
export const GET_SIMILIAR_JOBS_ERROR = `${scope}/GET_SIMILIAR_JOBS_ERROR`;


export const GET_CITY_DESC = `${scope}/GET_CITY_DESC`;
export const GET_CITY_DESC_SUCCESS = `${scope}/GET_CITY_DESC_SUCCESS`;
export const GET_CITY_DESC_ERROR = `${scope}/GET_CITY_DESC_ERROR`;

export const GET_JOB_BY_URL_SLUG = `${scope}/GET_JOB_BY_URL_SLUG`;

// Success Modal
export const APPLY_MODAL = `${scope}/APPLY_MODAL`;
export const IS_USER_LOGGED_IN_MODAL = `${scope}/IS_USER_LOGGED_IN_MODAL`;

// Per Page
export const JOBS_ITEMS_PER_PAGE = 10;
export const SIMILAR_JOBS_ITEMS_PER_PAGE = 4;

export const JOB_APPLY_VISITED = `${scope}/JOB_APPLY_VISITED`;