import {Link} from "react-router-dom";
import styled from 'styled-components';
import colors from '../../config/colors';

const theme: any = {
  primary: {
    background: `${colors.PRIMARY_ORANGE}`,
    border_color: `${colors.PRIMARY_ORANGE}`,
    hover_color: `${colors.PRIMARY_ORANGE}`,
    fill_hover: `${colors.PRIMARY_ORANGE}`,
    hover_bg: `${colors.WHITE_COLOR}`,
    color: `${colors.WHITE}`
  },
  primary_lg: {
    background: `${colors.PRIMARY_ORANGE}`,
    border_color: `${colors.PRIMARY_ORANGE}`,
    hover_color: `${colors.PRIMARY_ORANGE}`,
    fill_hover: `${colors.PRIMARY_ORANGE}`,
    hover_bg: `${colors.WHITE_COLOR}`,
    color: `${colors.WHITE}`
  },
  primary_light: {
    background: `${colors.PRIMARY_ORANGE_LIGHT}`,
    border_color: `${colors.PRIMARY_ORANGE_LIGHT}`,
    hover_color: `${colors.PRIMARY_ORANGE}`,
    fill_hover: `${colors.PRIMARY_ORANGE}`,
    hover_bg: `${colors.WHITE_COLOR}`,
    color: `${colors.PRIMARY_ORANGE}`
  },
  red: {
    background: `${colors.PRIMARY_RED}`,
    border_color: `${colors.PRIMARY_RED}`,
    hover_color: `${colors.PRIMARY_RED}`,
    fill_hover: `${colors.PRIMARY_RED}`,
    hover_bg: `${colors.WHITE}`,
    color: `${colors.WHITE}`
  },
  purple: {
    background: `${colors.PRIMARY_PURPLE}`,
    border_color: `${colors.PRIMARY_PURPLE}`,
    hover_color: `${colors.PRIMARY_PURPLE}`,
    fill_hover: `${colors.PRIMARY_PURPLE}`,
    hover_bg: `${colors.WHITE}`,
    color: `${colors.WHITE}`
  },
  gray: {
    background: `${colors.GRAY_BUTTON}`,
    border_color: `${colors.GRAY_BUTTON}`,
    hover_color: `${colors.GRAY_BUTTON}`,
    fill_hover: `${colors.GRAY_BUTTON}`,
    hover_bg: `${colors.WHITE_COLOR}`,
    color: `${colors.WHITE}`
  },
  green: {
    background: `${colors.GREEN_COLOR}`,
    border_color: `${colors.GREEN_COLOR}`,
    hover_color: `${colors.GREEN_COLOR}`,
    fill_hover: `${colors.GREEN_COLOR}`,
    hover_bg: `${colors.WHITE_COLOR}`,
    color: `${colors.WHITE}`
  },
  add: {
    color: `${colors.GREEN_COLOR}`,
    fill_color: `${colors.GREEN_COLOR}`,
  },
  delete: {
    color: `${colors.RED_COLOR}`,
    fill_color: `${colors.RED_COLOR}`,
  },
  transparent: {
    background: `rgba(255,255,255,0.1);`,
    border_color: `transparent`,
    hover_color: `${colors.BLACK}`,
    fill_hover: `${colors.PRIMARY_ORANGE}`,
  },
  white: {
    background: `${colors.WHITE}`,
    color: `${colors.BLACK}`,
    border_color: `${colors.WHITE}`,
    hover_color: `${colors.PRIMARY_PURPLE}`,
    fill_hover: `${colors.PRIMARY_PURPLE}`,
  },
  white_hover_bg: {
    background: `${colors.WHITE}`,
    color: `${colors.BLACK}`,
    border_color: `${colors.WHITE}`,
    hover_color: `${colors.WHITE}`,
    hover_bg: `${colors.PRIMARY_PURPLE}`,
    fill_hover: `${colors.PRIMARY_PURPLE}`,
  }
};

const LinkDefault = styled(Link)`
  display: inline-block;
  font-size: 14px;
  color: ${colors.TEXT_BLACK};

  &:hover {
    color: ${colors.PARAGRAPH_GRAY};
  }

  img, svg {
    margin-right: 8px;
    vertical-align: middle;
    position: relative;
  }
`;

const Links = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: ${props => theme[props.theme].background};
  border-radius: 3px;
  font-family: 'Gilroy-Medium';
  font-weight: 500;
  padding: 15px 20px;
  color: ${props => theme[props.theme].color};
  padding: ${props => theme[props.theme].padding};
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  border: 1px solid ${props => theme[props.theme].border_color};
  transition: all 100ms ease-in-out;
  max-width: 100%;
  position: relative;

  label {
    width: 26px;
    height: 26px;
  }

  img, svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 12px;
    transition: all 100ms ease-in-out;
    stroke: ${colors.WHITE_COLOR};
  }

  &:hover {
    background-color: ${props => theme[props.theme].hover_bg};
    color: ${props => theme[props.theme].hover_color};

    svg {
      stroke: ${props => theme[props.theme].fill_hover};
    }
  }
`;

const LinksSm = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background-color: ${props => theme[props.theme].background};
  border-radius: 3px;
  font-family: 'Gilroy-Medium';
  font-weight: 500;
  padding: 10.5px 20px;
  color: ${props => theme[props.theme].color};
  padding: ${props => theme[props.theme].padding};
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  border: 1px solid ${props => theme[props.theme].border_color};
  transition: all 100ms ease-in-out;
  max-width: 100%;
  position: relative;

  @media (max-width: 576px) {
    padding: 10.5px 14px;
  }

  label {
    width: 26px;
    height: 26px;
  }

  img, svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 12px;
    transition: all 100ms ease-in-out;
    stroke: ${colors.WHITE_COLOR};
  }

  &:hover {
    background-color: ${colors.WHITE_COLOR};
    color: ${props => theme[props.theme].hover_color};
    border-radius: 3px;

    svg {
      stroke: ${props => theme[props.theme].fill_hover};
    }
  }
`;

const LinkWhite = styled(Link)`
  display: inline-block;
  background: ${colors.WHITE_COLOR};
  box-shadow: 0 0 20px ${colors.SHADOW_GRAYDARK};
  border: none;
  border-radius: 10px;
  font-size: 12px;
  color: ${colors.TEXT_GRAY};
  padding: 6px 14px;
  margin-top: 12px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: all 100ms ease-in-out;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    font-size: 12px;
  }

  label {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -11px;
    width: 22px;
    height: 22px;
  }

  img, svg, .isvg {
    width: 26px;
    height: 26px;
    fill: ${colors.TEXT_GRAY};
    margin-right: 10px;
  }

  span {
    float: right;
    margin-left: auto;
  }

  &:hover {
    box-shadow: 0px 0px 1px #c4c4c4;

    > label > span:before {
      background: ${colors.ORANGE_COLOR};
    }
  }
`;

const LinkAction = styled(Link)`
  color: ${props => theme[props.theme].color};
  width: auto;
  white-space: nowrap;

  svg {
    fill: ${props => theme[props.theme].fill_color};
    width: 20px;
    height: 20px;
  }
`;

Links.defaultProps = {
  theme: 'primary'
}

export {
  LinkDefault,
  Links,
  LinksSm,
  LinkWhite,
  LinkAction
};
