import React, {FC, useEffect, useCallback, useState} from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import routes from '../../config/routes';
import PrivateRoute from '../PrivateRoute';
import UnauthorizedOnlyRoute from '../UnauthorizedOnlyRoute';

import Error404 from '../HttpStatusPages/error404Loadable';
import Error403 from '../HttpStatusPages/error403Loadable';
import CookieConsentWrapper from '../../components/CookieConsent/Loadable'

// Can && View Sections
import Can from '../../config/acl/can';
import {viewSections} from '../../config/acl/aclReadSections';

// Header
import Header from './Headers';

// Import Styles
import { ReactComponent as ReactSprite } from "../../assets/Images/icons.svg";
import styled from 'styled-components';
import colors from '../../config/colors';
import GlobalStyle from './global-styles';
import {LoadOverlaySticky} from '../../components/Animations';
import HeaderWrapper from '../../components/Header';
import Footer from '../../components/Footer/Loadable';
import ScrollToTop from '../ScrollToTop';
import roles from '../../config/roles';

// Import Pages
import Modal from '../Modal/Loadable';
import Jobs from '../Jobs/Loadable';
import JobDetailsProtected from '../Jobs/Details/Loadable';
import HomePage from '../Home/Loadable';
import NewsPage from '../News/Loadable';
import OurServices from '../OurServices/Loadable';
import NewsDetails from '../News/Details/Loadable'
import EventsPage from '../Events/Loadable';
import EventsDetails from '../Events/Details/Loadable'

import NewPasswordPage from '../ForgotPassword/Reset/Loadable';
import DashboardPage from '../Dashboard/Loadable';
import JobCreate from '../JobsAdmin/Loadable';

import NewsEdit from '../News/Edit/Loadable';
import NewsCreate from '../News/Create/Loadable';
import EventEdit from '../Events/Edit/Loadable';
import PreviewEvent from '../Events/PreviewEvent/Loadable';
import JobsList from '../PreselectionSelectionServices/Business/JobsList/Loadable';
import ChooseOption from '../PreselectionSelectionServices/Business/ChooseOption/Loadable';
import ChooseInterview from '../PreselectionSelectionServices/Business/ChoseInterview/Loadable';
import PreviewJob from '../PreselectionSelectionServices/Business/PreviewJob/Loadable';
import JobEdit from '../JobsAdmin/JobEdit/Loadable';
import Payment from '../Payment/Loadable';
import Thread from '../Messages/Thread/Loadable'
import ReviewJob from '../PreselectionSelectionServices/Business/ReviewJob/Loadable';
import CandidatesList from '../PreselectionSelectionServices/Hr/CandidatesList/Loadable';
import HrCreate from '../JobsAdmin/Hr/Create/Loadable';
import HrEdit from '../JobsAdmin/Hr/Edit/Loadable';
import EventCreatePage from '../Events/Create/Loadable';

import AddComment from '../PreselectionSelectionServices/Business/AddComment/Loadable';
import PrivacyPolicy from '../PrivacyPolicy/Loadable';
import TermsOfUse from '../TermsOfUse/Loadable';
import AboutUs from '../AboutUs/Loadable';
import SoonPage from '../SoonPage/Loadable';
import JobPreview from '../Jobs/Details/LoadableDetails';
import Unsubscribe from '../Unsubscribe/Loadable';

import CookiesPolicy from '../PrivacyPolicy/LoadableCookiePolicy';
import ReviewJobBussines from '../JobsAdmin/ReviewJob/Loadable';

// Chatbox
import ChatBotHelper from '../ChatBot/Loadable';

// Import Notifications
import Notifications from '../Notifications/Loadable';

// Import Cloud Note
import CloudNote from '../CloudNote/Loadable';

// Import Actions
import {refreshToken} from './actions';
import {logoutAction} from '../Login/actions'

// Import Selectors
import { makeSelectLoading, makeSelectToken, cloudNoteStatusSelector, cloudNoteTitleSelector, cloudNoteDescriptionSelector } from './selectors';
import {removeImageNamespace} from '../../utils/ImageCacheService';

import EmployerDetails from '../Employer/EmployerDetails/Loadable';
import ReturnJob from '../JobsAdmin/ReviewJob/ReturnJob/Loadable';
import Bid from '../PreselectionSelectionServices/Business/Bid/Loadable';

import {UserService} from '../../services/UserService'
import JobApplication from '../Jobs/Details/Apply/Loadable';
import ResetPasswordPhoneNumber from '../ForgotPassword/ResetPasswordPhoneNumber/Loadable';
import SupportAndDevelopment from '../SupportAndDevelopment/Loadable';
import PriceList from '../PriceList/Loadable';

const App: FC = () => {
 
  const location = useLocation();

  const loading = useSelector(makeSelectLoading);
  const dispatch = useDispatch();
  const token = useSelector(makeSelectToken);
  const match = useRouteMatch();
  const cloudNoteStatus = useSelector(cloudNoteStatusSelector);
  const cloudNoteTitle = useSelector(cloudNoteTitleSelector);
  const cloudNoteDescription = useSelector(cloudNoteDescriptionSelector);

  const isHomepage = location.pathname === '/' ? true : false

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if(!token){
      removeImageNamespace()
      dispatch(refreshToken())
    }
  }, [token, dispatch]);

  const logout = useCallback(()=>
    dispatch(logoutAction())
  ,[dispatch])

  const storage = UserService.getJwtFromLocalStorage()
  const isAuth = storage && storage.token ? true : false

  return (

    <AppWrapper>
      <ScrollToTop />
      {
        loading ?
          <LoadOverlaySticky />
        : ''
      }

      <GlobalStyle />

      <SpriteIcons>
        <ReactSprite />
      </SpriteIcons>

      <HeaderWrapper>
        <Header isAuth={isAuth} logout={logout} />
      </HeaderWrapper>

      <Content style={{ marginTop: isHomepage && width > 1025 ? '0' : '94px' }}>
        <Switch>
          <Route exact path={routes.HomePage}>
            <HomePage/>
          </Route>

          <UnauthorizedOnlyRoute path={routes.ForgottenPasswordNew}>
            <NewPasswordPage/>
          </UnauthorizedOnlyRoute>

          <UnauthorizedOnlyRoute path={routes.ResetPasswordPhoneNumber}>
            <ResetPasswordPhoneNumber/>
          </UnauthorizedOnlyRoute>

          {/* Payment */}
          <PrivateRoute path={routes.Payment}><Payment/></PrivateRoute>

          {/* Dashboard */}
          <PrivateRoute path={routes.DashboardPage}>
            {isAuth ?
              <DashboardPage/> : null
            }
          </PrivateRoute>

          {/* Static Pages */}
          <Route exact path={routes.PriceList}>
            <PriceList/>
          </Route>
          <Route exact path={routes.SupportAndDevelopment}>
            <SupportAndDevelopment />
          </Route>
          <Route exact path={routes.SoonPage}>
            <SoonPage/>
          </Route>
          <Route exact path={routes.AboutUs}>
            <AboutUs/>
          </Route>
          <Route exact path={routes.PrivacyPolicy}>
            <PrivacyPolicy/>
          </Route>
          <Route exact path={routes.CookiesPolicy}>
            <CookiesPolicy />
          </Route>
          <Route exact path={routes.TermsOfUse}>
            <TermsOfUse/>
          </Route>
          <Route exact path={routes.OurServices}>
            <OurServices/>
          </Route>

          {/* Employers */}
          <Route exact path={routes.EmployerDetails}>
            <EmployerDetails />
          </Route>
          {/* <Route exact path={routes.Employers}>
            <Employer />
          </Route> */}

          {/* Event Create/Edit/CTA/Details */}
          <PrivateRoute exact path={routes.EventReview}>
            <Can I="view" a={viewSections.section.events.eventReview}>
              <PreviewEvent/>
            </Can>
          </PrivateRoute>
          <PrivateRoute exact path={routes.EventEdit}>
          {isAuth ?
            <Can I="view" a={viewSections.section.events.eventEdit}>
              <EventEdit/>
            </Can> : null }

          </PrivateRoute>
          <PrivateRoute roles={[roles.BUSINESS_USER]} path={routes.EventCreate}>
          {isAuth ?
            <Can I="view" a={viewSections.section.events.eventCreate}>
              <EventCreatePage/>
            </Can> : null }
          </PrivateRoute>
          <Route exact path={routes.EventsDetailPage}>
            <EventsDetails/>
          </Route>
          <Route exact path={routes.EventsPage}>
            <EventsPage/>
          </Route>

          {/* Job Create/Edit/CTA/Details */}
          <PrivateRoute exact path={routes.JobEdit}>
          {isAuth ?
            <Can I="view" a={viewSections.section.jobs.jobEdit}>
              <JobEdit/>
            </Can> : null }
          </PrivateRoute>

          <Route exact path={routes.Jobs}>
            <Jobs/>
          </Route>
          <Route path={[routes.JobsFilterCity, routes.JobsFilterFieldOfWork]}>
            <Jobs/>
          </Route>

          <Route exact path={routes.JobsDetailByUrlSlug}>
            <JobPreview/>
          </Route>
          <Route path={routes.JobsDetailByUrlSlugApply}>
            <JobApplication/>
          </Route>

          <PrivateRoute roles={[roles.BUSINESS_USER, roles.HR_USER, roles.ADMIN, roles.MANAGEMENT]} path={routes.JobsDetailPage}>
              <JobDetailsProtected/>
          </PrivateRoute>

          <PrivateRoute roles={[roles.USER]} path={routes.Unsubscribe}>
              <Unsubscribe/>
          </PrivateRoute>

          {/* News Create/Edit/CTA/Details */}
          <PrivateRoute exact path={routes.NewsEdit}>
          {isAuth ?
            <Can I="view" a={viewSections.section.news.newsEdit}>
              <NewsEdit/>
            </Can> : null }
          </PrivateRoute>
          <PrivateRoute roles={[roles.BUSINESS_USER]} path={routes.NewsCreate}>
          {isAuth ?
            <Can I="view" a={viewSections.section.news.newsCreate}>
              <NewsCreate/>
            </Can> : null }
          </PrivateRoute>
          <Route exact path={routes.NewsPage}>
            <NewsPage/>
          </Route>
          <Route exact path={routes.NewsDetailPage}>
            <NewsDetails/>
          </Route>

          {/* Preselection/Selection */}
          <PrivateRoute path={routes.PreselectionJobList} roles={[roles.BUSINESS_USER]} >
          {isAuth ?
            <Can I="view" a={viewSections.section.preselection.jobList}>
              <JobsList/>
            </Can> : null }
          </PrivateRoute>
          <PrivateRoute path={routes.PreselectionChooseOption}>
          {isAuth ?
            <Can I="view" a={viewSections.section.preselection.chooseOption}>
              <ChooseOption/>
            </Can> : null }
          </PrivateRoute>
          <PrivateRoute path={routes.PreselectionChooseInterview}>
            <Can I="view" a={viewSections.section.preselection.chooseInterview}>
              <ChooseInterview/>
            </Can>
          </PrivateRoute>
          <PrivateRoute path={routes.PreselectionCustomOption}>
            <Can I="view" a={viewSections.section.preselection.previewJob}>
              <PreviewJob/>
            </Can>
          </PrivateRoute>
          <PrivateRoute exact path={routes.PreselectionJob}>
            <Can I="view" a={viewSections.section.preselection.addComment}>
              <AddComment/>
            </Can>
          </PrivateRoute>
          <PrivateRoute path={routes.PreselectionReviewJob}>
            <Can I="view" a={viewSections.section.preselection.ReviewJob}>
              <ReviewJob/>
            </Can>
          </PrivateRoute>

          {/* HR */}
          <PrivateRoute exact path={routes.PreselectionCandidateList}>
            <Can I="view" a={viewSections.section.preselection.candidateList}>
              <CandidatesList/>
            </Can>
          </PrivateRoute>
          <PrivateRoute exact path={routes.JobCreateHr}>
            <Can I="view" a={viewSections.section.jobs.jobCreateHr}>
              <HrCreate/>
            </Can>
          </PrivateRoute>
          <PrivateRoute exact path={routes.JobEditHr}>
            <Can I="view" a={viewSections.section.jobs.jobCreateHr}>
              <HrEdit/>
            </Can>
          </PrivateRoute>
          <PrivateRoute exact path={routes.JobBussinesPreview}>
              <ReviewJobBussines/>
          </PrivateRoute>
          <PrivateRoute exact path={routes.ReturnJobBussines}>
            <ReturnJob/>
          </PrivateRoute>
          <PrivateRoute exact path={routes.BidForService}>
              <Can I="view" a={viewSections.section.dashboard.bid}>
                <Bid/>
              </Can>
          </PrivateRoute>

          <PrivateRoute roles={[roles.BUSINESS_USER, roles.HR_USER]} path={routes.JobCreateStep}>
              <Can I="view" a={viewSections.section.jobs.jobCreate}>
                <JobCreate />
              </Can>
            </PrivateRoute>

          {/* Unauthorized Pages */}
          {/* Error Page */}
          <Route path="/error403" status={403} >
            <Error403/>
          </Route>
          <Route path="*" status={404} >
            <Error404/>
          </Route>
        </Switch>
          <Route
              path={match.url}
              component={() => {
                return (
                    <Modal  />
                );
              }}
            />
      </Content>
      <Footer />
      {isAuth ?
       <>
        <Thread />
        <Can I="view" a={viewSections.section.chatBot}>
          <ChatBotHelper />
        </Can>
       </>
       : null}
      <Notifications />
      <CloudNote status={cloudNoteStatus} title={cloudNoteTitle} description={cloudNoteDescription} />
      <CookieConsentWrapper />
    </AppWrapper>
  )
}

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .cookies {
    position: fixed;
    bottom: 0;
    width: 1110px;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1003;
    padding: 18px 30px 22px;
    background: ${colors.WHITE_COLOR};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.1);

    @media (max-width: 767px) {
      padding: 18px 15px 22px;
    }

    h3 {
      font-size: 18px;
      color: ${colors.TEXT_BLACK};
      margin: 0 0 8px;
    }

    p {
      font-size: 12px;
      color: ${colors.TEXT_BLACK};
      margin-bottom: 8px;

      a {
        font-family: 'Gilroy-Medium';
        font-weight: 500;
        font-size: 12px;
        color: ${colors.PURPLE_COLOR};

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__content {
      display: flex;
      margin: 0 -15px !important;

      @media (max-width: 767px) {
        display: block;
      }
    }

    &__details {
      display: block;
      margin-top: -23px;
    }

    button {
      background: ${colors.PRIMARY_ORANGE};
      color: ${colors.WHITE_COLOR};
      padding: 5px 18px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid ${colors.PRIMARY_ORANGE};
      transition: all 100ms ease-in-out;

      &:hover {
        background: ${colors.WHITE_COLOR};
        color: ${colors.PRIMARY_ORANGE};
      }
    }

    &.showDetails {

      .cookies__details {

        @media (max-width: 767px) {
          margin-top: 18px;
        }
      }
    }
  }
`;

const SpriteIcons = styled.div`
  display: none;
`;

const Content = styled.div`
  position: relative;
  min-height: calc(100vh - 94px);
`;

export default App;
