const colors = {
  BACKGROUND_COLOR: '#fbfbfb',
  BACKGROUND_GRAY: '#f6f6f6',
  BLUE_COLOR: '#1F69A4',
  WHITE_COLOR: '#fff',
  BLACK_COLOR: '#000',
  RED_COLOR: '#E50025',
  PURPLE_COLOR: '#634098',
  PINK_COLOR: '#ED7B9D',
  GREEN_COLOR: '#74af23',
  GRAYLIGHT_COLOR: '#B1B1B1',
  GRAY_BUTTON: '#909090',
  ORANGE_COLOR: '#FFB02F',
  TEXT_BLACK: '#272727',
  TEXT_GRAY: '#676767',
  TEXT_GRAYLIGHT: '#B9B9B9',
  PARAGRAPH_GRAY: '#5C5C5C',
  SHADOW_GRAY: 'rgba(134, 134, 134, 0.16)',
  SHADOW_GRAYLIGHT: 'rgba(198, 198, 198, 0.16)',
  SHADOW_GRAYDARK: 'rgba(93, 93, 93, 0.16)',
  CHART_LINE_BLUE: '#1F69A4',
  CHART_LINE_GRAY: '#B1B1B1',
  CHART_LINE_HOVER: '#F0F0F0',

  PRIMARY_ORANGE: '#F6BF51',
  PRIMARY_ORANGE_LIGHT: '#FDF2DC',
  PRIMARY_PURPLE: '#634098',
  PRIMARY_PURPLE_LIGHT: '#7B57B1',
  PRIMARY_PINK: '#ED8B9D',
  PRIMARY_RED: '#EB4639',

  INFO_BLUE: '#2F80ED',
  SUCCESS_GREEN: '#27AE60',
  WARNING_YELLOW: '#F8D879',
  ERROR_RED: '#EB5757',

  WHITE: '#FFFFFF',
  BLACK: '#111111',
  GRAY_DARK: '#333333',
  GRAY_MEDIUM: '#595959',
  GRAY_LIGHT: '#D7D7D7',
  GRAY_EXTRA_LIGHT: '#F5F5F9',
  GRAY_TEXT_MEDLIGHT: '#838383',
  GRAY_BORDER_MEDIUM: '#BCBCBC',
  GRAY_MD: '#E0E0EA',
};

export default colors;
