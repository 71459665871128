/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from './utils/history';
import appReducer from './containers/App/reducer';
import documentsReducer from './containers/Documents/reducer'
import jobsReducer from './containers/Jobs/reducer';

// import languageProviderReducer from 'containers/LanguageProvider/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
 
  
  const rootReducer = combineReducers({
    app: appReducer,
    documents: documentsReducer,
    jobs: jobsReducer,
    
    // language: languageProviderReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });
 
  return rootReducer;
}
