import React, {FC, memo, useCallback, useEffect, useState} from 'react';
import {Link, generatePath, useLocation} from "react-router-dom";
import {useSelector} from 'react-redux';

// Styles
import styled from 'styled-components';
import colors from '../../../config/colors';
import Number from '../../../components/Notification/NotificationNumber';
import routes from '../../../config/routes';
import roles from '../../../config/roles';

import Skeleton from 'react-loading-skeleton';

// Animations
import {FadeIn} from '../../../components/Animations/index';

// Selectors
import {makeSelectUserInfo, makeSelectNumberNotifications, makeSelectAvatarSelector, makeSelectCountUnreadMessages, makeSelectLoadingUserInfo} from '../selectors'


// Messages
import { FormattedMessage } from 'react-intl';
import generalMessages from '../../generalMessages';

import { Links } from '../../../components/LinksStyle';
import P from '../../../components/Paragraph';
import HeaderDropdownInner  from './dropdown';
import MyProfileDropDownInner from './myProfileDropdown';



interface Props  {
  logout : () => void,

  isAuth : boolean
}

const ProtectedInnerHeader: FC<Props> = ({logout, isAuth}) => {

  const [isFixed, setFixed] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);


  const userInfo = useSelector(makeSelectUserInfo);
  const avatarSelector = useSelector(makeSelectAvatarSelector);
  const countUnreadMessages = useSelector(makeSelectCountUnreadMessages);
  const notificationNumber = useSelector(makeSelectNumberNotifications);
  const loadingUserInfo = useSelector(makeSelectLoadingUserInfo);

  const logoutAction = () => {
    closeMenu();
    return logout();
  }

  const location = useLocation();

  useEffect(() => {
    let isFixedHeaderTemp = false;
    window.addEventListener("scroll", () => {

      const isFixedHeader = window.scrollY > 94 ? true : false;

      if(isFixedHeader !== isFixedHeaderTemp) {

        setFixed(isFixedHeader);
        isFixedHeaderTemp = isFixedHeader;
      }

    });
  }, []);

  const toggleMenu = useCallback((e) => {
    setOpen(!open);
    open ? document.body.classList.remove("no-scroll") : document.body.classList.add("no-scroll");
   }, [open]);

  const closeMenu = useCallback(() => {
      document.body.classList.remove("no-scroll")
      setOpen(!open);
  }, [open]);

  const toggleUserSettings = useCallback((event) => {

    if (!event.currentTarget.contains(event.relatedTarget)) {
      setOpenUser(event.relatedTarget ? false : !openUser);
    }
    return;
  }, [openUser]);

  const toggleDropdownInfo = useCallback((event) => {

    if (!event.currentTarget.contains(event.relatedTarget)) {
      setOpenDropdown(event.relatedTarget ? false : !openDropdown);
    }

  }, [openDropdown]);

    return (
    <FadeInHeader>
       {loadingUserInfo && isAuth ?
       <SkeletonAlign>
          <Skeleton className="skeleton" width={60} count={1}/>
          <Skeleton className="skeleton" circle={true} width={40} height={40}/>
          <Skeleton className="skeleton" width={30} count={1}/>
          <Skeleton className="skeleton" width={30} count={1}/>
          <Skeleton className="skeleton" width={70} count={1}/>
        </SkeletonAlign>
         :
        <HeaderNavProtected>
          <LinkItem to={routes.Jobs}>Pretraga poslova</LinkItem>
          <LinkItem to={routes.SupportAndDevelopment}>Razvoj karijere</LinkItem>
          <HeaderDropdownInner
            toggleDropdownInfo={toggleDropdownInfo}
            openDropdown={openDropdown}
            closeMenu={closeMenu}
            isFixed={isFixed}
          />
          {userInfo.role !== roles.USER ? <LinkBusiness to={generatePath(routes.OurServices)} fixed={isFixed.toString()} theme={isFixed ? 'primary_light' : 'transparent'}><svg><use xlinkHref={`#briefcase-f`}></use></svg>Za biznis korisnika</LinkBusiness> : null}

        {isAuth ?
        <>
           <LinkIcon className={isFixed ? 'icon-orange' : ''} theme={isFixed ?  '' : 'transparent'} to={generatePath(routes.Notifications)}>
            <i>
              <svg><use xlinkHref={`#notification`} /></svg>
              {notificationNumber && notificationNumber > 0 ? <Number className={notificationNumber && notificationNumber > 0 ? 'hasNotification' : ''}>{notificationNumber}</Number> : <></>}
            </i>
          </LinkIcon>
          <LinkIcon className={isFixed ? 'icon-orange' : ''} theme={isFixed ?  '' : 'transparent'} to={generatePath(routes.Messages)}>
             <i>
             <svg><use xlinkHref={`#message`} /></svg>
             {countUnreadMessages && countUnreadMessages[0] > 0 ? <Number className={countUnreadMessages && countUnreadMessages[0] > 0 ? 'hasMessage' : ''}>{countUnreadMessages[0]}</Number>: <></>}</i>
          </LinkIcon>

          <HeaderNavUser>
            <HeaderNavUserIcon>
              {avatarSelector ? <Link to={userInfo.role === roles.USER ? routes.RequestedServices  : userInfo.role === roles.BUSINESS_USER ? routes.DashboardPage : '/dashboard/tasks'  }><img src={avatarSelector} alt={userInfo.first_name} /></Link> : <Link to={userInfo.role === roles.USER ? routes.RequestedServices  : userInfo.role === roles.BUSINESS_USER ? routes.DashboardPage : '/dashboard/tasks'  }><svg className="isvg"><use xlinkHref={`#user`} /></svg></Link>}
            </HeaderNavUserIcon>

            <MyProfileDropDownInner
              toggleUserSettings={toggleUserSettings}
              logoutAction={logoutAction}
              openUser= {openUser}
              roles={roles}
              userInfo={userInfo}
            />

          </HeaderNavUser>
          </>
        :  <ButtonLogin fixed={isFixed.toString()}  theme={isFixed ? '' : 'white'} to={{pathname: location.pathname, search: '?modal=true&LoginPage=true'}} ><FormattedMessage {...generalMessages.Login}/> </ButtonLogin>}
        </HeaderNavProtected>
        }
        <MobileHeader>
          <HeaderNavUser className="mobile-userinfo">
          {isAuth ?
          <>
            <HeaderNavUserIcon>
              {avatarSelector ? <Link to={userInfo.role === roles.USER ? routes.RequestedServices  : userInfo.role === roles.BUSINESS_USER ? routes.DashboardPage : '/dashboard/tasks'  }><img src={avatarSelector} alt="Avatar" /></Link> : <Link to={userInfo.role === roles.USER ? routes.RequestedServices  : userInfo.role === roles.BUSINESS_USER ? routes.DashboardPage : '/dashboard/tasks'  }><svg className="isvg"><use xlinkHref={`#user`} /></svg></Link>}
            </HeaderNavUserIcon>
            <span>{userInfo.first_name} {userInfo.last_name}</span>
            </>
          : null}
          </HeaderNavUser>
          <HamburgerToggle onClick={toggleMenu} className={open ? 'close' : ''}><span></span>MENI</HamburgerToggle>
          <MenuMob className={open ? 'open' : 'hide'}>
            <P>MENI</P>
            <ul>
              <li  onClick={closeMenu} ><Link to={routes.Jobs}>Pretraga poslova</Link></li>
              <li  onClick={closeMenu}><Link  to={routes.SupportAndDevelopment}>Razvoj karijere</Link></li>
              <li>
                <HeaderDropdownInner
                  toggleDropdownInfo={toggleDropdownInfo}
                  openDropdown={openDropdown}
                  closeMenu={closeMenu}
                  isFixed={isFixed}
                />
              </li>
            </ul>
            <div className="bottom">
              {userInfo.role !== roles.USER ? <LinkTransparent onClick={closeMenu} theme='purple' to={generatePath(routes.OurServices)}><svg><use xlinkHref={`#briefcase-f`}></use></svg>Za biznis korisnika</LinkTransparent> : null}
              {isAuth ?
                <Links to={"#"} onClick={logoutAction}><FormattedMessage {...generalMessages.Logout} /></Links>
                : <Links onClick={closeMenu} theme='primary' to={{pathname: location.pathname, search: '?modal=true&LoginPage=true'}}  ><FormattedMessage {...generalMessages.Login}/></Links>
              }
            </div>
          </MenuMob>
        </MobileHeader>
      </FadeInHeader>
  )
}

const FadeInHeader = styled(FadeIn)`
  .skeleton {
    margin-right: 10px;
  }
`;
const SkeletonAlign = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderNavProtected = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const LinkItem = styled(Link)`
  margin-left: 30px;
`;

const ButtonLogin = styled(Links)<{ fixed: string }>`
  width: 132px;
  height: auto;
  padding: 10.5px 14px;
  border-radius: 5px;
  margin-left: 10px;
  color: ${p => (p.fixed === 'true' ? colors.WHITE : colors.PRIMARY_PURPLE)} !important;

  &:hover {
    color: ${p => (p.fixed === 'true' ? colors.PRIMARY_ORANGE : colors.PRIMARY_ORANGE)} !important;
  }
`;

const LinkTransparent = styled(Links)`

  svg {
    fill: ${colors.WHITE} !important;
    stroke: none;
  }

  &:hover {

    svg {
      fill: ${colors.WHITE} !important;
      stroke: none;
    }
  }
`;

const LinkBusiness = styled(Links)<{ fixed: string }>`
  height: auto;
  padding: 10.5px 14px;
  border-radius: 5px;
  margin-left: 26px;
  color: ${p => (p.fixed === 'true' ? colors.PRIMARY_ORANGE : colors.WHITE)} !important;

  svg {
    fill: ${p => (p.fixed === 'true' ? colors.PRIMARY_ORANGE : colors.WHITE)} !important;
    stroke: none;
  }

  &:hover {
    border-color: ${p => (p.fixed === 'true' ? colors.PRIMARY_ORANGE : colors.WHITE)} !important;

    svg {
      fill: ${p => (p.fixed === 'true' ? colors.PRIMARY_ORANGE : colors.WHITE)} !important;
      stroke: none;
    }
  }
`;

const LinkIcon = styled(Links)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  padding: 0;
  border-radius: 5px;
  margin-left: 8px;

  &.icon-orange {
    background-color: ${colors.PRIMARY_ORANGE_LIGHT};
    border-color: ${colors.PRIMARY_ORANGE_LIGHT};
    color: ${colors.PRIMARY_ORANGE} !important;

    svg {
      fill: ${colors.PRIMARY_ORANGE};
    }

    &:hover {
      background-color: ${colors.WHITE};
      border-color: ${colors.PRIMARY_ORANGE};
    }

    i {

      small.hasMessage {
        background: ${colors.PRIMARY_PURPLE};
        color: ${colors.WHITE};
      }
    }
  }

  @media (max-width: 767px) {
    display: none;
  }

  i {
    position: relative;
  }

  svg {
    margin-right: 0;
    width: 17px;
    height: 17px;
    stroke: none;
    fill: ${colors.WHITE};
  }

  &:hover {

    svg {
      stroke: none;
      fill: ${colors.PRIMARY_ORANGE};
    }

    i.hasMessage  {

      &:before {
        background: ${colors.PRIMARY_PURPLE};
      }
    }
  }
`;

const HeaderNavUser = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: #7E7F7F;
  text-align: right;
  margin-left: 8px;
  &.mobile-userinfo {
    flex-direction: column;
    span {
      color: ${colors.BLACK};
    }
  }

  @media (max-width: 767px) {
    padding: 0 !important;
  }
`;


const HeaderNavUserIcon = styled.div`
  width: 42px;
  height: 42px;
  background: rgba(255,255,255,0.1);
  border-radius: 5px;
  margin-right: 11px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  a {
    margin-left: 0 !important;
  }

  @media (max-width: 767px) {
    width: 32px;
    height: 32px;
    margin-left: 8px;
  }

  .isvg, img {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    object-fit: contain;

    @media (max-width: 767px) {
      width: 32px;
      height: 32px;
    }
  }
`;

const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media (min-width: 1101px) {
    display: none;
  }
   @media (max-width: 1100px) {
    display: flex;
  }
`;

const HamburgerToggle = styled.div`
  margin-left: 15px;
  font-size: 16px;
  font-family: "Gilroy-Medium";
  font-weight: 500;
  width: 36px;
  height: 40px;
  position: relative;
  padding-top: 30px;
  white-space: nowrap;
  text-align: center;

  span {
    display: block;
    position: absolute;
    left: 0;
    top: 10px;
    width: 36px;
    height: 3px;
    background: #000;
    opacity: 1;
    transition: all 400ms ease-in-out;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -1px 0 0 -18px;
      width: 36px;
      height: 3px;
      background: #000;
      opacity: 1;
      transition: all 400ms ease-in-out;
    }

    &:before {
      top: -8px;
    }

    &:after {
      top: 10px;
    }
  }

  &.close {

    span {
      background: none;

      &:before {
        transform: rotate(-45deg);
        top: 2px;
        width: 30px;
        margin: -1px 0 0 -15px;
      }

      &:after {
        transform: rotate(45deg);
        top: 2px;
        width: 30px;
        margin: -1px 0 0 -15px;
      }
    }

    svg {
      width: 22px;
      height: 22px;
      fill: ${colors.BLACK};
    }
  }
`;

const MenuMob = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  background: ${colors.GRAY_EXTRA_LIGHT};
  top: 94px;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: "Gilroy-Medium";
  font-weight: 500;
  padding: 20px;
  overflow-y: auto;
  opacity: 0;
  pointer-events: none;
  transition: all 400ms ease-in-out;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  &.hide {
    display: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    background: ${colors.WHITE};
    margin: 10px 0;

    a {
      display: block;
      padding: 11.5px 14px;
      color: ${colors.BLACK};
    }
  }

  .bottom {
    margin-top: auto;

    a {
      color: ${colors.WHITE};
       width: 100%;
       height: auto;
       padding: 10.5px 14px;
       margin: 5px 0;

       svg {
         width: 16px;
         height: 16px;
       }
    }
  }
`;

export default memo(ProtectedInnerHeader);
