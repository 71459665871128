import styled from 'styled-components';
import colors from '../../config/colors';
import {memo} from 'react';

const P = styled.p`
  font-size: 16px;
  color: ${colors.BLACK};
  margin: 0 0 4px;
`;

export default memo(P);
