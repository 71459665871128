const scope = 'loop/Tasks';

// Assign
export const ASSIGN_TASK = `${scope}/ASSIGN_TASK`;
export const ASSIGN_TASK_SUCCESS = `${scope}/ASSIGN_TASK_SUCCESS`;
export const ASSIGN_TASK_ERROR = `${scope}/ASSIGN_TASK_ERROR`;

// Search Users
export const SEARCH_USERS = `${scope}/SEARCH_USERS`;
export const SEARCH_USERS_SUCCESS = `${scope}/SEARCH_USERS_SUCCESS`;
export const SEARCH_USERS_ERROR = `${scope}/SEARCH_USERS_ERROR`;

// Get Task
export const GET_TASKS = `${scope}/GET_TASKS`;
export const GET_TASKS_SUCCESS = `${scope}/GET_TASKS_SUCCESS`;
export const GET_TASKS_ERROR = `${scope}/GET_TASKS_ERROR`;

// Get Task
export const GET_TASK = `${scope}/GET_TASK`;
export const GET_TASK_SUCCESS = `${scope}/GET_TASK_SUCCESS`;
export const GET_TASK_ERROR = `${scope}/GET_TASK_ERROR`;

// Complete Task
export const COMPLETE_TASK = `${scope}/COMPLETE_TASK`;
export const COMPLETE_TASK_SUCCESS = `${scope}/COMPLETE_TASK_SUCCESS`;
export const COMPLETE_TASK_ERROR = `${scope}/COMPLETE_TASK_ERROR`;

// Approve ENTITY
export const APPROVE_ENTITY = `${scope}/APPROVE_ENTITY`;
export const APPROVE_ENTITY_SUCCESS = `${scope}/APPROVE_ENTITY_SUCCESS`;
export const APPROVE_ENTITY_ERROR = `${scope}/APPROVE_ENTITY_ERROR`;

// Approve TASK
export const TASK_GROUP = `${scope}/TASK_GROUP`;
export const TASK_GROUP_SUCCESS = `${scope}/TASK_GROUP_SUCCESS`;
export const TASK_GROUP_ERROR = `${scope}/TASK_GROUP_ERROR`;

// Task sidebar
export const SIDEBAR = `${scope}/SIDEBAR`;
export const SIDEBAR_CREATE_PRICE = `${scope}/SIDEBAR_CREATE_PRICE`;

// Delete task
export const DELETE_TASK = `${scope}/DELETE_TASK`;
export const DELETE_TASK_SUCCESS = `${scope}/DELETE_TASK_SUCCESS`;
export const DELETE_TASK_ERROR = `${scope}/DELETE_TASK_ERROR`;

// Get applicants
export const GET_APPLICANTS = `${scope}/GET_APPLICANTS`;
export const GET_APPLICANTS_SUCCESS = `${scope}/GET_APPLICANTS_SUCCESS`;
export const GET_APPLICANTS_ERROR = `${scope}/GET_APPLICANTS_ERROR`;

// Get applicants ITEMS
export const APPLICANTS_PER_PAGE  = 10;

// Get applicants
export const CREATE_PRICE = `${scope}/CREATE_PRICE`;
export const CREATE_PRICE_SUCCESS = `${scope}/CREATE_PRICE_SUCCESS`;
export const CREATE_PRICE_ERROR = `${scope}/CREATE_PRICE_ERROR`;


export const JOB_COMMENT = `${scope}/JOB_COMMENT`;
export const JOB_COMMENT_SUCCESS = `${scope}/JOB_COMMENT_SUCCESS`;
export const JOB_COMMENT_ERROR = `${scope}/JOB_COMMENT_ERROR`;



