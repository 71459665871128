import { createGlobalStyle } from 'styled-components';
import colors from '../../config/colors';
import '../../assets/Fonts/fonts.css';

export default createGlobalStyle`

  *, ::after, ::before {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  #root {
    height: 100%;
  }

  body {
    background: ${colors.WHITE};
    font-family: 'Gilroy-Medium';
    margin: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    &.no-scroll {
      overflow: hidden;
      padding-right: 15px;

      .header {
        width: auto;
        right: 15px;
        transition: right 0ms;
      }
    }

    &.modalOpen {
      overflow: hidden;
      padding-right: 15px;
    }
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
    outline: none;
    transition: color 100ms ease-in-out;
    word-break: normal;

    &:active,
    &:focus {
      outline: none;
    }
  }

  button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    word-break: normal;

    &:active,
    &:focus {
      outline: none !important;
    }
  }

  a {
    font-size: 16px;
    color: ${colors.PRIMARY_PURPLE};

    &:hover {
      color: ${colors.TEXT_BLACK};
    }
  }

  p {
    font-size: 16px;
    line-height: 26px;
    color: ${colors.BLACK};
    margin: 0 0 4px;

    a {
      color: ${colors.PRIMARY_PURPLE};
    }
  }

  ul, ol {
    margin: 0 0 20px;

    li {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: ${colors.PARAGRAPH_GRAY};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Gilroy-Bold';
    color: ${colors.TEXT_BLACK};
  }

  select,
  textarea,
  input {

    @supports (-webkit-touch-callout: none) {

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  label {

    &:focus {
      outline: none;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  blockquote {
    font-family: "Gilroy-Bold";
    color: ${colors.TEXT_BLACK};
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;

    &::before {
      color: ${colors.TEXT_BLACK};
      content: open-quote;
      font-size: 3.5em;
      margin-right: 10px;
      vertical-align: -0.5em;
      font-family: Georgia, Times, Garamond, serif;
      line-height: 20px;
      display: inline-block;
    }
    &::after {
      color: ${colors.TEXT_BLACK};
      content: close-quote;
      margin-left: 10px;
      font-size: 3.5em;
      font-family: Georgia, Times, Garamond, serif;
      vertical-align: -0.6em;
      line-height: 20px;
      display: inline-block;
    }
  }

  .notification-unread {
    display: block;
    margin: 0;
    padding: 4px 10px;
    font-size: 13px;
    color: ${colors.TEXT_GRAY};
  }

  // ------------------------------------ Input Search Clear Button Style Webkit
  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
     height: 13px;
     width: 13px;
     position: relative;
     right: -3px;
     background: url("data:image/svg+xml;charset=UTF-8,%3csvg viewPort='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='1' y1='11' x2='11' y2='1' stroke='#676767' strokeWidth='1.2'/%3e%3cline x1='1' y1='1' x2='11' y2='11' stroke='#676767' strokeWidth='1.2'/%3e%3c/svg%3e");
  }

  // ------------------------------------------------ Input Number Remove Arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type="time"]::-webkit-clear-button {
    color: #000;
  }

  // --------------------------------------------------- React Custom Scrollbars
  .scrollbar {

    .track-vertical {
      width: 10px !important;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 10px;
      background: transparent;

      > div {
        background: ${colors.ORANGE_COLOR} !important;
      }

      .thumb-vertical {
        position: relative;
        display: block;
        width: 100%;
        cursor: pointer;
        border-radius: inherit;
        background-color: ${colors.ORANGE_COLOR}
      }
    }
  }

  .hide-item {
    display: none !important;
  }

/* Package hidden */
.select-package-hidden {
  display: none;
}

/* if the dont want delete */
.disabled-button {
  background: ${colors.GRAY_BUTTON};
  border: 1px solid ${colors.GRAY_BUTTON};
  pointer-events: none;
  &:hover {
    background: ${colors.GRAY_BUTTON};
    border: 1px solid ${colors.GRAY_BUTTON};
    color: ${colors.WHITE_COLOR}
  }
}
@media print {
  header,
  footer {
    display: none;
  }
}

/* Jobs with different packages */
.featured {
    border: 2px solid ${colors.PRIMARY_PINK};

    &:hover {
      background: rgba(237, 139, 157, 0.2);
    }
  }

.hot {
  border: 2px solid ${colors.PRIMARY_ORANGE};

  &:hover {
    background: rgba(246, 191, 81, 0.2);
  }
}

/* pacakges */
.disable-pacakge {
  div {
    border-left: 8px solid ${colors.PARAGRAPH_GRAY};
  }

  label {
    span {
      border: 1px solid ${colors.PARAGRAPH_GRAY};
    }
  }

}
`;
