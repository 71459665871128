import produce from 'immer';
import update from 'immutability-helper';

import {
    JOBS_SUCCESS,
    JOBS_REQUEST,
    JOBS_ERROR,
    GET_SINGLE_JOB,
    GET_SINGLE_JOB_SUCCESS,
    GET_SINGLE_JOB_ERROR,
    CHECK_APPLICATION_SUCCESS,
    SAVE_JOB,
    SAVE_JOB_SUCCESS,
    SAVE_JOB_ERROR,
    GET_SIMILIAR_JOBS,
    GET_SIMILIAR_JOBS_SUCCESS,
    GET_CITY_DESC,
    GET_CITY_DESC_ERROR,
    GET_CITY_DESC_SUCCESS,
    GET_JOB_BY_URL_SLUG,
    APPLY_MODAL,
    IS_USER_LOGGED_IN_MODAL
} from './constants';

import {Job} from '../../models/jobs'

export interface jobsState {
  jobs: Job[] | null;
  similiarJobs: [];
  jobItem : object | null;
  jobItemError: any |  null,
  error: any;
  loaderSimilar: boolean;
  loaderDetail: boolean;
  loading: boolean;
  page: number;
  applicantExists: boolean;
  isSavedJob: boolean;
  savedJobLoading: boolean;
  saveJobPayload: {
    key: number;
    saved: boolean;
  }
  currentPage: number;
  pageCount: number;
  currentSimilarPage: number;
  pageSimilarCount: number;
  cityLoading: boolean;
  citySeo: Object | null;
  applyModal: boolean;
  isUserLoggedInModal: boolean;
}

export const initialState: jobsState = {
  jobs: null,
  similiarJobs: [],
  jobItem : null,
  jobItemError: null,
  error: null,
  loading: false,
  loaderDetail: false,
  loaderSimilar: false,
  page: 1,
  applicantExists: false,
  isSavedJob: false,
  savedJobLoading: false,
  saveJobPayload: {
    key: 0,
    saved: false
  },
  currentPage: 1,
  pageCount: 1,
  currentSimilarPage: 1,
  pageSimilarCount: 1,
  cityLoading: false,
  citySeo: null,
  applyModal: false,
  isUserLoggedInModal: false
};

/* eslint-disable default-case, no-param-reassign */
const jobsReducer = (state = initialState, action: any) =>

  produce(state, draft => {
    switch (action.type) {   
      case JOBS_REQUEST:
        draft.loading = true;
        draft.jobs = [];
        draft.error = null;
        break;
      case JOBS_SUCCESS:       
        draft.loading = false;
        draft.currentPage = action?.response?.data?.current_page || 1;
        draft.pageCount = action?.response?.data?.last_page || 1; 
        draft.jobs = action.response.data.data;
        break;
      case GET_SIMILIAR_JOBS:
        draft.loaderSimilar = true;
        break
      case GET_SIMILIAR_JOBS_SUCCESS:
        draft.loaderSimilar = false;
        draft.currentSimilarPage = action?.response?.data?.meta?.current_page || 1;
        draft.pageSimilarCount = action?.response?.data?.meta?.last_page || 1;
        draft.similiarJobs = action.response.data.data;
        break
      case JOBS_ERROR:
        draft.loading = false;
        draft.error = action.error.response ? action.error.response.status : 0;
        break;
      case GET_SINGLE_JOB:
        draft.loaderDetail = true;
        break;
      case GET_JOB_BY_URL_SLUG:
        draft.jobItemError = null;
        draft.loaderDetail = true;
        break;  
      case GET_SINGLE_JOB_SUCCESS:
        const jobItem = action.response.data;
        draft.jobItemError = null;
        draft.loaderDetail = false;
        draft.jobItem = jobItem;
        draft.applicantExists = jobItem?.check_application?.applied || false;
        draft.isSavedJob = jobItem?.check_application?.saved || false;
        break;
      case GET_SINGLE_JOB_ERROR:
        draft.loaderDetail = false;
        draft.jobItemError = true;
        break;
      case CHECK_APPLICATION_SUCCESS:
        draft.loading = false;
        draft.applicantExists = action.response.data.applied;
        draft.isSavedJob = action.response.data.saved;
        break;  
      case SAVE_JOB:    
        if (action.key) {
          draft.saveJobPayload = {key: action.key, saved: action.saved}
        }    
        draft.savedJobLoading = true;
        break;
      case SAVE_JOB_SUCCESS:      
        draft.savedJobLoading = false;
        if (state?.saveJobPayload?.key) {
          draft.jobs = update(state.jobs, {[state.saveJobPayload.key]: {saved: {$set: state.saveJobPayload.saved}}}); 
        } else {
          draft.isSavedJob = !state.isSavedJob;
        }
        break;
      case SAVE_JOB_ERROR:
        draft.savedJobLoading = false;
        draft.error = action.error;
        break;          
      case GET_CITY_DESC:
        draft.cityLoading = true;
        break;            
      case GET_CITY_DESC_SUCCESS:
        draft.cityLoading = false;
        draft.citySeo = action.response.data
        break;            
      case GET_CITY_DESC_ERROR:
        draft.cityLoading = false;
        draft.citySeo = null;
        break;    
      case APPLY_MODAL:  
        draft.applyModal = action.open;
        break; 
      case IS_USER_LOGGED_IN_MODAL:  
        draft.isUserLoggedInModal = action.open;
        break;        
    }
  });

export default jobsReducer;
