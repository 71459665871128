import React, {FC, Children} from 'react';
import { makeSelectUserRole } from '../App/selectors';
import { useSelector } from 'react-redux';
import {buildAbilityFor} from '../../config/acl/ability';
import { AbilityContext } from '../../config/acl/can';

const CaslProvider: FC = (props) => {
  const role: string = useSelector(makeSelectUserRole);
 
  const ability = role ? buildAbilityFor(role) : buildAbilityFor('all');
  return (
    <AbilityContext.Provider value={ability}>
      {Children.only(props.children)}
    </AbilityContext.Provider> 
  )
}

export default CaslProvider;
