const scope = 'loop/Notifications';

export const NOTIFICATION_INIT = `${scope}/NOTIFICATION_INIT`;
export const NOTIFICATIONS_ERROR = `${scope}/NOTIFICATIONS_ERROR`;
export const NOTIFICATIONS_SUCCESS = `${scope}/NOTIFICATIONS_SUCCESS`;
export const NOTIFICATIONS_WARNING = `${scope}/NOTIFICATIONS_WARNING`;
export const NOTIFICATIONS_INFO = `${scope}/NOTIFICATIONS_INFO`;

export const ALL_NOTIFICATIONS = `${scope}/ALL_NOTIFICATIONS`;
export const ALL_NOTIFICATIONS_ERROR = `${scope}/ALL_NOTIFICATIONS_ERROR`;
export const ALL_NOTIFICATIONS_SUCCESS = `${scope}/ALL_NOTIFICATIONS_SUCCESS`;

export const UPDATE_NOTIFICATIONS = `${scope}/UPDATE_NOTIFICATIONS`;
export const UPDATE_NOTIFICATIONS_ERROR = `${scope}/UPDATE_NOTIFICATIONS_ERROR`;
export const UPDATE_NOTIFICATIONS_SUCCESS = `${scope}/UPDATE_NOTIFICATIONS_SUCCESS`;

export const DELETE_NOTIFICATION = `${scope}/DELETE_NOTIFICATION`;
export const DELETE_NOTIFICATION_ERROR = `${scope}/DELETE_NOTIFICATION_ERROR`;
export const DELETE_NOTIFICATION_SUCCESS = `${scope}/DELETE_NOTIFICATION_SUCCESS`;

// Per Page
export const ITEMS_PER_PAGE = 15;