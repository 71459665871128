import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import {viewSections} from './aclReadSections';
import {Page} from './aclReadSections';
import roles from '../roles';


type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'view';
type Subjects = 'all'| Page;

export type AppAbilityType = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;


export default function defineRulesFor(role: string) {
  const { can, rules } = new AbilityBuilder<AppAbilityType>();

  if(role === roles.USER) {
    can('view', viewSections.section.dashboard.savedJobs);
    can('view', viewSections.section.dashboard.userInformation);
    can('view', viewSections.section.dashboard.accountInformation);
    can('view', viewSections.section.dashboard.regularUserInformation);
    can('view', viewSections.section.dashboard.regularRequestedServices);
    can('view', viewSections.section.dashboard.favouriteJobs);
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.dashboard.uploads);
    can('view', viewSections.section.jobs.jobButtonShare);
    can('view', viewSections.section.jobs.jobButtonSaveAd);
    can('view', viewSections.section.jobs.jobTabsApply);
    can('view', viewSections.section.sideBarMenu.regular);
    can('view', viewSections.section.footer.regular);
    can('view', viewSections.section.header.regular);
    can('view', viewSections.section.chatBot);
    can('view', viewSections.section.jobs.bookmark);
    can('view', viewSections.section.dashboard.notifications);
    can('view', viewSections.section.dashboard.documents);
    can('view', viewSections.section.jobs.jobAdditionalLink);
    can('view', viewSections.section.sideBarMenu.show);
  }

  if(role === roles.BUSINESS_USER) {
    can('view', viewSections.section.dashboard.content);
    can('view', viewSections.section.dashboard.selection);
    can('view', viewSections.section.dashboard.candidateApplications);
    can('view', viewSections.section.dashboard.preSelection);
    can('view', viewSections.section.dashboard.companyInfo);
    can('view', viewSections.section.dashboard.accountInformation);
    can('view', viewSections.section.dashboard.userInformation);
    can('view', viewSections.section.dashboard.businessUserInformation);
    can('view', viewSections.section.dashboard.businessRequestedServices);
    can('view', viewSections.section.dashboard.statistics);
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.dashboard.jobCreate);
    can('view', viewSections.section.dashboard.cta);
    can('view', viewSections.section.dashboard.archiveCandidate);
    can('view', viewSections.section.events.eventCreate);
    can('view', viewSections.section.events.eventEdit);
    can('view', viewSections.section.events.eventReview);
    can('view', viewSections.section.jobs.jobCreate);
    can('view', viewSections.section.jobs.jobEdit);
    can('view', viewSections.section.news.newsCreate);
    can('view', viewSections.section.news.newsEdit);
    can('view', viewSections.section.preselection.jobList);
    can('view', viewSections.section.preselection.chooseOption);
    can('view', viewSections.section.preselection.chooseInterview);
    can('view', viewSections.section.preselection.previewJob);
    can('view', viewSections.section.preselection.addComment);
    can('view', viewSections.section.preselection.reviewJob);
    can('view', viewSections.section.jobs.setAdLink);
    can('view', viewSections.section.jobs.jobButtonShare);
    can('view', viewSections.section.sideBarMenu.business);
    can('view', viewSections.section.footer.business);
    can('view', viewSections.section.ourOffer.jobCTA);
    can('view', viewSections.section.ourOffer.homeSection);
    can('view', viewSections.section.chatBot);
    can('view', viewSections.section.dashboard.notifications);
    can('view', viewSections.section.sideBarMenu.show);
    can('view', viewSections.section.dashboard.myTransactions);
    can('view', viewSections.section.dashboard.businessCoupons);
    can('view', viewSections.section.dashboard.bid);
    can('view', viewSections.section.ourServices.chooseServiceButton);
  }

  if(role === roles.SUPER_ADMIN) {
    can('view', viewSections.section.dashboard.users);
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.dashboard.statistics);
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.ourOffer.homeSection);
    can('view', viewSections.section.chatBot);
    can('view', viewSections.section.dashboard.notifications);
    can('view', viewSections.section.admin.email_settings);
    can('view', viewSections.section.admin.verifyUser);
    can('view', viewSections.section.dashboard.news);
    can('view', viewSections.section.dashboard.events);
  }

  if(role === roles.ADMIN || role === roles.MANAGEMENT) {
    can('view', viewSections.section.dashboard.createTemplate);
    can('view', viewSections.section.dashboard.users);
    can('view', viewSections.section.dashboard.approveUser);
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.dashboard.messagesNew);
    can('view', viewSections.section.dashboard.payment);
    can('view', viewSections.section.dashboard.ourClients);
    can('view', viewSections.section.dashboard.createFAQs);
    can('view', viewSections.section.dashboard.createCategories);
    can('view', viewSections.section.jobs.jobTemplateEdit);
    can('view', viewSections.section.ourOffer.homeSection);
    can('view', viewSections.section.chatBot);
    can('view', viewSections.section.dashboard.notifications);
    can('view', viewSections.section.dashboard.transactions);
    can('view', viewSections.section.dashboard.coupons);
    can('view', viewSections.section.transaction.activateBeforeBooked);
    can('view', viewSections.section.dashboard.jobs);
    can('view', viewSections.section.logout.admin);
    can('view', viewSections.section.dashboard.choosenAdCategories);
    can('view', viewSections.section.preselection.candidateList);
    can('view', viewSections.section.footer.regular);
    if(role === roles.ADMIN) {
      can('view', viewSections.section.dashboard.adminCTA);
      can('view', viewSections.section.dashboard.tasks);
      can('view', viewSections.section.dashboard.taskServiceHistory);
      can('view', viewSections.section.admin.email_settings);
      can('view', viewSections.section.admin.verifyUser);
      can('view', viewSections.section.dashboard.news);
      can('view', viewSections.section.dashboard.events);

    }
  }

  if(role === roles.HR_USER) {
    can('view', viewSections.section.dashboard.userInformation);
    can('view', viewSections.section.dashboard.tasks);
    can('view', viewSections.section.dashboard.taskServiceHistory);
    can('view', viewSections.section.dashboard.accountInformation);
    can('view', viewSections.section.dashboard.regularUserInformation);
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.jobs.hrButton);
    can('view', viewSections.section.dashboard.jobCreate);
    can('view', viewSections.section.preselection.candidateList);
    can('view', viewSections.section.jobs.jobCreateHr);
    can('view', viewSections.section.ourOffer.homeSection);
    can('view', viewSections.section.chatBot);
    can('view', viewSections.section.dashboard.notifications);
    can('view', viewSections.section.dashboard.approveUser);
    can('view', viewSections.section.dashboard.choosenAdCategories);
    can('view', viewSections.section.dashboard.bid);
    can('view', viewSections.section.events.eventAdminEdit);
  }

  if(role === roles.SUPPORT) {
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.chatBot);
    can('view', viewSections.section.dashboard.notifications);
  }

  if(role === roles.FINANCE) {
    can('view', viewSections.section.dashboard.messages);
    can('view', viewSections.section.dashboard.notifications);
    can('view', viewSections.section.dashboard.transactions);
  }

  if(role !== roles.SUPER_ADMIN && role !== roles.BUSINESS_USER && role !== roles.ADMIN && role !== roles.HR_USER && role !== roles.SUPPORT && role !== roles.FINANCE) {
    can('view', viewSections.section.jobs.jobButtonShare);
    can('view', viewSections.section.ourOffer.homeSection);
    can('view', viewSections.section.jobs.jobTabsApply);
  }

  if(role !== roles.USER && role !== roles.SUPER_ADMIN && role !== roles.BUSINESS_USER && role !== roles.ADMIN && role !== roles.HR_USER && role !== roles.SUPPORT && role !== roles.FINANCE) {
    can('view', viewSections.section.ourServices.chooseServiceButton);
  }

  if(role === 'all') {
    can('view', viewSections.section.sideBarMenu.business);
    can('view', viewSections.section.sideBarMenu.regular);
    can('view', viewSections.section.footer.business);
    can('view', viewSections.section.footer.regular);
    can('view', viewSections.section.header.regular);
    can('view', viewSections.section.ourOffer.priceListCTA);
    can('view', viewSections.section.jobs.jobAdditionalLink);
    can('view', viewSections.section.sideBarMenu.show);
  }

  return rules;
}

// export function updateRulesFor(role: string) {
//   const {rules } = new AbilityBuilder<AppAbility>();
//   return rules ;

// }

export function buildAbilityFor(role: string): AppAbilityType {
  return new AppAbility(defineRulesFor(role));
}
