
import { LOGIN_REQUEST, LOGOUT_REQUEST, LOGIN_RESET, RESEND_VERIFICATION_EMAIL_REQUEST, RESEND_VERIFICATION_CODE_REQUEST, LOGIN_AFTER_VALIDATION_REQUEST, LOGIN_VERIFY_PHONE } from './constants';

export function login({username, password} : {username: string, password: string}) {
  return {
    type: LOGIN_REQUEST,
    username,
    password
  };
}

export function logoutAction() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function resetLogoutAction() {
  return {
    type: LOGIN_RESET,
  };
}

export function resendVerificationEmailtAction(username: string) {
  return {
    type: RESEND_VERIFICATION_EMAIL_REQUEST,
    username,
  };
}

export function resendVerificationCodeAction(username: string) {
  return {
    type: RESEND_VERIFICATION_CODE_REQUEST,
    username,
  };
}

export function loginAfterValidation({token, expires_in, category, slug}:{token: string, expires_in: string, category?: string, slug?: string}) {
  return {
    type: LOGIN_AFTER_VALIDATION_REQUEST,
    token,
    expires_in,
    category,
    slug
  };
}

export function verifyPhoneNumberAction(phone: string, code: string, pattern?: string) { 
  return {
    type: LOGIN_VERIFY_PHONE,
    phone, 
    code,
    pattern
  }
}