const scope = 'loop/Dashboard/RequestedServices';

export const GET_MY_JOB_APPLICATIONS = `${scope}/GET_MY_JOB_APPLICATIONS`;
export const GET_MY_JOB_APPLICATIONS_SUCCESS = `${scope}/GET_MY_JOB_APPLICATIONS_SUCCESS`;
export const GET_MY_JOB_APPLICATIONS_ERROR = `${scope}/GET_MY_JOB_APPLICATIONS_ERROR`;

export const GET_JOBS = `${scope}/GET_JOBS`;
export const GET_JOBS_SUCCESS = `${scope}/GET_JOBS_SUCCESS`;
export const GET_JOBS_ERROR = `${scope}/GET_JOBS_ERROR`;

export const GET_NEWS = `${scope}/GET_NEWS`;
export const GET_NEWS_SUCCESS = `${scope}/GET_NEWS_SUCCESS`;
export const GET_NEWS_ERROR = `${scope}/GET_NEWS_ERROR`;

export const GET_EVENTS = `${scope}/GET_EVENTS`;
export const GET_EVENTS_SUCCESS = `${scope}/GET_EVENTS_SUCCESS`;
export const GET_EVENTS_ERROR = `${scope}/GET_EVENTS_ERROR`;

export const GET_ALL = `${scope}/GET_ALL`;
export const GET_ALL_SUCCESS = `${scope}/GET_ALL_SUCCESS`;
export const GET_ALL_ERROR = `${scope}/GET_ALL_ERROR`;

export const DELETE_ITEM = `${scope}/DELETE_ITEM`;
export const DELETE_ITEM_SUCCESS = `${scope}/DELETE_ITEM_SUCCESS`;
export const DELETE_ITEM_ERROR = `${scope}/DELETE_ITEM_ERROR`;

export const PAUSE_JOB = `${scope}/PAUSE_JOB`;
export const PAUSE_JOB_SUCCESS = `${scope}/PAUSE_JOB_SUCCESS`;
export const PAUSE_JOB_ERROR = `${scope}/PAUSE_JOB_ERROR`;

export const REQUEST_WITHDRAW = `${scope}/REQUEST_WITHDRAW`;
export const REQUEST_WITHDRAW_SUCCESS = `${scope}/REQUEST_WITHDRAW_SUCCESS`;
export const REQUEST_WITHDRAW_ERROR = `${scope}/REQUEST_WITHDRAW_ERROR`;

export const DEACTIVATE_JOB = `${scope}/DEACTIVATE_JOB`;
export const DEACTIVATE_JOB_SUCCESS = `${scope}/DEACTIVATE_JOB_SUCCESS`;
export const DEACTIVATE_JOB_ERROR = `${scope}/DEACTIVATE_JOB_ERROR`;

export const DEACTIVATE_EVENT = `${scope}/DEACTIVATE_EVENT`;
export const DEACTIVATE_EVENT_SUCCESS = `${scope}/DEACTIVATE_EVENT_SUCCESS`;
export const DEACTIVATE_EVENT_ERROR = `${scope}/DEACTIVATE_EVENT_ERROR`;

export const DELETE_NEWS = `${scope}/DELETE_NEWS`;
export const DELETE_NEWS_SUCCESS = `${scope}/DELETE_NEWS_SUCCESS`;
export const DELETE_NEWS_ERROR = `${scope}/DELETE_NEWS_ERROR`;

// Per Page
export const REQUESTED_SERVICES_ITEMS_PER_PAGE = 5;

export const GET_CITIES = `${scope}/GET_CITIES`;
export const GET_CITIES_SUCCESS = `${scope}/GET_CITIES_SUCCESS`;
export const GET_CITIES_ERROR = `${scope}/GET_CITIES_ERROR`;

export const CREATE_CRITERIA = `${scope}/CREATE_CRITERIA`;
export const CREATE_CRITERIA_SUCCESS = `${scope}/CREATE_CRITERIA_SUCCESS`;
export const CREATE_CRITERIA_ERROR = `${scope}/CREATE_CRITERIA_ERROR`;

export const LAST_CRITERIA = `${scope}/LAST_CRITERIA`;
export const LAST_CRITERIA_SUCCESS = `${scope}/LAST_CRITERIA_SUCCESS`;
export const LAST_CRITERIA_ERROR = `${scope}/LAST_CRITERIA_ERROR`;

export const LAST_CRITERIA_JOBS = `${scope}/LAST_CRITERIA_JOBS`;
export const LAST_CRITERIA_JOBS_SUCCESS = `${scope}/LAST_CRITERIA_JOBS_SUCCESS`;
export const LAST_CRITERIA_JOBS_ERROR = `${scope}/LAST_CRITERIA_JOBS_ERROR`;

export const FAVOURITE_JOBS_SIDEBAR = `${scope}/FAVOURITE_JOBS_SIDEBAR`;

export const OPTIONS_CRITERIA = `${scope}/OPTIONS_CRITERIA`;
export const OPTIONS_CRITERIA_SUCCESS = `${scope}/OPTIONS_CRITERIA_SUCCESS`;
export const OPTIONS_CRITERIA_ERROR = `${scope}/OPTIONS_CRITERIA_ERROR`;

export const UPDATE_NEWSLETTER = `${scope}/UPDATE_NEWSLETTER`;
export const UPDATE_NEWSLETTER_SUCCESS = `${scope}/UPDATE_NEWSLETTER_SUCCESS`;
export const UPDATE_NEWSLETTER_ERROR = `${scope}/UPDATE_NEWSLETTER_ERROR`;

export const GET_PACKAGES = `${scope}/GET_PACKAGES`;
export const GET_PACKAGES_SUCCESS = `${scope}/GET_PACKAGES_SUCCESS`;
export const GET_PACKAGES_ERROR = `${scope}/GET_PACKAGES_ERROR`;

export const ENTITY_RENEWAL = `${scope}/ENTITY_RENEWAL`;
export const ENTITY_RENEWAL_SUCCESS = `${scope}/ENTITY_RENEWAL_SUCCESS`;
export const ENTITY_RENEWAL_ERROR = `${scope}/ENTITY_RENEWAL_ERROR`;

export const PACKAGE_SELECTION_SIDEBAR = `${scope}/PACKAGE_SELECTION_SIDEBAR`;