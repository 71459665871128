import authInstance from '../../utils/axios/authInstance';
import apiInstance from '../../utils/axios/apiInstance';

export const authLoginService = (username: string, password: string) => {
  const params = {
    username,
    password
  }

  const config = {
    withCredentials: true
  }
  
  return new Promise(async (resolve, reject) => {
    try {
      const result = await authInstance.post('/auth', params, config);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const authLogoutService = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await apiInstance.get('/logout');
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const resendVerificationEmailService = (username: string) => {
  return authInstance.post(`/email-verify/resend`, {
    email: username
  });
}

export const resendVerificationCodeService = (phone: string) => {
  return authInstance.get(`/phone-verification-resend/${phone}`);
}